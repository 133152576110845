import React, { Component } from "react";
import Navbar from "../Navbar";
import { Modal } from "react-bootstrap";
import SignOut from "../SignOut";
import { link, goto, downloadFile,numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Footer from "../footer";
import Loading from "../loading";
export class Spendings extends Component {
  constructor() {
    super();
    this.state = {
      verificationinfo: {
        id: "",
        status: "",
        amount: "",
        transactionDate: "",
        transactionCharges: "0",
        transactionAmount: "",
        transactionID: "",
        bankType: "",
        bankName: "",
      },
      loading: true,
      dataOut: 15,
      paymentlist: [],
      searchItem: "",
      spendinglist: null,
      isitLoading: false,
      openModal: false,
      recordtoverify: {},
      elementType: "",
      tempdate: new Date(),
      errormessage: "",
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    //this.sendVerification = this.sendVerification().bind(this);
  }
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getSpendings();
  };
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  getPayments() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=get&ra=payment&ft=areclist&elmt=paymentStatus&id=`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: Payments ", res.data);
        if (Array.isArray(res.data)) {
          this.setState({
            paymentlist: res.data,
          });
        }

        this.setState({ isitLoading: false });
        this.getSpendings();
      })
      .catch((err) => {
        // this.setState({
        //   isitLoading: false,
        // });
        console.log("AXIOS ERROR: Payments ", err);
      });
  }

  getSpendings() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "paymentapproval");

    axios
      .post(
        link() + `&do=get&ra=spending&ft=areclist&elmt=spendingStatus&id=`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: Spending ", res.data);
        if (Array.isArray(res.data)) {
          this.setState({
            loading: false,
            spendinglist: res.data,
          });
        }

        this.setState({
          isitLoading: false,
        });
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  }

  download() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type":
      "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "paymentapproval");
    

      downloadFile(
        "POST",
        link() +  `&do=get&ra=spending&ft=areclist&elmt=spendingStatus&id=`+ `&rec=spending&ufile=getfile`,
        postData,
        headers,
        "spending.xlsx",
      );
  }

  sendVerification = (e,decision) => {
    e.preventDefault();

    this.setState({ errormessage: "" });
    if (this.state.verificationinfo.bankType === "") {
      this.setState({ errormessage: "Account Type can't be empty" });
      return;
    }

    if (this.state.verificationinfo.bankName === "") {
      this.setState({ errormessage: "Bank name can't be empty" });
      return;
    }

  //  .console.log("Submitting verification results");
    var token = localStorage.getItem("token");

    //format(Date.parse(this.state.startDate), "yyyy-MM-dd");
    var testd = this.state.verificationinfo;
    testd.status =decision;
    testd.transactionDate = format(
      Date.parse(this.state.verificationinfo.transactionDate),
      "yyyy-MM-dd"
    );
    this.setState({ verificationinfo: testd });
    var postData = this.state.verificationinfo;
    var elementType = this.state.elementType;
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=verify&ra=${elementType}`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res.data);
        this.closeModal();
        this.handleVerify();
      })
      .catch((err) => {
        this.setState({
          isitLoading: false,
          errormessage: "Bank name can't be empty",
        });
        console.log("AXIOS ERROR: ", err);
        this.closeModal();
      });
  };

  handlePayment = () => {
    //window.location.href = window.location + "Main/RecordPayment";
    goto("Main/RecordPayment");
  };
  handleExpense = () => {
    //window.location.href = window.location + "Main/RecordExpenses";
    goto("Main/RecordExpenses");
  };
  handleVerify = () => {
    //window.location.href = window.location + "Main/PaymentApproval";
    //goto("Main/PaymentApproval");
    window.location.reload(true);
  };
  handleChangeDate(date) {
    //e.preventDefault();
    var verificationinfo = this.state.verificationinfo;
    verificationinfo.transactionDate = date;
    this.setState({
      verificationinfo: verificationinfo,
    });

  //  .console.log("Date changed " + date + "\n" + verificationinfo);
  }

  handleClick = (e, recordv, type) => {
  //  .console.log("1. Target= " + e.target.name + ", value= " + e.target.value);
    var verificationinfo = this.state.verificationinfo;
    if (type === "payment") verificationinfo.id = recordv.paymentID;
    if (type === "spending") verificationinfo.id = recordv.spendingID;
    verificationinfo.status = "verified";
    verificationinfo.names = recordv.firstName + " " + recordv.lastName;
    verificationinfo.amount = recordv.transactionAmount;
    verificationinfo.transactionDate = recordv.transactionDate;
    verificationinfo.transactionCharges = recordv.transactionCharges;
    verificationinfo.transactionID = recordv.transactionID;
    verificationinfo.elementID = recordv.elementID;
    verificationinfo.bankType = recordv.bankType;
    verificationinfo.bankName = recordv.bankName;

    verificationinfo.transactionDate =
      verificationinfo.transactionDate === ""
        ? new Date()
        : new Date(verificationinfo.transactionDate);

    this.setState({
      openModal: true,
      recordtoverify: recordv,
      verificationinfo: verificationinfo,
      elementType: type,
    });
  //  .console.log(this.state.verificationinfo);
    // console.log(
    //   "1. Variying= " +
    //     recordv.firstName +
    //     ", value= " +
    //     recordv.transactionAmount
    // );
  };

  closeModal = () => {
    var verificationinfo = this.state.verificationinfo;

    verificationinfo.id = "";
    verificationinfo.status = "";
    verificationinfo.amount = "";
    verificationinfo.transactionDate = "";
    verificationinfo.transactionCharges = "";
    verificationinfo.transactionID = "";
    this.setState({
      openModal: false,
      recordtoverify: {},
      verificationinfo: verificationinfo,
      errormessage: "",
    });
  };

  handleChange = (e) => {
    let verificationinfo = this.state.verificationinfo;
    verificationinfo[e.target.name] = e.target.value;
    //this.setState({ payment });
    //console.log("1. Target= " + e.target.name + ", value= " + e.target.value);

    if (e.target.name === "bankType" && e.target.value === "Bank") {
      verificationinfo.bankName = "BPR";
    } else if (e.target.name === "bankType" && e.target.value === "Momo") {
      verificationinfo.bankName = "Momo";
    } else if (e.target.name === "bankType" && e.target.value === "Other") {
      verificationinfo.bankName = "Other";
    }
    this.setState({ verificationinfo, errormessage: "" });

    //console.log(this.state.verificationinfo);
  };

  handleModalChange = (e) => {
    let verificationinfo = this.state.verificationinfo;
    verificationinfo[e.target.name] = e.target.value;
    //this.setState({ payment });
  //  .console.log("2. Target= " + e.target.name + ", value= " + e.target.value);

    this.setState({ verificationinfo });
  };
  handleSpendings = () => {
    goto("Main/Spendings/");
  };
  handlePayments = (bgcolor, color) => {
    goto("Main/PaymentApproval" + "/" + String(bgcolor) + "/" + String(color));
  };
  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 20,
      };
    });
  };
  render() {
    const {
      paymentlist,
      spendinglist,
      verificationinfo,
      recordtoverify,
      loading,
    } = this.state;
    let path = window.location.href;
    let bgcolor = path.split("/")[6];
    let color = path.split("/")[7];
  //  .console.log(path);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Payment</h6>
        </div>
        <div className="membaddd  ml-2">
          <button className="btndistr mb-2" onClick={this.handlePayment}>
            Record a Payment
          </button>
          <button className="btndistr" onClick={this.handleExpense}>
            Record an Expense
          </button>
          <button
            className="btndistr"
            onClick={() => goto("Main/Createpayment")}
          >
            Create Payment Request
          </button>
        </div>
        {/* <h6 className="activeuser"> Verify Spendings</h6> */}
        <h6 className="activeuser mt-3 d-flex"> <div className="col conteinpu">Verify Spendings</div>{spendinglist && spendinglist.length > 0 ? (
          <div className="print" onClick={() => this.download()}>
            <i class="fas fa-download"></i>
          </div>
        ) : (
          ""
        )}</h6>
        <div className=" ml-2">
          <button
            className="btnd mb-2"
            onClick={() => this.handlePayments("#16977f", "#ffffff")}
            disabled={loading}
          >
            Payments
          </button>
          <button
            className="btnd"
            onClick={this.handleSpendings}
            style={{ backgroundColor: bgcolor, color: color }}
          >
            Spendings
          </button>
        </div>
        <div className=" cardtable">
          <div className="membadd">
            <p className=" contentmemb "> Spendings</p>
            <input
              type="text"
              id="search"
              className="loginsearchfaro mb-3"
              placeholder=" Search a name..."
              onChange={(e) => this.handleUserChange(e)}
            />
            {/* <input
                  type="text"
                  id="search"
                  className="loginsearchfaro"
                  placeholder=" Search a name...
                "
                /> */}
          </div>
          <div className="rowuno  dupa">
            <div class="form-group">
              <br />
              {loading ? (
                <Loading />
              ) : (
                <>
                  <table class="table table-hover  table-responsive  tablealot ">
                    <tr>
                      <th className="userpronyblue "></th>
                      <th className="userpronyblue ">ID</th>
                      <th className="userpronyblue ">Transaction Date</th>
                      <th className="userpronyblue ">Bank</th>
                      <th className="userpronyblue ">Payee</th>
                      <th className="userpronyblue ">Category</th>
                      <th className="userpronyblue ">Type</th>
                      <th className="userpronyblue ">Currency</th>
                      <th className="userpronyblue ">Amount</th>
                      <th className="userpronyblue ">Charge Fees</th>
                      <th className="userpronyblue ">Round Name</th>
                      <th className="userpronyblue "></th>
                    </tr>

                    <tbody>
                      {spendinglist &&
                        spendinglist
                          .filter((grd) =>
                            grd.firstName
                              ?.toLowerCase()
                              .includes(this.state.searchItem)
                          )
                          .slice(0, this.state.dataOut)
                          .map((item, index) => {
                            // var sdate = item.transactionDate;
                            return (
                              <tr>
                                <td className="userinfo ">{index + 1}</td>
                                <td className="userinfo ">{item.spendingID}</td>
                                <td className="userinfo ">
                                  {item.transactionDate.substring(0, 10)}
                                </td>
                                <td className="userinfo ">{item.bankName}</td>
                                <td className="userinfo ">
                                  {item.firstName} {item.lastName}
                                </td>
                                <td className="userinfo ">{item.category}</td>
                                <td className="userinfo ">{item.type}</td>
                                <td className="userinfo ">{item.currency}</td>
                                <td className="userinfo ">
                                  {numb2(item.transactionAmount)}
                                </td>
                                <td className="userinfo ">
                                  {numb2(item.transactionCharges)}
                                </td>
                                <td className="userinfo ">{item.roundName}</td>
                                <td className="userinfo ">
                                  {item.spendingStatus === "Active" ? (
                                    "Verified"
                                  ) : (
                                    <button
                                      name="verifybutton"
                                      className="btndistr "
                                      onClick={(e) =>
                                        this.handleClick(e, item, "spending")
                                      }
                                      value={item.spendingID}
                                    >
                                      Verify
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      {spendinglist &&
                      spendinglist.length <= this.state.dataOut ? (
                        ""
                      ) : (
                        <div className="buttoncenter">
                          {spendinglist &&
                          spendinglist.length >= this.state.dataOut ? (
                            <button
                              type="button"
                              className="btnbookrermore  "
                              onClick={() => this.loadmore()}
                            >
                              <span className="mr-2 mt-1">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                              <span>Load More</span>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="schoolModal">
                    <Modal show={this.state.openModal} className="modalAll">
                      <Modal.Body className="Madl">
                        <div className="detmod">
                          <div>
                            <p
                              className="closeModale"
                              onClick={this.closeModal}
                            >
                              <i className="fas fa-times"></i>
                            </p>
                          </div>
                          <div className=" conteinputitle ">Verify Records</div>
                          <div className="d-flex">
                            <div className="conteinpu ml-3">
                              Bank:
                              <span className="unity">
                                {" "}
                                {this.state.verificationinfo.bankName}
                              </span>
                            </div>
                            <div className="conteinpu ml-5 ">
                              Payer:
                              <span className="unity">
                                {" "}
                                {this.state.verificationinfo.names}
                              </span>
                            </div>
                          </div>
                          <div className="rowuno mt-3">
                            <div class="form-group">
                              <div>
                                <label className="lebelenter">
                                  Transaction Date
                                </label>
                              </div>
                              <DatePicker
                                selected={
                                  this.state.verificationinfo.transactionDate
                                }
                                //onChange={(e) => this.handleChangeDate(e)}
                                onChange={this.handleChangeDate}
                                name="transactionDate"
                                maxDate={new Date()}
                                className="logininputsele"
                                dateFormat="yyyy-MM-dd"
                                placeholder="Pick a date"
                              />
                            </div>
                            <div class="form-group">
                              <div>
                                <label className="lebelenter">Amount</label>
                              </div>

                              <input
                                type="text"
                                id="amount"
                                name="amount"
                                // id="validationDefault01"
                                // required
                                onChange={(e) => this.handleModalChange(e)}
                                value={verificationinfo.amount}
                                className="logininputsele"
                                placeholder="Enter Amount"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="rowuno mt-3">
                              <div class="form-group">
                                <label className="lebelenter">
                                  Account Type
                                </label>

                                <select
                                  id="catcbanktypeegory"
                                  type="text"
                                  name="bankType"
                                  required
                                  //id="validationDefault01"
                                  onChange={(e) => this.handleChange(e)}
                                  selected={
                                    this.state.verificationinfo.bankType
                                  }
                                  className="logininputsselect"
                                  placeholder="Select Bank type"
                                >
                                  <option value="">Select Type</option>
                                  <option
                                    value="Bank"
                                    selected={
                                      verificationinfo.bankType === "Bank"
                                        ? true
                                        : null
                                    }
                                  >
                                    Bank
                                  </option>
                                  <option
                                    value="Momo"
                                    selected={
                                      verificationinfo.bankType === "Momo"
                                        ? true
                                        : null
                                    }
                                  >
                                    Momo
                                  </option>
                                  <option
                                    value="Other"
                                    selected={
                                      verificationinfo.bankType === "Other"
                                        ? true
                                        : null
                                    }
                                  >
                                    Other
                                  </option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label className="lebelenter">Bank Name</label>
                                <select
                                  id="bankname"
                                  type="text"
                                  name="bankName"
                                  required
                                  //id="validationDefault01"
                                  onChange={(e) => this.handleChange(e)}
                                  selected={
                                    this.state.verificationinfo.bankName
                                  }
                                  className="logininputsselect"
                                  placeholder="Select Bank Name"
                                >
                                  <option> Select Bank</option>
                                  <option
                                    disabled={
                                      this.state.verificationinfo.bankType ===
                                      "Bank"
                                        ? null
                                        : true
                                    }
                                    value="BPR"
                                    selected={
                                      verificationinfo.bankName === "BPR"
                                        ? true
                                        : null
                                    }
                                  >
                                    BPR
                                  </option>

                                  <option
                                    disabled={
                                      this.state.verificationinfo.bankType ===
                                      "Bank"
                                        ? null
                                        : true
                                    }
                                    value="COPEDU"
                                    selected={
                                      verificationinfo.bankName === "COPEDU"
                                        ? true
                                        : null
                                    }
                                  >
                                    COPEDU
                                  </option>
                                  <option
                                    disabled={
                                      this.state.verificationinfo.bankType ===
                                      "Momo"
                                        ? null
                                        : true
                                    }
                                    value="Momo"
                                    selected={
                                      verificationinfo.bankName === "Momo"
                                        ? true
                                        : null
                                    }
                                  >
                                    Momo
                                  </option>
                                  <option
                                    disabled={
                                      this.state.verificationinfo.bankType ===
                                      "Other"
                                        ? null
                                        : true
                                    }
                                    value="Other"
                                    selected={
                                      verificationinfo.bankName === "Other"
                                        ? true
                                        : null
                                    }
                                  >
                                    Other
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="rowuno mt-3">
                              <div class="form-group">
                                <label className="lebelenter">
                                  Transaction Reference:
                                </label>

                                <input
                                  type="text"
                                  id="transactionID"
                                  name="transactionID"
                                  onChange={(e) => this.handleModalChange(e)}
                                  value={verificationinfo.transactionID}
                                  className="logininputsele"
                                  placeholder="Enter Ref ID"
                                />
                              </div>
                              <div class="form-group">
                                <label className="lebelenter">
                                  Transaction fee:
                                </label>
                                <input
                                  type="number"
                                  id="transactionCharges"
                                  name="transactionCharges"
                                  // id="validationDefault01"
                                  required
                                  onChange={(e) => this.handleModalChange(e)}
                                  value={verificationinfo.transactionCharges}
                                  className="logininputsele"
                                  placeholder="Enter 0 if no Bank fees"
                                />
                              </div>
                            </div>

                            <div className="d-block  ml-5">
                              <p>{this.state.errormessage}</p>
                              <div className="row">
                                <div className="col-3"></div>
                                <div className="col-6">
                                  <button
                                    className="viewdetcli"
                                    method="post"
                                    onClick={(e) => this.sendVerification(e, "verified")}
                                  >
                                    I've verified the transaction
                                  </button> {" " }
                                  <button
                                    className="viewdetcli"
                                    method="post"
                                    onClick={(e) => this.sendVerification(e, "cancelled")}
                                  >
                                    Cancel Record
                                  </button>
                                </div>
                                <div className="col-1"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

// const Modal = ({ handleClose, show, children }) => {
//   const showHideClassName = show
//     ? "modal display-block modal-open"
//     : "modal display-none close-modal";
//   console.log("Modal show is " + show);

//   return (
//     <div className={showHideClassName}>
//       <section className="modal-main">
//         {children}
//         <button onClick={handleClose}>Close</button>
//       </section>
//     </div>
//   );
// };

// const container = document.createElement("div");
// document.body.appendChild(container);
// ReactDOM.render(<PaymentApproval />, container);

export default Spendings;
