import React, { Component } from "react";
import Navbar from "../../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../../SignOut";
import { link, numb2, goto } from "../../link.js";
import axios from "axios";
import Footer from "../../footer";
export class AddMemberRound extends Component {
  constructor() {
    super();
    this.state = {
      newUserAdd: [],
      selectedUser: "",
      round: {},
      newMemberAdd: {
        numberOfShares: "",
        capital: "",
        valueOfaShare: "",
        shareValue: "",
        memberships: "",
        distributionDate: "",
        commission: "0",
      },
      openSucess: false,
      openError: false,
      loading: false,
    };
  }
  componentDidMount = () => {
    this.getRoundById();
  };
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuucess = () => {
    let path = window.location.hash;
    let RoundId = path.split("/")[3];
    goto("Main/RoundInfo/" + RoundId);
  };
  getRoundById() {
    let path = window.location.hash;
    let RoundId = path.split("/")[3];
    var token = localStorage.getItem("token");
    var config = {
      method: "get",

      url: link() + `&do=get&ra=round&ft=rec&id=${RoundId}&elmt=roundID`,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          round: response.data,
        });
      //  .console.log(response.data);
        this.getUser();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getUser() {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: link() + `&do=get&ra=user&ft=all`,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          newUserAdd: response.data,
        });
      //  .console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (e) => {
    let newMemberAdd = this.state.newMemberAdd;
    newMemberAdd[e.target.name] = e.target.value;
    this.setState({ newMemberAdd });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    let path = window.location.hash;
    let RoundId = path.split("/")[3];
    var newMember = JSON.stringify({
      roundID: RoundId,
      userids: this.state.selectedUser,
      numberOfShares: this.state.newMemberAdd.numberOfShares,
      capital: this.state.newMemberAdd.capital,
      memberships: this.state.newMemberAdd.memberships,
      distributionDate: this.state.newMemberAdd.distributionDate,
      commission: this.state.newMemberAdd.commission,
    });

    var token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: link() + `&do=addmember&ra=round`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: newMember,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // window.location.href = window.location + "Main/RoundInfo";
  };
  handleNewStu = (event) => {
    this.setState({
      selectedUser: event.target.value,
    });
  };
  render() {
    const { newUserAdd, round, loading } = this.state;

    //console.log(this.state.selectedUser);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Round </h6>
        </div>

        <div className="activeuser d-flex">
          <p className="mt-2">
            <div className="col conteinpu">
              Adding member to Round:
              <span className="unity">{round.name}</span>
            </div>
          </p>
          <p className="mt-2">
            <div className="col conteinpu">
              Value of a Share:
              <span className="unity"> {numb2(round.shareValue)}</span>
            </div>
          </p>
          <p className="parainputside">
            <div className="col conteinpu">
              Share Payments Due Date:
              <span className="unity">
                {" "}
                {round.capitalDueDate
                  ? round.capitalDueDate.substring(0, 10)
                  : ""}
              </span>
            </div>
          </p>
        </div>

        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className=" rowuno  mt-3">
              <div class="form-group">
                <label className="lebelenter"> User</label>
                <select
                  className="logininputsselect"
                  value={this.state.selectedUser}
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleNewStu(e)}
                >
                  <option></option>
                  {newUserAdd &&
                    newUserAdd.map((aca) => (
                      <option value={aca.userID} id={aca.userID}>
                        {aca.firstName}
                        {"  "}
                        {aca.lastName}
                      </option>
                    ))}
                </select>
              </div>
              <div class="form-group">
                <label className="lebelenter">Social Capital Shares</label>
                <input
                  type="number"
                  id="valueOfaShare"
                  name="numberOfShares"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newMemberAdd.numberOfShares}
                  className="logininputs"
                  placeholder="InputNumber Of Shares: "
                />
              </div>
              <div class="form-group">
                <label className="lebelenter"> Round Payment Shares</label>
                <input
                  type="number"
                  id="uname"
                  name="memberships"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newMemberAdd.memberships}
                  className="logininputs"
                  placeholder="Payable Round Payment"
                />
              </div>
            </div>

            <div className="rowuno">
              {/* <div class="form-group">
                <label className="lebelenter">Monthly Distribution Date</label>
                <input
                  type="text"
                  id="param2"
                  id="validationDefault01"
                  required
                  name="distributionDate"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newMemberAdd.distributionDate}
                  className="logininputs"
                  placeholder="use format YYYY-MM-DD:"
                />
              </div> */}
              <div class="form-group">
                <label className="lebelenter">Commission</label>
                <input
                  type="number"
                  id="uname"
                  id="validationDefault01"
                  required
                  name="commission"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newMemberAdd.commission}
                  className="logininputs"
                  placeholder="commission:"
                />
              </div>
            </div>
            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>

            <br />
          </form>
          <div className="schoolModal">
            <Modal show={this.state.openSucess} className="modalAll">
              <Modal.Body className="Madl">
                <div>
                  <p className="btnaddblog" onClick={this.closeSuucess}>
                    <i className="fas fa-times"></i>
                  </p>
                </div>
                <div>
                  <p className="sent">Successfully added!</p>
                  <p className="succpar">
                    You have successfully added a member to the Round .
                    Congratulations!
                  </p>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default AddMemberRound;
