import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";

import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link } from "../link.js";
import { confirm } from "../utils/Confirmation";
import { confirm as confirmComplex } from "../utils/ComplexConfirmation";

import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
import Loading from "../loading";
export class Usergroups extends Component {
  constructor() {
    super();
    this.state = {
      users: null,
      reportType: "contr",
      reportResults: [],
      userid: "",
      //userlist: null,
      openModal: false,
      groupinfo: { userid: "", action: "", groupid: "", gname: "" },
      cansend: false,
      loading: false,
    };
    //this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    //this.handleChangeDate = this.handleChangeDate.bind(this);
    //this.getReport = this.getReport.bind(this);
  }

  getGroups = (reptype) => {
    var token = localStorage.getItem("token");

    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=mgroup&ft=all`, postData, { headers: headers })
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res.data);
        if (Array.isArray(res.data))
          this.setState({
            reportResults: res.data,
            loading: false,
          });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  };

  getUser = () => {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "expense");
    axios
      .post(
        link() + `&do=get&ra=user&ft=all`,

        postData,
        { headers: headers }
      )
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        if (Array.isArray(res.data))
          this.setState({
            users: res.data,
            loading: false,
          });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getGroups("");
  };
  handleChangeSelect = (e) => {
    console.log("3. Target= " + e.target.name + ", value= " + e.target.value);
    var groupinfo = this.state.groupinfo;

    groupinfo.userid = e.target.value;
    groupinfo.action = "adduser";
    //groupinfo.groupid = groupelement.mgroupID;
    //groupinfo.gname = groupelement.otherName;
    this.setState({
      //openModal: true,
      //recordtoverify: recordv,
      groupinfo: groupinfo,
    });
  };

  handleRemoveUser = async (e, item) => {
    e.preventDefault();
    /////////////////////////////////////////////
    // confirmAlert({
    //   title: "Delete user",
    //   message: `Are you sure you want to delete user ${item.names}?`,
    //   buttons: [
    //     {
    //       label: "Cancel",
    //     },
    //     {
    //       label: "Delete",
    //       onClick: () => {

    ///////////////////////////////////////////////

    if (await confirm("Are you sure?")) {
      this.setState({
        cansend: true,
      });
    } else {
      this.setState({
        message: false,
      });
    }

    console.log(
      "4. User to remove= " + item.names + ", userid= " + item.userid
    );

    if (!this.state.cansend) return;
    var groupinfo = this.state.groupinfo;

    groupinfo.userid = item.userid;
    groupinfo.action = "removeuser";
    groupinfo.groupid = item.pid;
    //groupinfo.groupid = groupelement.mgroupID;
    //groupinfo.gname = groupelement.otherName;
    this.setState({
      //openModal: true,
      //recordtoverify: recordv,
      groupinfo: groupinfo,
    });

    this.updateChanges();

    //////////////////////////////////
    //       },
    //     },
    //   ],
    // });
  };

  handleCreateRound = () => {
    window.location.href = window.location.origin + "/Main/CreateRound";
  };
  handleShowMore = () => {
    window.location.href = window.location.origin + "/Main/LoanCheck";
  };
  handleBack = () => {
    window.location.href = window.location.origin + "/Main/Allloans";
  };

  sendChanges = (e) => {
    e.preventDefault();
    this.updateChanges();
  };

  updateChanges() {
    //e.preventDefault();
    console.log("Submitting useradd");
    var token = localStorage.getItem("token");
    var postData = this.state.groupinfo;
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=add&ra=mgroup`,

        postData,
        { headers: headers }
      )
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res.data);

        this.getGroups("");
      })
      .catch((err) => {
        this.setState({
          isitLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });

    this.closeModal();
  }

  openModal = (groupelement) => {
    //console.log("1. Target= " + e.target.name + ", value= " + e.target.value);
    this.getUser();
    var groupinfo = this.state.groupinfo;

    groupinfo.userid = groupelement.userID;
    groupinfo.action = "adduser";
    groupinfo.groupid = groupelement.mgroupID;
    groupinfo.gname = groupelement.otherName;
    this.setState({
      openModal: true,
      //recordtoverify: recordv,
      groupinfo: groupinfo,
    });

    console.log(
      "1. Selected Group= " +
        groupelement.otherName +
        ", action= " +
        groupinfo.action
    );
  };
  closeModal = () => {
    let groupinfo = this.state.groupinfo;

    groupinfo.userid = "";
    groupinfo.action = "adduser";
    groupinfo.groupid = "";
    groupinfo.gname = "";
    this.setState({
      openModal: false,
      groupinfo: groupinfo,
    });
  };

  render() {
    const { reportResults, groupinfo, users, loading } = this.state;

    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">System Settings</h6>
            <div className="membadd">
              <Link to="/Main/Members" className="contrilink">
                Users
              </Link>
              <Link to="/Main/Assets" className="contrilink">
                Assets
              </Link>
              <Link to="/Main/Usergroups" className="contrilink">
                User Groups
              </Link>
              <Link to="/Main/Adminsettings" className="contrilink">
                Round Settings
              </Link>
              <Link to="/Main/Profile" className="contrilink">
                Admin Settings
              </Link>
            </div>
            <h6 className="activeuser mt-3">
              <div className="col conteinpu">
                User Group Settings:
                {/* <span className="unity"> {reportResults.roundName}</span> */}
              </div>
            </h6>
            <div className="cardtable ">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="row">
                    {reportResults &&
                      reportResults.map((item) => {
                        return (
                          <div>
                            <div className="totaldetaer">
                              <div className="col  unity pt-1 ml-2">
                                {/* <div
                            className="btnaddblogme "
                            onClick={() => this.openModal(item)}
                          >
                            <i class="fas fa-plus"></i>
                          </div> */}

                                <div>
                                  <p
                                    className="closeModalero "
                                    onClick={() => this.openModal(item)}
                                  >
                                    <i className="fas fa-plus"></i>
                                  </p>
                                </div>
                                <span className="pt-5">{item.otherName}</span>
                              </div>
                            </div>

                            <br />

                            <div className="table table-responsive ml-5">
                              <div>
                                {item.attachment &&
                                  item.attachment.map((prs) => {
                                    //let dt = item.paiddate.toString().substring(0, 10);
                                    return (
                                      <div className="d-flex">
                                        <p className="userin  ">{prs.names}</p>
                                        <p className="viewer ">
                                          <span
                                            className="viewdetclionedit "
                                            onClick={(e) =>
                                              this.handleRemoveUser(e, prs)
                                            }
                                          >
                                            <i class="fas fa-trash-alt"></i>
                                          </span>
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>

                            <br />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
              {/* <>-----------------------------------------------------------------</> */}

              <Modal show={this.state.openModal} className="modalAll">
                <Modal.Body className="Madl">
                  <div>
                    <p className="closeModal" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className="rowuno mt-3">
                    <div className="row ml-3">
                      <div className="col conteinpu ml-3">
                        Group:
                        <span className="unity">
                          {" "}
                          {this.state.groupinfo.gname}
                        </span>
                      </div>
                      <div className="col conteinpu ml-5">
                        Add User:
                        <select
                          className="form-select  logininputsselect"
                          value={groupinfo.userid}
                          required
                          onChange={(e) => this.handleChangeSelect(e)}
                          //disabled={this.state.hideuserid ? true : null}
                          name="userID"
                          id="userID"
                        >
                          <option value=""> user</option>

                          {users &&
                            users.map((aca) => (
                              <option value={aca.userID} id={aca.userID}>
                                {aca.firstName} {aca.lastName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="d-block  ml-5">
                        <button
                          className="viewdetcli"
                          method="post"
                          onClick={(e) => this.sendChanges(e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <br />
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Usergroups;
