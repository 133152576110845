import React, { Component, ChangeEvent, useState } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";

export class Filemanager extends Component {
  constructor() {
    super();
    this.state = {
      openSucess: false,
      openError: false,
      loading: false,
      selectedFile: null,
    };
    //this.handleChange = this.handleChange.bind(this);
  }
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuccess = () => {
    //window.location.href = window.location + "Main/AddMember";

    goto("Main/file");
  };
  // openModalError = () => {
  //   this.setState({
  //     openError: true,
  //   });
  // };
  closeError = () => {
    //window.location.href = window.location + "Main/Signin";
    goto("Main/Signin");
  };
  handleChange = (e) => {
    let users = this.state.users;
    users[e.target.name] = e.target.value;
    this.setState({ users });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var newUser = JSON.stringify({
      firstName: this.state.users.firstname,
      lastName: this.state.users.lastname,
      middleName: this.state.users.middlename,
      jmb: this.state.users.password,
      email: this.state.users.email,
      mobile: this.state.users.mobile,
    });
    // .console.log(
    //   this.state.users.firstname +
    //     "/" +
    //     this.state.users.lastname +
    //     "/" +
    //     this.state.users.username,
    //   "/",
    //   newUser
    // );
    var token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: link() + `&do=create&ra=user`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newUser,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  }; // On file upload (click the upload button)
  onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData(); // Update the formData object
    var token = localStorage.getItem("token");

    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    ); // Details of the uploaded file
  //  .console.log(this.state.selectedFile); // Request made to the backend api // Send formData object
    axios
      .post(link() + `&do=uptemplate&ufile=upload`, formData, {
        headers: {
          Authorization: `${token}`,

          "Content-Type": "application/json",
        },
      })
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          //openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }; // File content to be displayed after // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>

          <p>
            Last Modified:
            {this.state.selectedFile &&
              this.state.selectedFile.lastModifiedDate &&
              this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  render() {
    //const { loading } = this.state;
    //console.log(this.state.users);
    return (
      <div className="contentdashbord">
        <div className="welcome ">
          <h6 className="activejobs">File Manager</h6>
        </div>

        <h6 className="activeuser"> Upload File</h6>
        <div className=" cardtable">
          <div>
            <div>
              <h3>Select File</h3>
              <br />
              <input type="file" onChange={this.onFileChange} />

              <button onClick={this.onFileUpload}>Upload!</button>
            </div>
            <br />
            {this.fileData()}
          </div>
        </div>
        <div className="schoolModal">
          <Modal show={this.state.openSucess} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="btnaddblog" onClick={this.closeSuucess}>
                  <i className="fas fa-times"></i>
                </p>
              </div>
              <div>
                <p className="sent">Successful upload!</p>
                <p className="succpar">
                  You have successfully uploaded a file!
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Filemanager;
