import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../SignOut";
import { link, goto, numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Footer from "../footer";
export class LoanRequest extends Component {
  constructor() {
    super();
    this.state = {
      loan: {},
      loanstatus: {},
      users: [],
      selectedUser: "",
      opensSuccess: false,
      openError: false,
      loading: false,
      startDate: new Date(),
      endDate: new Date(),
      btvalue: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  componentDidMount = () => {
    this.getLoanDetatils();
  };

  // getUser() {
  //   var token = localStorage.getItem("token");
  //   var postData = {};
  //   var headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `${token}`,
  //   };

  //   localStorage.setItem("currentView", "faculty");
  //   axios
  //     .post(
  //       link() + `&do=getusers&ra=round`,

  //       postData,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       console.log("RESPONSE RECEIVED: Users ", res.data);
  //       if (Array.isArray(res.data))
  //         this.setState({
  //           users: res.data,
  //         });
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         isLoading: false,
  //       });
  //       console.log("AXIOS ERROR: ", err);
  //     });
  // }

  getLoanDetatils = () => {
    let path = window.location.hash;
  //  .console.log("path= " + path);
    let userId = path.split("/")[3];
  //  .console.log("id= " + userId);
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: link() + `&do=get&ra=loan&ft=rec&elmt=loanID&id=${userId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);

        if (Object.keys(response.data).length > 0) {
          let loan = response.data;
          loan.startDate =
            response.data.startDate === ""
              ? new Date()
              : new Date(
                  response.data.startDate.replace("T", " ").substring(0, 19)
                );
          this.setState({
            loan: loan,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handlePayment = () => {
  //   window.location.href = window.location + "Main/RecordPayment";
  // };
  // handleExpense = () => {
  //   window.location.href = window.location + "Main/RecordExpenses";
  // };
  // handleVerify = () => {
  //   window.location.href = window.location + "Main/PaymentApproval";
  // };

  takeMeToLoan = (id) => {
    if (id !== "") goto("Main/LoanDetails/" + id);
  };

  handleChange = (e) => {
    let loan = this.state.loan;
    loan[e.target.name] = e.target.value;
    this.setState({ loan });
  };

  handleChangeDate(date) {
    let loan = this.state.loan;
    loan.startDate = date;
    this.setState({
      loan: loan,
    });

  //  .console.log("start date", this.state.loan.startDate);
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.setState({ btvalue: true });

    var newLoan = JSON.stringify({
      loanID: this.state.loan.loanID,
      userID: this.state.loan.userID,
      capital: this.state.loan.capital,
      category: this.state.loan.category,
      startDate: format(Date.parse(this.state.loan.startDate), "yyyy-MM-dd"),
      numberOfTerms: this.state.loan.numberOfTerms,
      interestRate: this.state.loan.interestRate,
      remark: this.state.loan.remark,
      topupAmount: this.state.loan.topupAmount,
      applicationFeeRate: this.state.loan.applicationFeeRate,
    });

    var token = localStorage.getItem("token");
    //var cont;
    var config = {
      method: "post",
      url: link() + `&do=update&ra=loan`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newLoan,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);

        this.setState({ btvalue: false });
        //cont.push(response.data);

        if (response.data !== "Success")
          alert("Something went wrong. Please try again later.");
        else goto("Main/LoanDetails/" + this.state.loan.loanID);
      })
      .catch((error) => {
        console.log(error);

        alert("Something went wrong. Please try again later.");
        //this.setState({ btvalue: false });
      });

    //
  };

  render() {
    const { loan } = this.state;
    //console.log(this.state.selectedUser);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Loan</h6>
        </div>
        <div className="searchexp">
          <button
            className="btndistr"
            onClick={() => goto("Main/LoanDetails/" + loan.loanID)}
          >
            Go Back
          </button>
        </div>

        <h6 className="activeuser">Edit Loan</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div class="form-group">
                <label className="lebelenter">
                  Beneficiary : {loan.firstName} {loan.middleName}{" "}
                  {loan.lastName}
                </label>
              </div>
            </div>
            <div className="rowuno mt-3">
              <div class="form-group">
                <label className="lebelenter">
                  {" "}
                  Requested Capital (in RWF)
                </label>
                <input
                  type="number"
                  id="amount"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.loan.capital}
                  name="capital"
                  className="logininputs"
                  placeholder="Enter Amount"
                />
              </div>

              <div class="form-group">
                <div>
                  <label className="lebelenter">Effective Date</label>
                </div>
                <DatePicker
                  selected={this.state.loan.startDate}
                  //onChange={(e) => this.handleChangeDate(e)}
                  onChange={this.handleChangeDate}
                  name="effectiveDate"
                  //minDate={this.state.endDate}
                  id="validationDefault01"
                  required
                  className="logininputs"
                  dateFormat="yyyy-MM-dd"
                  placeholder="Pick a date"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Top Up Fee</label>
                <input
                  type="number"
                  id="topupAmount"
                  // id="validationDefault01"
                  //required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.loan.topupAmount}
                  name="topupAmount"
                  className="logininputs"
                  placeholder="Enter Amount"
                />
              </div>
            </div>
            <div className="rowuno ">
              <div class="form-group">
                <label className="lebelenter">Interest Rate (%)</label>
                <input
                  type="number"
                  id="interestRate"
                  name="interestRate"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.loan.interestRate}
                  className="logininputs"
                  placeholder="Interest Rate (%)"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Terms</label>
                <input
                  type="number"
                  id="numberOfTerms"
                  name="numberOfTerms"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.loan.numberOfTerms}
                  className="logininputs"
                  placeholder="Terms:"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Application Fee Rate (%)</label>
                <input
                  type="number"
                  id="applicationFeeRate"
                  // id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.loan.applicationFeeRate}
                  name="applicationFeeRate"
                  className="logininputs"
                  placeholder="Enter rate: 1"
                />
              </div>
            </div>
            <div className="rowuno "></div>

            <div className="col ">
              <label className="lebelenter">Justification</label> <br />
              <textarea
                className="parainputsmestext"
                placeholder="Write a justification for approvals to understand more about the request."
                name="remark"
                id="validationDefault01"
                required
                onChange={(e) => this.handleChange(e)}
                value={this.state.loan.remark}
              />
            </div>
            <br />
            <div className="d-flex">
              {this.state.btvalue ? (
                <button
                  type="button"
                  //value="loading..."
                  className="viewdetcli ml-3"
                >
                  loading...
                </button>
              ) : (
                <input
                  type="submit"
                  value="Submit"
                  className="viewdetcli ml-3"
                />
              )}
            </div>

            <br />
          </form>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default LoanRequest;
