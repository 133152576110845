import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Container, Button, Modal } from "react-bootstrap";
import ChartOver from "./Chartover";
import Navbar from "./Navbar";
import MapContainer from "./Map";
import SignOut from "./SignOut";
//import { connect } from "react-redux";
//import { saveSchool, saveCompany } from "../redux/actions";
import { link, goto, numb2 } from "./link.js";
import axios from "axios";
import Footer from "./footer";
import Loading from "./loading";

class Overall extends Component {
  constructor() {
    super();
    this.state = { round: [], loading: false };
  }
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getRound();
  };
  getRound() {
    var token = localStorage.getItem("token");

    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";

    var config = {
      method: "post",
      url:
        link() + `&do=getoverview&ra=round&ft=rec&elmt=roundID&id=${roundId}`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    };
    setTimeout(() => {
      axios(config)
        .then((response) => {
          this.setState({
            round: response.data,
            roundid: response.data.roundID,
            loading: false,
          });
          //console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);
    // axios(config)
    //   .then((response) => {
    //     this.setState({
    //       round: response.data,
    //       roundid: response.data.roundID,
    //     });
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  render() {
    const { round, loading } = this.state;
    const name = localStorage.getItem("name");

    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <Container fluid>
            <div className="welcome d-flex">
              <h6 className="welcomeback">Welcome Back {name}. </h6>{" "}
              {/* <span className="username">  </span> */}
            </div>

            <br />
            {loading ? (
              <Loading />
            ) : (
              <>
                <div>
                  <div className="row">
                    <div className="col-md-3 ">
                      <Card className="carddetails ">
                        <Card.Body>
                          <div className="d-block">
                            <p className="descr">Members</p>
                            <p className="numberzero">
                              {round.attachement &&
                                round.attachement.memberlist.length}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3">
                      <Card className="carddetails">
                        <Card.Body>
                          <div className="d-block">
                            <p className="descr">Number Of Shares</p>
                            <p className="numberzero">
                              {round.attachement &&
                                round.attachement.sharesnumber}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3">
                      <Card className="carddetails">
                        <Card.Body>
                          <div className="d-block">
                            <p className="descr">Total Capital</p>
                            <p className="numberzero">
                              {round.currency}{" "}
                              {round.attachement &&
                                numb2(round.attachement.valueofshares)}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3">
                      <Card className="carddetails">
                        <Card.Body>
                          <div className="d-block">
                            <p className="descr"> Monthly Payable Shares</p>
                            <p className="numberzero">
                              {round.attachement &&
                                round.attachement.membershipsnumber}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3 ">
                      <Card className="carddetails">
                        <Card.Body>
                          <div className="d-block">
                            <p className="descr"> Monthly contribution</p>
                            <p className="numberzero">
                              {round.currency}{" "}
                              {round.attachement &&
                                numb2(round.attachement.termcontributions)}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <Card className="caerd">
                    <Card.Body>
                      <Row className="cardrow">
                        <Col xs="12" md="7" lg="7">
                          <ChartOver />
                        </Col>
                        <Col xs="12" md="5" lg="5">
                          <div className="peoplecompany">
                            <div className="contcount">
                              <div className="d-block">
                                <p className="descr">Number of Loans</p>
                                <p className="numberzero">
                                  {round.attachement &&
                                    round.attachement.numberofloans}
                                </p>
                              </div>
                              <div className="linehorizontal" />
                            </div>

                            <div className="contcount">
                              <div className="d-block">
                                <p className="descr">Loans Value</p>
                                <p className="numberzero">
                                  {round.currency}{" "}
                                  {round.attachement &&
                                    numb2(round.attachement.loancapitalvalue)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <br />
                </div>
              </>
            )}
          </Container>
          <Footer />
        </div>
      </div>
    );
  }
}
// function mapStateToProps({ YourSchool }) {
//   const { school } = YourSchool;
//   return {
//     school,
//   };
// }

export default Overall;
