import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../SignOut";
import { link } from "../link.js";
import Footer from "../footer";
export class LoanCheck extends Component {
  render() {
    return (
      <div className="contentdashbord">
        <div className="welcome ">
          <h6 className="activejobs">Loan</h6>
        </div>

        <h6 className="activeuser">Loan Approval</h6>
        <div className=" cardtable">
          <form className="formcreate " method="post">
            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Beneficiary:
                  <span className="unity"> John Doe</span>
                </div>
              </p>
              <p className="parainputs mt-3">
                {" "}
                <div className="col conteinpu">
                  Term type:
                  <span className="unity"> Month</span>
                </div>
              </p>
            </div>
            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Share Value::
                  <span className="unity"> RWF 1,601,392.00</span>
                </div>
              </p>
              <p className="parainputs ">
                {" "}
                <div className="col conteinpu">
                  Terms:
                  <span className="unity"> 5</span>
                </div>
              </p>
              <p className="parainputs ">
                {" "}
                <div className="col conteinpu ">
                  Requestor:
                  <span className="unity"> Alfred</span>
                </div>
              </p>
            </div>
            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Capital Amount:
                  <span className="unity"> RWF 1,000,000.00</span>
                </div>
              </p>
              <p className="parainputs ">
                {" "}
                <div className="col conteinpu">
                  Request Date:
                  <span className="unity"> 9/13/2021</span>
                </div>
              </p>

              <p className="parainputsmes">
                <div className="col conteinpu">
                  Requestor’s note:
                  <span className="unity"> </span>
                </div>
              </p>
            </div>
            <div className="rowuno ">
              <p className="parainputsha">
                <div className="col conteinpu">
                  nterest Rate (%):
                  <span className="unity"> 3</span>
                </div>
              </p>
              <p className="parainputsha ">
                {" "}
                <div className="col conteinpu">
                  Approval Date:
                  <span className="unity"> Pending</span>
                </div>
              </p>
            </div>
            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Effective Date:
                  <span className="unity"> 9/15/2021</span>
                </div>
              </p>
              <p className="parainputs ">
                {" "}
                <div className="col conteinpu">
                  Top Up Fees:
                  <span className="unity"> RWF 5000</span>
                </div>
              </p>
            </div>
            <div className="row ">
              <div class="col-7">
                <div className="col conten">
                  Beneficiary’s other existing loans:
                  <span className="unity"> 1</span>
                </div>
                <br />
                <textarea
                  className="parainputsmestext"
                  placeholder="justification"
                />
              </div>
              <div class="col-5 ">
                <p className="appperson ">
                  Jean Semana approved at 9/13/2021 13:40
                </p>
                <input className="parainputsed ml-3 " placeholder="Note:" />

                <p className="appperson">
                  Marcelin approved at 9/13/2021 13:40
                </p>
                <input className="parainputsed ml-3 " placeholder="Note:" />
                <p className="appperson">Alfred approved at 9/13/2021 13:40</p>
                <input className="parainputsed ml-3 " placeholder="Note:" />
              </div>
            </div>
            <br />
            <div className="d-flex">
              <input
                type="submit"
                value="Approve"
                className="viewdetcli ml-3"
              />
              <input type="submit" value="Reject" className="viewdetcli ml-3" />
            </div>
          </form>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default LoanCheck;
