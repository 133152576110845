import React, { Component } from "react";
import Navbar from "../../../components/Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../../SignOut";
import { link } from "../../link.js";
import Footer from "../../footer";
export class CreateMemberRound extends Component {
  render() {
    return (
      <div className="contentdashbord">
        <div className="welcome ">
          <h6 className="activejobs">Round Management</h6>
        </div>

        <div className="activeuser">
          <div className="col conten">Migrate user to new</div>
        </div>

        <div className="rowuno mt-3">
          <p
            type="text"
            id="lname"
            name="lname"
            //id="validationDefault01"
            required
            className="induka"
            placeholder="Last name : Doe"
          ></p>

          <p
            type="text"
            id="lname"
            name="lname"
            //id="validationDefault01"
            required
            className="induka"
            placeholder="Last name : Doe"
          >
            hello
          </p>
        </div>
        <div className="rowuno">
          <p
            id="validationDefault01"
            required
            className="induka"
            placeholder="Last name : Doe"
          >
            hello
          </p>

          <p
            type="text"
            id="lname"
            name="lname"
            //id="validationDefault01"
            required
            className="induka"
            placeholder="Last name : Doe"
          ></p>
          <p
            type="text"
            id="lname"
            name="lname"
            //id="validationDefault01"
            required
            className="induka"
            placeholder="Last name : Doe"
          ></p>
        </div>
        <div className=" cardtable">
          <form className="formcreate " method="post">
            <div className="rowuno">
              <div class="form-group">
                <input
                  id="fname"
                  type="text"
                  name="fname"
                  //id="validationDefault01"
                  required
                  className="logininputs"
                  placeholder="First name:John"
                />
              </div>
            </div>
            <div className="rowuno">
              <div class="form-group">
                <input
                  type="email"
                  id="param2"
                  name="email"
                  //id="validationDefault01"
                  required
                  className="logininputs"
                  placeholder="email: johndoe@gmail.com"
                />
              </div>
            </div>
            <div className="rowuno">
              <div class="form-group">
                <input
                  type="email"
                  id="param2"
                  name="email"
                  //id="validationDefault01"
                  required
                  className="logininputs"
                  placeholder="email: johndoe@gmail.com"
                />
              </div>
            </div>

            <input type="submit" value="Save" className="viewdetcli ml-5" />
            <br />
          </form>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default CreateMemberRound;
