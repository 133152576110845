import React, { Component } from "react";
import { api } from "./link";
export class Footer extends Component {
  render() {
    const version = localStorage.getItem("version");
    return (
      <div>
        <footer className="footer">
          <span className="ikimfoo">
            Copyright © 2023 IKIMINA TOWARDS THE SUCCESS. All rights reserved.
            v.{version}
          </span>

          <span className="poweredse">Powered By Sekadi Ltd</span>
          {process.env.REACT_APP_HOST_ENV !== "PRO" ? (
            <div>
              <span className="poweredse">{api()}</span>
            </div>
          ) : (
            ""
          )}
        </footer>
        {/* <footer className="footercard">
          <div className=" py-2">
            <div className="copyright">
              <strong>
                <span className="womenp"> </span>
              </strong>
              &copy;
              <strong>
                <span className="womenp">2022 All Rights Reserved. </span>
              </strong>
            </div>
          </div>
        </footer> */}
      </div>
    );
  }
}

export default Footer;
