import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
export class Success extends Component {
  render() {
    return (
      <div>
        <div className="schoolModal">
          <Modal show={this.state.openSucess} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="closeModal" onClick={this.closeSuucess}>
                  <i className="fas fa-times"></i>
                </p>
              </div>
              <div>
                <p className="sent">Successfully Sent!</p>
                <p className="succpar">
                  You have successfully sent you Interview application.
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Success;
