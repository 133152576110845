import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { link } from "../link.js";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
export class IcomeReport extends Component {
  constructor() {
    super();
    this.state = {
      filters: {
        datefrom: "",
        dateTo: "",
        userid: "",
        memberid: "",
        roundid: "",
        roundname: "",
        name: "",
        termstartdate: "",
        termduedate: "",
        status: "",
      },
      reportType: "contr",
      reportResults: [],
      totaltopay: "0.0",
      totalbalance: "0.0",
      totalpaid: "0.0",
    };

    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.getReport = this.getReport.bind(this);
  }

  getReport = (reptype) => {
    var token = localStorage.getItem("token");

    //let reptype = this.stage.reportType;
    var postData = {
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    };
    var headers = {
      "Content-Type": "application/json",
      //Authorization: `${token}`,
      Authorization: "1502930012",
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=report&rec=${reptype}`, postData, {
        headers: headers,
      })
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res);

        this.setState({
          reportResults: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount = () => {
    this.getReport("contr");
  };
  handleChangeSelect = (e) => {
    let reptype = this.stage;
    //let fitlers = this.state.payment;
    //payment.firstName = e.target.options[e.target.selectedIndex].text;
    // payment.userID = e.target.value;
    this.setState({
      reportType: e.target.value,
      //payment: payment,
    });

  //  .console.log(
   //   "Target= " + e.target.name + ", value= " + e.target.value
      //+
      //", content= " +
      //e.target.value
  //  );

    this.getReport(e.target.value);
    //console.log(this.state.payment);
  };

  handleCreateRound = () => {
    window.location.href = window.location + "Main/CreateRound";
  };
  handleShowMore = () => {
    window.location.href = window.location + "Main/LoanCheck";
  };
  handleBack = () => {
    window.location.href = window.location + "Main/Allloans";
  };

  render() {
    const { reportResults } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Reports</h6>
            <div className="report">
              <Link to="/Main/IncomeReport" className="contrilink">
                Income
              </Link>
              <Link to="/Main/ExpenseReport" className="contrilink">
                Expense
              </Link>
              <Link to="/Main/Cashbook" className="contrilink">
                Cash Book
              </Link>
              <Link to="/Main/financialstatements" className="contrilink">
                Financial Statements
              </Link>
              <Link to="/Main/otherreports" className="contrilink">
                Other Reports
              </Link>
            </div>
            <h6 className="activeuser mt-3">
              <div className="col conteinpu">
                Incomes:
                <span className="unity">
                  {" "}
                  {this.state.reportResults.roundName}{" "}
                </span>
              </div>
            </h6>
            <div className="cardtable ">
              <div className="membaddy">
                <div> Select Report</div>
                <select
                  id="reporttype"
                  type="text"
                  name="reporttype"
                  onChange={(e) => this.handleChangeSelect(e)}
                  selected={this.state.reportType}
                  className="logininputsselect"
                  placeholder="Select Bank type"
                >
                  <option value="incomeloan">Income From Loans</option>
                  <option value="contrsaving">Income From Contributions</option>
                  <option value="contrpen">Other Income</option>
                  <option value="penonlate">Adminstrative Expenses</option>

                  <option value="instal">Finance Expenses</option>
                  <option value="instal">Share Capital</option>

                  <option value="instal">Receivable</option>
                  <option value="instal">Payable</option>
                </select>
              </div>
              <div className="totaldetails">
                <div className="col conteinpu">
                  Total to Pay:
                  <span className="unity">
                    {" "}
                    {this.state.reportResults.currency}{" "}
                    {this.state.reportResults.topay}
                  </span>
                </div>
                <div className="col conteinpu">
                  Total Paid:
                  <span className="unity">
                    {" "}
                    {this.state.reportResults.currency}{" "}
                    {this.state.reportResults.paid}
                  </span>
                </div>
                <div className="col conteinpu">
                  Balance:
                  <span className="unity">
                    {" "}
                    {this.state.reportResults.currency}{" "}
                    {this.state.reportResults.balance}
                  </span>
                </div>
              </div>

              <table className="table table-hover  table-responsive  tablealot ">
                <thead>
                  <tr>
                    <th className="userpronyblue "> Month/Date</th>
                    <th className="userpronyblue ">Payer</th>
                    <th className="userpronyblue ">Category</th>
                    <th className="userpronyblue ">Type</th>
                    <th className="userpronyblue ">Description</th>
                    <th className="userpronyblue ">Curr</th>
                    <th className="userpronyblue ">Paid Date</th>
                    <th className="userpronyblue ">Total to Pay</th>
                    <th className="userpronyblue ">Paid</th>
                    <th className="userpronyblue ">Balance</th>
                    <th className="userpronyblue ">-</th>
                  </tr>
                </thead>
                <tbody>
                  {reportResults.attachment &&
                    reportResults.attachment.map((item) => {
                      //let dt = item.paiddate.toString().substring(0, 10);
                      return (
                        <tr>
                          <td className="userinfowe ">{item.month}</td>
                          <td className="userinfowe ">{item.name}</td>
                          <td className="userinfowe ">{item.category}</td>
                          <td className="userinfowe ">{item.type}</td>
                          <td className="userinfowe">{item.description}</td>
                          <td className="userinfowe">{item.currency}</td>
                          <td className="userinfowe">
                            {item.paiddate.toString().substring(0, 10)}
                          </td>
                          <td className="userinfowe">{item.topay}</td>
                          <td className="userinfowe">{item.paid}</td>
                          <td className="userinfowe">{item.balance}</td>
                          <td className="userinfowe">-</td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td className="userinfowe "></td>
                    <td className="userinfowe "></td>
                    <td className="userinfowe "></td>
                    <td className="userinfowe "></td>
                    <td className="userinfowe"></td>
                    <td className="userinfowe"></td>
                    <td className="userinfowe"></td>
                    <td className="userinfowe"></td>
                    <td className="userinfowe"></td>
                    <td className="userinfowe"></td>
                    <td className="userinfowe"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

export default IcomeReport;
