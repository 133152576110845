import React, { Component } from "react";
import Auth from "../Auth";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
export class SignOut extends Component {
  redirect = () => {
    const { history } = this.props;
    if (history) history.push("/");
  };

  logout() {
    Auth.SignOut();
  }

  handleLogOut = (e) => {
    e.preventDefault();
    this.logout();
    this.redirect();
  };
  render() {
    return (
      <div>
        <div className=" addinfo d-flex">
          {" "}
          <ul className="header-actions">
            <li className="dropdown">
              <a id="userSettings" className="user">
                <Link to="/Main/Overall">
                  <button className="profile">
                    <i className="fas fa-user-alt"></i>
                  </button>
                </Link>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="userSettings"
              >
                <div className="header-profile-actions">
                  <li className="iconlogout">
                    <span>
                      <i class="far fa-arrow-alt-circle-right"></i>
                      {"   "}
                    </span>
                    <Button className="logout" onClick={this.handleLogOut}>
                      Sign out
                    </Button>
                  </li>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(SignOut);
