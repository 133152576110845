import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import axios from "axios";
import SignOut from "../SignOut";
import { link, goto, numb2 } from "../link.js";
import Footer from "../footer";
import Loading from "../loading";
export class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      isLoading: false,
      searchItem: "",
      loading: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      isLoading: true,
      loading: true,
    });
    this.getAssets();
  };
  handleEdit = (id) => {
    goto("Main/EditAsset/" + id);
  };

  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  getAssets() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "faculty");
    axios
      .post(link() + `&do=get&ra=asset&ft=all`, postData, { headers: headers })
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res.data);
        //var member = this.state.member;

        if (Array.isArray(res.data)) {
          //setTimeout(() => {
          this.setState({
            assets: res.data,
            isLoading: false,
            loading: false,
          });
          //}, 1000);
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }

  render() {
    const { assets, loading } = this.state;
    var num = 1;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">System Settings</h6>
            <div className="membadd">
              <Link to="/Main/Members" className="contrilink">
                Users
              </Link>
              <Link to="/Main/Assets" className="contrilink">
                Assets
              </Link>
              <Link to="/Main/Usergroups" className="contrilink">
                User Groups
              </Link>
              <Link to="/Main/Adminsettings" className="contrilink">
                Round Settings
              </Link>
              <Link to="/Main/Profile" className="contrilink">
                Admin Settings
              </Link>
            </div>

            <div className="searchexp">
              <h6 className="activeuser mt-3">
                <div className="col conteinpu">
                  Asset List:
                  {/* <span className="unity"> {reportResults.roundName}</span> */}
                </div>
              </h6>
              <input
                type="text"
                id="search"
                className="loginsearchfa mt-4"
                placeholder=" Search Category"
                onChange={(e) => this.handleUserChange(e)}
              />
              <div className="btnaddblog" onClick={() => goto("Main/AddAsset")}>
                <i class="fas fa-plus"></i>
              </div>
            </div>

            <div className=" cardtable">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="containeruserscroll">
                    <table class="table table-hover  table-responsive  tablealot">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Asset Category</th>
                          <th>Type</th>
                          <th>Asset Name</th>
                          <th>Currency</th>
                          <th>Value</th>
                          <th></th>
                          <th>Purchase Date</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          // assets &&
                          //   assets
                          //     .filter((item) =>
                          //       item.assetCategory

                          //         ?.toLowerCase()
                          //         .includes(this.state.searchItem)
                          //     )
                          //     .map((items) => {
                          //       if (items.assetStatus !== "Cancelled")
                          assets &&
                            assets.map((items) => {
                              if (items.assetStatus !== "Cancelled") {
                                return (
                                  <tr>
                                    <td className="userinfo ">{num++}</td>
                                    <td className="userinfo ">
                                      {items.assetCategory}
                                    </td>
                                    <td className="userinfo ">{items.type}</td>

                                    <td className="userinfo ">{items.name}</td>
                                    <td className="userinfo ">
                                      {items.currency}
                                    </td>
                                    <td className="userinfo ">
                                      {numb2(items.purchaseValue)}
                                    </td>
                                    <td className="userinfo ">
                                      {items.roundName}
                                    </td>
                                    <td className="userinfo ">
                                      {items.purchaseDate
                                        ? items.purchaseDate.substring(0, 10)
                                        : ""}
                                    </td>
                                    <td className="userinfo ">
                                      <span
                                        className="viewdetclion "
                                        onClick={() =>
                                          this.handleEdit(items.assetID)
                                        }
                                      >
                                        Edit
                                      </span>
                                    </td>
                                  </tr>
                                );
                              } else {
                                return <p> No Data found</p>;
                              }
                            })
                        }
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Assets;
