import React, { Component } from "react";
import Auth from "../Auth";
import { Link, withRouter } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
class Siderbar extends Component {
  
  redirect = () => {
    const { history } = this.props;
    if (history) history.push("/");
  };

  logout() {
    Auth.SignOut();
  }

  handleLogOut = (e) => {
    e.preventDefault();
    this.logout();
    this.redirect();
    window.location.reload();
  };
  render() {
    return (
      <div>
        {" "}
        <nav className="sidebar-wrapper">
          <div className="sidebar-brand d-flex" id="sidebar">
            {/* <span className="logdashboard">IKIMINA MANAGEMENT</span> */}
            <Link to="/" className="logdashboard">
              IKIMINA MANAGEMENT
            </Link>
          </div>

          <div
            className="slimScrollDiv"
            style={{
              position: "relative",
              overflowY: "hidden",
              width: "auto",
              height: " 100%",
            }}
          >
            <div className="Nav_right">
              <ul className="ullinks ">
                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-columns"></i>
                  </span> */}

                  <Link className="Nav__link " to="/Main/Overall">
                    Overview
                  </Link>
                </li>
                {/* <li className="Nav__item"> */}
                {/* <span>
                    <i class="fas fa-user-edit"></i>
                  </span> */}
                {/* <Link className="Nav__link " to="/Main/Members">
                    Users
                  </Link>
                </li> */}

                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-money-bill-alt"></i>
                  </span> */}
                  <Link className="Nav__link " to="/Main/RoundInfo">
                    Rounds
                  </Link>
                </li>
                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-money-bill-alt"></i>
                  </span> */}
                  <Link className="Nav__link " to="/Main/Allloans">
                    Loan
                  </Link>
                </li>

                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-money-bill-alt"></i>
                  </span> */}
                  <Link className="Nav__link " to="/Main/RecordPayment">
                    Payments
                  </Link>
                </li>
                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-money-bill-alt"></i>
                  </span> */}
                  <Link className="Nav__link " to="/Main/IncomeReport">
                    Reports
                  </Link>
                </li>
                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-money-bill-alt"></i>
                  </span> */}
                  <Link className="Nav__link " to="/Main/Myapproval">
                    Requests
                  </Link>
                </li>
                <li className="Nav__item">
                  {/* <span>
                    <i class="fas fa-money-bill-alt"></i>
                  </span> */}
                  <Link className="Nav__link " to="/Main/Usergroups">
                    System Settings
                  </Link>
                </li>
                <li className="Nav__item">
                  <p className="Nav__link  log" onClick={this.handleLogOut}>
                    Log Out
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Siderbar;
