import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, gotopage, numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
import { confirm } from "../utils/Confirmation";
import Loading from "../loading";
export class Myapproval extends Component {
  constructor() {
    super();
    this.state = {
      requestlist: [],

      isLoading: false,
      comment: "",
      searchItem: "",
      approvalinfo: { approverid: "", action: "", requestid: "", note: "" },
      openModal: false,
      focusrequest: {},
      loading: false,
      dataOut: 15,
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.handleChangeSelect = this.handleChangeSelect.bind(this);
    //this.handleChangeDate = this.handleChangeDate.bind(this);
    //this.getReport = this.getReport.bind(this);
  }

  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  getRequests = (reptype) => {
    var token = localStorage.getItem("token");

    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      //Authorization: `${token}`,
      Authorization: "1502930012",
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=approval&ft=all`, postData, {
        headers: headers,
      })
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res.data);
        if (Array.isArray(res.data))
          this.setState({
            requestlist: res.data,
            loading: false,
          });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getRequests("");
  };

  handleApprove = (e, dec) => {
    e.preventDefault();

    var approvalinfo = this.state.approvalinfo;
  //  .console.log(
    //   "1. Request set to " +
    //     dec +
    //     " = " +
    //     approvalinfo.itemName +
    //     ", userid= " +
    //     approvalinfo.name
    // );

    approvalinfo.action = dec;
    approvalinfo.note = this.state.comment;

    this.setState({
      approvalinfo: approvalinfo,
      focusrequest: {},
    });

    this.updateChanges();
  };

  handleChange = (e) => {
  //  .console.log("2. Adding comment= " + e.target.value);
    this.setState({
      comment: e.target.value,
    });
  };

  async updateChanges() {
    //e.preventDefault();

  //  .console.log("Submitting approval decision");
    var token = localStorage.getItem("token");
    var postData = this.state.approvalinfo;

    if (
      this.state.focusrequest.type === "New" &&
      this.state.focusrequest.linkRaID === "round"
    ) {
      if (
        await confirm(
          "This action can cause serious and consequences. You must ensure you have verified all pre-requisit configuration before approving a round activation. Do you still want to continue?"
        )
      ) {
      } else {
        return;
      }
    }

    var headers = {
      "Content-Type": "application/json",
      //Authorization: `${token}`,
      Authorization: "1502930012",
    };

    //localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=action&ra=approval`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res.data);

        this.getRequests("");
      })
      .catch((err) => {
        this.setState({
          isitLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });

    this.closeModal();
  }
  openModal = (item) => {
    console.log(
      "1. Request to process= " + item.itemName + ", userid= " + item.name
    );
    var approvalinfo = this.state.approvalinfo;

    approvalinfo.userid = item.approverID;

    approvalinfo.requestid = item.approvalID;
    approvalinfo.note = this.state.comment;

    this.setState({
      approvalinfo: approvalinfo,
      openModal: true,
      focusrequest: item,
    });
  };

  closeModal = () => {
    let approvalinfo = this.state.approvalinfo;
    approvalinfo.userid = "";
    approvalinfo.action = "";
    approvalinfo.requestid = "";
    approvalinfo.note = "";

    this.setState({
      comment: "",
      openModal: false,
      approvalinfo: approvalinfo,
    });
  };
  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 20,
      };
    });
  };

  render() {
    const { requestlist, focusrequest, loading } = this.state;

    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Requests</h6>
            {/* <div className="report">
              <Link to="/Main/Usergroups" className="contrilink"> 
                My Requests
              </Link> 
              
            </div> */}
            <h6 className="activeuser mt-3">
              <div className="col conteinpu">
                {/* My Approval: */}
                <span className="unity"> My Requests:</span>
              </div>
            </h6>
            <div className="cardtable ">
              {/* {reportResults &&
                reportResults.map((item) => {
                  return ( */}
              <div>
                <div className=" d-flex">
                  <div className="totaldetails">
                    <div className="col conteinpu unity">
                      My Approval Records
                      {/* <div
                            className="btnaddblogme "
                            onClick={() => this.openModal(item)}
                          >
                            <i class="fas fa-plus"></i>
                          </div> */}
                    </div>
                  </div>
                  <div className="membadd ml-auto">
                    <input
                      type="text"
                      id="search"
                      className="loginsearchfaro mb-3"
                      placeholder=" Search  approver name..."
                      onChange={(e) => this.handleUserChange(e)}
                    />
                  </div>
                </div>
                <br />
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <table className="table table-hover  table-responsive  tablealot">
                      <thead>
                        <tr>
                          <th className="userpronyblue ">Date</th>
                          <th className="userpronyblue ">ID</th>
                          <th className="userpronyblue ">Title</th>
                          <th className="userpronyblue ">Approver</th>
                          <th className="userpronyblue ">Submitter</th>
                          <th className="userpronyblue ">Amount</th>
                          <th className="userpronyblue ">Submitter Note</th>
                          <th className="userpronyblue ">Status</th>
                          <th className="userpronyblue ">Approved Date</th>
                          <th className="userpronyblue ">Rejected Date</th>
                          <th className="userpronyblue "></th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestlist &&
                          requestlist
                            .filter((grd) =>
                              grd.names
                                ?.toLowerCase()
                                .includes(this.state.searchItem)
                            )
                            .slice(0, this.state.dataOut)
                            .map((request) => {
                              //let dt = item.paiddate.toString().substring(0, 10);
                              return (
                                <tr>
                                  <td className="userinfo ">
                                    {request.requestDate
                                      .substring(0, 19)
                                      .replace("T", " ")}
                                  </td>

                                  <td className="userinfo ">
                                    {request.approverID}
                                  </td>

                                  <td
                                    className="userinfo cursorPoint blueColor"
                                    onClick={() =>
                                      gotopage(request.linkRaID, request.itemID)
                                    }
                                  >
                                    {request.itemName}
                                  </td>
                                  <td className="userinfo ">{request.names}</td>
                                  <td className="userinfo ">
                                    {request.requestorName}
                                  </td>
                                  <td className="userinfo ">
                                    {request.currency}
                                    {numb2(request.amount)}
                                  </td>
                                  <td className="userinfo ">{request.note}</td>
                                  <td className="userinfo ">
                                    {request.approvalStatus}
                                  </td>

                                  <td className="userinfo ">
                                    {request.approvalDate &&
                                      request.approvalDate.substring(0, 10)}
                                  </td>
                                  <td className="userinfo ">
                                    {request.approvalStatus === "Rejected"
                                      ? request.rejectionDate &&
                                        request.rejectionDate.substring(0, 10) +
                                          ": " +
                                          request.rejectionNote
                                      : ""}
                                  </td>
                                  <td className="userinfo ">
                                    {request.approvalStatus === "Pending" ? (
                                      <button
                                        className="btndistr "
                                        onClick={() => this.openModal(request)}
                                      >
                                        Take Action
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        {requestlist &&
                        requestlist.length <= this.state.dataOut ? (
                          ""
                        ) : (
                          <div className="buttoncenter">
                            {requestlist &&
                            requestlist.length >= this.state.dataOut ? (
                              <button
                                type="button"
                                className="btnbookrermore  "
                                onClick={() => this.loadmore()}
                              >
                                <span className="mr-2 mt-1">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                                <span>Load More</span>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
                <br />
              </div>
              {/* );
                })} */}
              {/* <>-----------------------------------------------------------------</> */}

              <Modal show={this.state.openModal} className="modalAll">
                <Modal.Body className="Madl">
                  <div>
                    <p className="closeModal" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className=" conteinputitle ">
                    <div className=" ">
                      Category:
                      <span className="unity">
                        {" "}
                        {focusrequest.category}-{focusrequest.type}
                      </span>
                    </div>
                  </div>
                  <div className="rowuno mt-2">
                    <div className="myappr">
                      <p className="parainputsapp">
                        <div className=" conteinpu ">
                          Request Title:
                          <span className="unity">
                            {" "}
                            {focusrequest.itemName}
                          </span>
                        </div>
                      </p>
                      <p className="parainputsapp">
                        <div className="conteinpu ">
                          Request Date:
                          <span className="unity">
                            {" "}
                            {focusrequest.requestDate &&
                              focusrequest.requestDate.substring(0, 10)}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="rowuno mt-1">
                    <div className="myappr">
                      <p className="parainputsapp">
                        <div className=" conteinpu ">
                          Requestor:
                          <span className="unity">
                            {" "}
                            {focusrequest.requestorName}
                          </span>
                        </div>
                      </p>
                      <p className="parainputsapp">
                        <div className=" conteinpu ">
                          Request Amount:
                          <span className="unity">
                            {focusrequest.currency}
                            {focusrequest.amount}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="rowuno mt-2 d-block">
                    <div>
                      <label className="conteinpu ml-2">Requestor Note:</label>
                    </div>

                    <textarea
                      className="parainputsmestextay"
                      name="justification"
                      id="validationDefault01"
                      disabled="true"
                      value={focusrequest.note}
                    />
                  </div>

                  <div className="rowuno  mt-2 d-block">
                    <div>
                      <label className="conteinpu ml-2 "> Enter Remark:</label>
                    </div>
                    <textarea
                      type="text"
                      id="comment"
                      // id="validationDefault01"
                      required
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.comment}
                      name="comment"
                      className="parainputsmestexta"
                      placeholder="Enter your comment"
                    />
                  </div>

                  {/* //-------------------- */}

                  <div className=" conteinpu ml-5 mt-3">
                    <span className=" conteinpu ml-5">
                      <button
                        className="viewdetcli"
                        method="post"
                        onClick={(e) => this.handleApprove(e, "Approved")}
                      >
                        I Approve
                      </button>
                    </span>
                    <span className=" conteinpu ml-3">
                      <button
                        className="viewdetcli"
                        method="post"
                        onClick={(e) => this.handleApprove(e, "Rejected")}
                      >
                        I Reject
                      </button>
                    </span>
                  </div>
                  <div className="d-block  ml-3"></div>
                </Modal.Body>
              </Modal>
            </div>
            <br />
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Myapproval;
