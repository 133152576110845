import React, { Component } from "react";

export class Loading extends Component {
  render() {
    return (
      <div>
        <div className="mt-5 mr-5">
          <div className="text-center ">
            <div className=" colorspino spinner-grow " role="status"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
