import "./App.css";
import React from "react";
import Main from "./components/Main";
import Login from "./components/Login";

import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";
import Auth from "./Auth";
import Home from "./components/home";
import Reset from "./components/Reset";

function App() {
  // console.log("href=" + window.location.href);
  // console.log("Origin=" + window.location.origin);
  // console.log("pathname=" + window.location.pathname);
  // console.log("host=" + window.location.host);
  //alert(process.env.REACT_APP_HOST_ENV);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Auth.getAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/Login",
            }}
          />
        )
      }
    />
  );
  return (
    <div className="page-wrapper">
      {/* <Router basename="/its"> */}

      <HashRouter>
        <Route exact path="/" component={Home} />
        <PrivateRoute path="/Main" component={Main} />
        <Route exact path="/Login" component={Login} />
        {/* <Route path="/Main" component={Main} /> */}
        <Route path="/Reset" component={Reset} />
        {/* </Router> */}
      </HashRouter>
    </div>
  );
}

export default App;
