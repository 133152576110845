import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../SignOut";
import { link, goto, numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Footer from "../footer";
export class LoanRequest extends Component {
  constructor() {
    super();
    this.state = {
      payment: {
        terms: "5",
        userID: "",
        topup: "",
        roundID: "",
        interestrate: "3",
        capital: 0,
        justification: "",
        category: "loan",
        applicationFeeRate: 1,
      },
      loanstatus: {},
      users: [],
      selectedUser: "",
      opensSuccess: false,
      openError: false,
      loading: false,
      startDate: new Date(),
      endDate: new Date(),
      btvalue: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  componentDidMount = () => {
    this.getUser();
  };

  getUser() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "faculty");
    axios
      .post(
        link() + `&do=getusers&ra=round`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: Users ", res.data);
        if (Array.isArray(res.data))
          this.setState({
            users: res.data,
          });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }

  getLoanStatus(id) {
    var token = localStorage.getItem("token");

    if (id === "") {
      console.log("No userid");
      this.setState({ loanstatus: {} });
      return;
    }

    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "faculty");
    axios
      .post(
        link() + `&do=status&ra=loan&userID=` + id,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: loanstatus ", res.data);
        //if (Array.isArray(res.data))
        this.setState({
          loanstatus: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }

  handleChangeSelect = (e) => {
    let payment = this.state.payment;
    payment.firstName = e.target.options[e.target.selectedIndex].text;
    payment.userID = e.target.value;
    this.setState({
      selectedUser: e.target.value,
      payment: payment,
    });
    this.getLoanStatus(e.target.value);
  };

  handlePayment = () => {
    window.location.href = window.location + "Main/RecordPayment";
  };
  handleExpense = () => {
    window.location.href = window.location + "Main/RecordExpenses";
  };
  handleVerify = () => {
    window.location.href = window.location + "Main/PaymentApproval";
  };

  takeMeToLoan = (id) => {
    if (id !== "") goto("Main/LoanDetails/" + id);
  };

  handleChange = (e) => {
    let payment = this.state.payment;
    payment[e.target.name] = e.target.value;
    this.setState({ payment });
  };

  handleChangeDate(date) {
    this.setState({
      startDate: date,
    });
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.setState({ btvalue: true });

    var newLoan = JSON.stringify({
      userID: this.state.payment.userID,
      capital: this.state.payment.capital,
      category: this.state.payment.category,
      startDate: format(Date.parse(this.state.startDate), "yyyy-MM-dd"),
      terms: this.state.payment.terms,
      rate: this.state.payment.interestrate,
      note: this.state.payment.justification,
      topup: this.state.payment.topup,
      applicationFeeRate: this.state.payment.applicationFeeRate,
    });

    var token = localStorage.getItem("token");
    //var cont;
    var config = {
      method: "post",
      url: link() + `&do=create&ra=loan`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newLoan,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);

        this.setState({ btvalue: false });
        //cont.push(response.data);

        if (response.data !== "Success") alert("Error: " + response.data);
      })
      .catch((error) => {
        console.log(error);

        alert("Something went wrong. Please try again later. " + error);
        //this.setState({ btvalue: false });
      });

    goto("Main/Allloans");
  };

  render() {
    const { users, loanstatus } = this.state;
    //console.log(this.state.selectedUser);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Loan</h6>
        </div>

        <h6 className="activeuser">Create Loan Request</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div class="form-group">
                <label className="lebelenter"> Select Beneficiary</label>
                <select
                  className="form-select  logininputsselect"
                  value={this.state.selectedUser}
                  required
                  onChange={(e) => this.handleChangeSelect(e)}
                  name="userID"
                  id="userID"
                >
                  <option value=""> select</option>

                  {users &&
                    users
                      .sort((persone, perstwo) => {
                        if (
                          persone.firstName.toLowerCase() <
                          perstwo.firstName.toLowerCase()
                        )
                          return -1;
                        if (
                          persone.firstName.toLowerCase() >
                          perstwo.firstName.toLowerCase()
                        )
                          return 1;
                        return 0;
                      })
                      .map((aca) => (
                        <option value={aca.userID} id={aca.userID}>
                          {aca.firstName} {aca.lastName}
                        </option>
                      ))}
                </select>
              </div>
              <div class="form-group">
                <label className="lebelenter">
                  {" "}
                  Requested Capital (in RWF)
                </label>
                <input
                  type="number"
                  id="amount"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.capital}
                  name="capital"
                  className="logininputs"
                  placeholder="Enter Amount"
                />
              </div>

              <div class="form-group">
                <div>
                  <label className="lebelenter">Effective Date</label>
                </div>
                <DatePicker
                  selected={this.state.startDate}
                  //onChange={(e) => this.handleChangeDate(e)}
                  onChange={this.handleChangeDate}
                  name="effectiveDate"
                  //minDate={this.state.endDate}
                  id="validationDefault01"
                  required
                  className="logininputs"
                  dateFormat="yyyy-MM-dd"
                  placeholder="Pick a date"
                />
              </div>
            </div>
            <div className="rowuno ">
              <div class="form-group">
                <label className="lebelenter">Interest Rate (%)</label>
                <input
                  type="number"
                  id="interestrate"
                  name="interestrate"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.interestrate}
                  className="logininputs"
                  placeholder="Interest Rate (%)"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Terms</label>
                <input
                  type="number"
                  id="Terms"
                  name="terms"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.terms}
                  className="logininputs"
                  placeholder="Terms:"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Top Up Fee</label>
                <input
                  type="number"
                  id="topup"
                  // id="validationDefault01"
                  //required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.topup}
                  name="topup"
                  className="logininputs"
                  placeholder="Enter Amount"
                />
              </div>
            </div>
            <div className="rowuno ">
              <div class="form-group">
                <label className="lebelenter">Application Fee Rate (%)</label>
                <input
                  type="number"
                  id="applicationFeeRate"
                  // id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.applicationFeeRate}
                  name="applicationFeeRate"
                  className="logininputs"
                  placeholder="Enter rate: 1"
                />
              </div>
            </div>

            <div className="col ">
              {loanstatus.sharevalue ? (
                <div className="contenblue">
                  Member Share Value =
                  <span className="unity">
                    {loanstatus.currency} {numb2(loanstatus.sharevalue)}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                className="contenblue cursorPoint"
                onClick={() =>
                  this.takeMeToLoan(
                    loanstatus.attachment && loanstatus.attachment[0]
                      ? loanstatus.attachment[0].loanID
                      : ""
                  )
                }
              >
                Beneficiary’s other existing loans:{" "}
                {!loanstatus.activeloans ? (
                  <span className="unity">0</span>
                ) : (
                  <span className="unity">{loanstatus.activeloans}</span>
                )}
              </div>
              <label className="lebelenter">Justification</label> <br />
              <textarea
                className="parainputsmestext"
                placeholder="Write a justification for approvals to understand more about the request."
                name="justification"
                id="validationDefault01"
                required
                onChange={(e) => this.handleChange(e)}
                value={this.state.payment.justification}
              />
            </div>
            <br />
            <div className="d-flex">
              {this.state.btvalue ? (
                <button
                  type="button"
                  //value="loading..."
                  className="viewdetcli ml-3"
                >
                  loading...
                </button>
              ) : (
                <input
                  type="submit"
                  value="Submit"
                  className="viewdetcli ml-3"
                />
              )}
            </div>

            <br />
          </form>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default LoanRequest;
