import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
export class MemberDetails extends Component {
  constructor() {
    super();
    this.state = {
      request: {
        datefrom: "",
        dateTo: "",
        userid: "",
        memberid: "",
        roundid: "",
        roundname: "",
        loanid: "",
        name: "",
        termstartdate: "",
        termduedate: "",
        status: "",
      },
      reportType: "",
      reportResults: {},
      users: null,
      openModal: false,
      memberinfo: {},
      itemkey: "",
      itemname: "",
    };

    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.getReport = this.getReport.bind(this);
  }

  getMember() {
    var token = localStorage.getItem("token");
    let request = this.state.request;
    var userID = request.userid;
    var memberID = request.memberid;

    if (userID === "" && memberID === "") return;
    var id = userID === "" ? memberID : userID;
    var elmt = userID === "" ? "memberID" : "userID";

    //console.log("calling elmt= " + elmt + "= " + id);

    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    axios
      .post(
        link() + `&do=get&ra=member&ft=rec&elmt=${elmt}&id=${id}`,
        {},
        {
          headers: headers,
        }
      )
      .then((res) => {
        //  .console.log("RESPONSE RECEIVED: member ", res.data);

        this.setState({
          memberinfo: res.data,
        });

        setTimeout(this.getReport(this.state.reportType), 2000);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
        //return false;
      });
  }

  getReport(toget) {
    this.setState({ reportResults: {} });
    var token = localStorage.getItem("token");

    //console.log("calling requestype= " + toget);
    var postData = this.state.request;
    var requesttype = toget === "" ? this.state.reportType : toget;
    //console.log("will get= " + requesttype);
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=report&rec=${requesttype}`, postData, {
        headers: headers,
      })
      .then((res) => {
        //  .console.log("RESPONSE RECEIVED: report ", res.data);

        this.setState({
          reportResults: res.data,
        });

        // this.getMember();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }

  processRequest = (rtype) => {
    let path = window.location.hash;
    let memberId = path.split("/")[3];
    let idType = path.split("/")[4] ? path.split("/")[4] : "user";
    // let requestType = path.split("/")[5];

    if (idType === "") idType = "user";

    let request = this.state.request;
    if (idType === "user") request.userid = memberId;
    if (idType === "member") request.memberid = memberId;
    if (idType === "loan") request.loanid = memberId;

    if (rtype !== "") {
      //console.log("updating requestype= " + rtype);
      this.setState({
        reportType: rtype,
      });
    } else {
      rtype = this.state.reportType;
    }

    if (rtype === "") return;
    this.setState({
      request: request,

      reportResults: {},
    });
    this.getMember();
  };

  componentDidMount = () => {
    let path = window.location.hash;
    let requestType = path.split("/")[5] ? path.split("/")[5] : "memberround";

    // console.log(
    //   "requestype= " + this.state.reportType + ", header=" + requestType
    // );
    this.processRequest(requestType);
  };
  handleChangeSelect = (e) => {
    this.setState({
      reportType: e.target.value,
    });

    //  .console.log("1. Target= " + e.target.name + ", value= " + e.target.value);

    this.getReport(e.target.value);
  };

  takeAction(e, action) {
    e.preventDefault();

    var token = localStorage.getItem("token");
    var postData = this.state.memberinfo;
    var config = {
      method: "post",
      url:
        link() +
        `&do=updateunique&w=${action}&ra=member&elmt=memberID&id=${this.state.memberinfo.memberID}&t=test`,
      data: postData,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        //  .console.log("Response of update=");
        //  .console.log(response.data);
        //this.closeModal();
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (e) => {
    //console.log("Changing month comment= " + e.target.value);

    const { memberinfo } = this.state;
    memberinfo[e.target.name] = e.target.value;
    this.setState({ memberinfo });

    //  .console.log(this.state.memberinfo);
  };

  openModal = (itemkey, itemname) => {
    //  .console.log("Modal Opened for key=" + itemkey + ", name= " + itemname);
    this.setState({
      openModal: true,
      errmessage: "",
      itemkey: itemkey,
      itemname: itemname,
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
      errmessage: "",
      itemkey: "",
      itemname: "",
    });
  };

  render() {
    const { reportResults, memberinfo } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Report</h6>
            <h6 className="activeuser mt-3">
              <div className="col conteinpu">
                <span className="unity">Member's Details</span>
                <span className="conteinpu">{reportResults.names}</span>
              </div>
            </h6>
            {memberinfo && memberinfo.userID !== "" ? (
              <div className="activeuser">
                <div class="row ">
                  <div class="col col-lg-4">
                    <div className=" conten">
                      Name:
                      <span className="unity">{reportResults.names}</span>
                    </div>
                    <div className=" conten">
                      Round name:
                      <span className="unity">{memberinfo.roundName}</span>
                    </div>
                  </div>
                  <div class="col col-lg-4">
                    <div className=" conten">
                      <div className="d-flex">
                        <span className="mt-2">Total Shares:</span>
                        {memberinfo.attachment &&
                        memberinfo.attachment.roundstatus === "Pending" ? (
                          <span
                            className="btnbookrer  redColor cursorPoint"
                            onClick={() =>
                              this.openModal(
                                "numberOfShares",
                                "Number of Shares"
                              )
                            }
                          >
                            <span className="mr-2">
                              {memberinfo.numberOfShares}
                            </span>
                            <i class="fas fa-edit"></i>
                          </span>
                        ) : (
                          <span className="unity">
                            {memberinfo.numberOfShares}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" conten">
                      Value of a Share:
                      <span className="unity">
                        {memberinfo.currency} {numb2(memberinfo.sharesValue)}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div className=" conten">
                      <div className="d-flex">
                        <span className="mt-2"> Round Payable Shares:</span>
                        {memberinfo.attachment &&
                        memberinfo.attachment.roundstatus === "Pending" ? (
                          <span
                            className="btnbookrer  redColor cursorPoint"
                            onClick={() =>
                              this.openModal(
                                "numberOfMemberships",
                                "Number of round payable shares"
                              )
                            }
                          >
                            <span className="mr-2">
                              {" "}
                              {memberinfo.numberOfMemberships}
                            </span>

                            <i class="fas fa-edit"></i>
                          </span>
                        ) : (
                          <span className="unity">
                            {memberinfo.numberOfMemberships}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" conten">
                      Monthly Payments:
                      <span className="unity">
                        {memberinfo.currency}{" "}
                        {numb2(
                          memberinfo.numberOfMemberships *
                            memberinfo.membershipTermAmount +
                            memberinfo.numberOfSavings *
                              memberinfo.savingTermAmount
                        )}
                      </span>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : (
              ""
            )}

            <div className="cardtable ">
              <div className="alldeta">
                <div className="membadd ">
                  <div className="lebelenter"> Select Report</div>

                  <select
                    id="reporttype"
                    type="text"
                    name="reporttype"
                    onChange={(e) => this.handleChangeSelect(e)}
                    selected={this.state.reportType}
                    className="logininputsselect"
                    placeholder="Select Bank type"
                  >
                    <option value="memberround">Contributions</option>
                    <option value="memberdistr">Distributions</option>
                    <option value="memberloan">Loan</option>
                    <option value="memberother">Others</option>
                  </select>
                  <div className=" searchadd ml-auto">
                    <button
                      className="btndistr d-none"
                      onClick={this.handleReport}
                    >
                      Send report
                    </button>
                  </div>
                </div>
                {reportResults && reportResults.attachment ? (
                  ""
                ) : (
                  <div>No data</div>
                )}
                {reportResults &&
                reportResults.attachment &&
                this.state.reportType === "memberround" ? (
                  <div>
                    <div className="activeuser">
                      <div className="unity">Monthly Contributions</div>
                    </div>
                    <table className="table table-hover  table-responsive  tablealot ">
                      <thead>
                        <tr>
                          <th className="userpronyblue "> Month/Date</th>
                          <th className="userpronyblue ">
                            Contribution To Pay
                          </th>
                          <th className="userpronyblue ">Penalty To Pay</th>
                          {/* <th className="userpronyblue ">Others To Pay</th> */}
                          <th className="userpronyblue ">Due Date</th>
                          <th className="userpronyblue ">Completion Date</th>
                          <th className="userpronyblue ">Late Days</th>
                          <th className="userpronyblue ">Contribution Paid</th>
                          <th className="userpronyblue ">Penalty Paid</th>
                          <th className="userpronyblue ">Paid Total</th>
                          <th className="userpronyblue ">Balance</th>
                          {/* <th className="userpronyblue "></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportResults.attachment &&
                          reportResults.attachment.map((member) => {
                            return (
                              member.Contribution.attachment &&
                              member.Contribution.attachment.map((item) => {
                                //let dt = item.paiddate.toString().substring(0, 10);
                                return (
                                  <tr>
                                    <td className="userinfowe ">
                                      {item.month}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.capitaltopay)}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.penaltytopay)}
                                    </td>
                                    <td className="userinfowe ">
                                      {item.duedate.toString().substring(0, 10)}
                                    </td>
                                    <td className="userinfowe">
                                      {item.paiddate
                                        .toString()
                                        .substring(0, 10)}
                                    </td>
                                    <td className="userinfowe"></td>
                                    <td className="userinfowe">
                                      {numb2(item.captialpaid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.penaltypaid)}
                                    </td>
                                    <td className="userinfowe">{item.paid}</td>
                                    <td className="userinfowe">
                                      {numb2(item.balance)}
                                    </td>

                                    {/* <td className="userinfowe">-</td> */}
                                  </tr>
                                );
                              })
                            );
                          })}
                        <tr>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          {/* <td className="userinfowe"></td> */}
                        </tr>
                      </tbody>
                    </table>

                    <div className="activeuser">
                      <div className="  unity">Social Capital</div>
                    </div>
                    <table className="table table-hover  table-responsive  tablealot ">
                      <thead>
                        <tr>
                          <th className="userpronyblue "> Month/Date</th>
                          <th className="userpronyblue ">
                            Contribution To Pay
                          </th>
                          <th className="userpronyblue ">Penalty To Pay</th>
                          {/* <th className="userpronyblue ">Others To Pay</th> */}
                          <th className="userpronyblue ">Due Date</th>
                          <th className="userpronyblue ">Completion Date</th>
                          <th className="userpronyblue ">Late Days</th>
                          <th className="userpronyblue ">Contribution Paid</th>
                          <th className="userpronyblue ">Penalty Paid</th>
                          <th className="userpronyblue ">Paid Total</th>
                          <th className="userpronyblue ">Balance</th>
                          {/* <th className="userpronyblue "></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportResults.attachment &&
                          reportResults.attachment.map((member) => {
                            console.log(member);
                            return (
                              member.Capital.attachment &&
                              member.Capital.attachment.map((item) => {
                                //let dt = item.paiddate.toString().substring(0, 10);
                                return (
                                  <tr>
                                    <td className="userinfowe ">
                                      {item.month}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.capitaltopay)}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.penaltytopay)}
                                    </td>
                                    <td className="userinfowe ">
                                      {item.duedate.toString().substring(0, 10)}
                                    </td>
                                    <td className="userinfowe">
                                      {item.paiddate
                                        .toString()
                                        .substring(0, 10)}
                                    </td>
                                    <td className="userinfowe"></td>
                                    <td className="userinfowe">
                                      {numb2(item.captialpaid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.penaltypaid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.paid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.balance)}
                                    </td>

                                    {/* <td className="userinfowe">-</td> */}
                                  </tr>
                                );
                              })
                            );
                          })}
                        <tr>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          {/* <td className="userinfowe"></td> */}
                          {/* <td className="userinfowe"></td> */}
                        </tr>
                      </tbody>
                    </table>

                    <div className="activeuser">
                      <div className=" unity">Commission</div>
                    </div>
                    <table className="table table-hover  table-responsive  tablealot ">
                      <thead>
                        <tr>
                          <th className="userpronyblue "> Month/Date</th>
                          <th className="userpronyblue ">
                            Contribution To Pay
                          </th>
                          <th className="userpronyblue ">Penalty To Pay</th>
                          {/* <th className="userpronyblue ">Others To Pay</th> */}
                          <th className="userpronyblue ">Due Date</th>
                          <th className="userpronyblue ">Completion Date</th>
                          <th className="userpronyblue ">Late Days</th>
                          <th className="userpronyblue ">Contribution Paid</th>
                          <th className="userpronyblue ">Penalty Paid</th>
                          <th className="userpronyblue ">Paid Total</th>
                          <th className="userpronyblue ">Balance</th>
                          {/* <th className="userpronyblue "></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportResults.attachment &&
                          reportResults.attachment.map((member) => {
                            return (
                              member.Commission.attachment &&
                              member.Commission.attachment.map((item) => {
                                //let dt = item.paiddate.toString().substring(0, 10);
                                return (
                                  <tr>
                                    <td className="userinfowe ">
                                      {item.month}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.capitaltopay)}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.penaltytopay)}
                                    </td>
                                    <td className="userinfowe ">
                                      {item.duedate.toString().substring(0, 10)}
                                    </td>
                                    <td className="userinfowe">
                                      {item.paiddate
                                        .toString()
                                        .substring(0, 10)}
                                    </td>
                                    <td className="userinfowe"></td>
                                    <td className="userinfowe">
                                      {numb2(item.captialpaid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.penaltypaid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.paid)}
                                    </td>
                                    <td className="userinfowe">
                                      {numb2(item.balance)}
                                    </td>

                                    {/* <td className="userinfowe">-</td> */}
                                  </tr>
                                );
                              })
                            );
                          })}

                        <tr>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          {/* <td className="userinfowe"></td> */}
                          {/* <td className="userinfowe"></td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}

                {reportResults &&
                reportResults.attachment &&
                this.state.reportType === "memberloan" ? (
                  <div>
                    <table className="table table-hover  table-responsive  tablealot ">
                      <thead>
                        <tr>
                          <th className="userpronyblue ">Names</th>
                          <th className="userpronyblue ">Loan Created Date</th>
                          <th className="userpronyblue ">Loan Start Date</th>
                          <th className="userpronyblue ">Capital to pay</th>
                          <th className="userpronyblue ">Interest to pay</th>
                          <th className="userpronyblue ">Moratoroire to pay</th>
                          <th className="userpronyblue ">Others to pay</th>
                          <th className="userpronyblue ">Due Date</th>
                          <th className="userpronyblue ">Completion Date</th>
                          <th className="userpronyblue ">Late Days</th>
                          <th className="userpronyblue ">Capital Paid</th>
                          <th className="userpronyblue ">Interest Paid</th>
                          <th className="userpronyblue ">Moratoroire Paid</th>
                          <th className="userpronyblue ">Other Paid</th>
                          <th className="userpronyblue ">Paid Total</th>
                          <th className="userpronyblue ">Balance</th>
                          {/* <th className="userpronyblue "></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportResults.attachment &&
                          reportResults.attachment.map((loan) => {
                            //  .console.log(loan);
                            return (
                              <tr>
                                <td className="userinfowe ">{loan.names}</td>
                                <td className="userinfowe ">
                                  {loan.requestdate
                                    ? loan.requestdate
                                        .toString()
                                        .substring(0, 10)
                                    : ""}
                                </td>
                                <td className="userinfowe ">
                                  {loan.effectivedate
                                    ? loan.effectivedate
                                        .toString()
                                        .substring(0, 10)
                                    : ""}
                                </td>
                                <td className="userinfowe ">
                                  {numb2(loan.capitaltopay)}
                                </td>

                                <td className="userinfowe ">
                                  {numb2(loan.interesttopay)}
                                </td>
                                <td className="userinfowe">
                                  {numb2(loan.penaltytopay)}
                                </td>
                                <td className="userinfowe">
                                  {numb2(loan.feetopay + loan.topuptopay)}
                                </td>
                                <td></td>
                                <td className="userinfowe">
                                  {loan.loanpaiddate.substring(0, 10)}
                                </td>
                                <td className="userinfowe">{loan.latedays}</td>
                                <td className="userinfowe">
                                  {numb2(loan.capitalpaid)}
                                </td>
                                <td className="userinfowe">
                                  {numb2(loan.interestpaid)}
                                </td>

                                <td className="userinfowe">
                                  {numb2(loan.penaltypaid)}
                                </td>
                                <td className="userinfowe">
                                  {numb2(loan.feepaid + loan.topuppaid)}
                                </td>
                                <td className="userinfowe">
                                  {numb2(loan.paid)}
                                </td>
                                <td className="userinfowe">
                                  {numb2(loan.balance)}
                                </td>

                                {/* <td className="userinfowe">-</td> */}
                              </tr>
                            );
                          })}
                        <tr>
                          <td className="userinfowe " colspan="16"></td>
                          {/* <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe">-</td> */}
                          {/* <td className="userinfowe"></td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}

                {reportResults &&
                reportResults.attachment &&
                this.state.reportType === "memberdistr" ? (
                  <div>
                    <div className="activeuser">
                      <div className="unity">Round Distributions</div>
                    </div>
                    <table className="table table-hover  table-responsive  tablealot ">
                      <thead>
                        <tr>
                          <th className="userpronyblue "> Month/Date</th>
                          <th className="userpronyblue ">
                            Distribution Amount
                          </th>
                          {/* <th className="userpronyblue ">Penalty To Pay</th>
                          <th className="userpronyblue ">Others To Pay</th> */}
                          <th className="userpronyblue ">Due Date</th>
                          <th className="userpronyblue ">Completion Date</th>
                          {/* <th className="userpronyblue ">Late Days</th> */}
                          <th className="userpronyblue ">Contribution Paid</th>
                          {/* <th className="userpronyblue ">Penalty Paid</th>
                          <th className="userpronyblue ">Paid Total</th> */}
                          <th className="userpronyblue ">Balance</th>
                          {/* <th className="userpronyblue "></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportResults.attachment &&
                          reportResults.attachment.map((member) => {
                            return (
                              member.distribution.attachment &&
                              member.distribution.attachment.map((item) => {
                                //let dt = item.paiddate.toString().substring(0, 10);
                                return (
                                  <tr>
                                    <td className="userinfowe ">
                                      {item.month}
                                    </td>
                                    <td className="userinfowe ">
                                      {numb2(item.capitaltopay)}
                                    </td>
                                    {/* <td className="userinfowe ">
                                      {item.penaltytopay}
                                    </td> */}
                                    <td className="userinfowe ">
                                      {item.duedate.toString().substring(0, 10)}
                                    </td>
                                    <td className="userinfowe">
                                      {item.paiddate
                                        .toString()
                                        .substring(0, 10)}
                                    </td>

                                    <td className="userinfowe">
                                      {numb2(item.capitalpaid)}
                                    </td>
                                    {/* <td className="userinfowe">
                                      {item.penaltypaid}
                                    </td>
                                    <td className="userinfowe">{item.paid}</td> */}
                                    <td className="userinfowe">
                                      {numb2(item.balance)}
                                    </td>

                                    {/* <td className="userinfowe">-</td> */}
                                  </tr>
                                );
                              })
                            );
                          })}
                        <tr>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe "></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          {/* <td className="userinfowe"></td>
                          <td className="userinfowe"></td> */}
                          {/* <td className="userinfowe"></td>
                          <td className="userinfowe"></td>
                          <td className="userinfowe"></td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />

            {/* <>-----------------------------------------------------------------</> */}

            <Modal show={this.state.openModal} className="modalAll">
              <Modal.Body className="Madl">
                <div className="detmod">
                  <div>
                    <p className="closeModale" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className=" conteinputitle ">
                    Update {this.state.reportResults.names}{" "}
                    {this.state.itemname}
                  </div>
                  <div className="d-flex">
                    <div className="conteinpu ml-3">
                      {/* Select the action you would like to take */}
                      {/* <p className="unity"> Give Back</p>
                      <p className="unity">Convert</p> */}
                      <div class="form-group">
                        <label className="lebelenter">
                          Change {this.state.itemname}:
                        </label>

                        <input
                          type="text"
                          id="actionamount"
                          name={this.state.itemkey}
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.memberinfo[this.state.itemkey]}
                          className="logininputsele"
                          //placeholder="Enter date: YYYY-MM"
                        />
                      </div>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, this.state.itemkey)}
                      >
                        Save
                      </button>
                      <p className="unity redColor">{this.state.errmessage}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default MemberDetails;
