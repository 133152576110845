import React, { Component } from "react";
import Navbar from "../../Navbar";
import { Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../../SignOut";
import { link, goto } from "../../link.js";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import axios from "axios";
import Footer from "../../footer";
export class CreateRound extends Component {
  constructor() {
    super();
    this.state = {
      newRound: {
        name: "",
        description: "",
        savingAmount: "",
        membershipAmount: "",
        shareValue: "",
        capitalDueDate: "",
        contributionStartDate: "",
        distributionStartDate: "",
        note: "",
      },
      startDate: new Date(),
      endDate: new Date(),
      openSucess: false,
      openError: false,
      loading: false,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuucess = () => {
    goto("Main/RoundInfo");
  };
  handleChangeDate = (item) => {
    //e.preventDefault();
    //console.log(item);
    let newRound = this.state.newRound;
    newRound[item.name] = item.value;
    this.setState({ newRound });

    //console.log(this.state.newRound);
  };
  handleChange = (e) => {
    let newRound = this.state.newRound;
    newRound[e.target.name] = e.target.value;
    this.setState({ newRound });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var newRoundCreate = JSON.stringify({
      name: this.state.newRound.name,
      description: this.state.newRound.description,
      savingAmount: this.state.newRound.savingAmount,
      membershipAmount: this.state.newRound.membershipAmount,
      shareValue: this.state.newRound.shareValue,
      capitalDueDate: format(this.state.newRound.capitalDueDate, "yyyy-MM-dd"),
      contributionStartDate: format(
        this.state.newRound.contributionStartDate,
        "yyyy-MM-dd"
      ),
      distributionStartDate: this.state.newRound.distributionStartDate,

      startDate: format(this.state.newRound.startDate, "yyyy-MM-dd"),
      endDate: format(this.state.newRound.endDate, "yyyy-MM-dd"),
      note: this.state.newRound.note,
    });

    var token = localStorage.getItem("token");
    var config = {
      method: "post",

      url: link() + `&do=create&ra=round`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: newRoundCreate,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="contentdashbord">
        <div className="welcome ">
          <h6 className="activejobs">Round Management</h6>
        </div>

        <h6 className="activeuser"> Create a Round</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div class="form-group">
                <label className="lebelenter">Round Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.name}
                  className="logininputs"
                  placeholder="name:"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Description</label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.description}
                  className="logininputs"
                  placeholder="description :"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Value of a Share</label>
                <input
                  type="number"
                  id="param2"
                  name="shareValue"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.shareValue}
                  className="logininputs"
                  placeholder="shareValue:"
                />
              </div>
            </div>
            <div className="rowuno">
              <div class="form-group">
                <label className="lebelenter">
                  Value of Round Payable Share
                </label>
                <input
                  type="number"
                  id="param2"
                  id="validationDefault01"
                  required
                  name="membershipAmount"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.membershipAmount}
                  className="logininputs"
                  placeholder="membershipAmount:"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">
                  Value of Mothly Reserve Share
                </label>
                <input
                  type="number"
                  id="savingAmount"
                  id="validationDefault01"
                  required
                  name="savingAmount"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.savingAmount}
                  className="logininputs"
                  placeholder="savingAmount: "
                />
              </div>

              <div class="form-group">
                <div>
                  <label className="lebelenter">Capital Payment Due Date</label>
                </div>
                {/* <input
                  type="text"
                  id="capitalDueDate"
                  name="capitalDueDate"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.capitalDueDate}
                  className="logininputs"
                  placeholder="capitalDueDate: "
                /> */}

                <DatePicker
                  selected={this.state.newRound.capitalDueDate}
                  onChange={(date) =>
                    this.handleChangeDate({
                      name: "capitalDueDate",
                      value: date,
                    })
                  }
                  //onChange={this.handleChangeDate}
                  name="capitalDueDate"
                  //maxDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  id="validationDefault01"
                  required
                  placeholderText="Pick a date"
                />

                {/* <DatePicker
                  selected={this.state.startDate}
                  //onChange={(e) => this.handleChangeDate(e)}
                  onChange={this.handleChangeDate}
                  name="capitalDueDate"
                  maxDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  placeholder="Pick a date"
                  id="validationDefault01"
                  required
                /> */}
                {/* <input
                  type="text"
                  id="uname"
                  name="capitalDueDate"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.capitalDueDate}
                  className="logininputs"
                  placeholder="capitalDueDate:"
                /> */}
              </div>
            </div>
            <div className="rowuno">
              {/* <div class="form-group">
                <label className="lebelenter">Distribution Start Date</label>
                <input
                  type="text"
                  id="param2"
                  name="distributionStartDate"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.distributionStartDate}
                  className="logininputs"
                  placeholder="distributionStartDate:"
                />
              </div> */}
              <div class="form-group">
                <label className="lebelenter">Round Start Date</label>
                {/* <input
                  type="text"
                  id="uname"
                  name="startDate"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.startDate}
                  className="logininputs"
                  placeholder="startDate:"
                /> */}

                <DatePicker
                  selected={this.state.newRound.startDate}
                  onChange={(date) =>
                    this.handleChangeDate({ name: "startDate", value: date })
                  }
                  //onChange={this.handleChangeDate}
                  name="startDate"
                  //maxDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  id="validationDefault01"
                  required
                  placeholderText="Pick a date"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Round End Date</label>
                {/* <input
                  type="text"
                  id="param2"
                  name="endDate"
                  id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.endDate}
                  className="logininputs"
                  placeholder="endDate:"
                /> */}

                <DatePicker
                  selected={this.state.newRound.endDate}
                  onChange={(date) =>
                    this.handleChangeDate({ name: "endDate", value: date })
                  }
                  //onChange={this.handleChangeDate}
                  name="endDate"
                  //maxDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  id="validationDefault01"
                  required
                  placeholderText="Pick a date"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Contribution Start Date</label>
                {/* <input
                  type="text"
                  id="param2"
                  id="validationDefault01"
                  required
                  name="contributionStartDate"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.newRound.contributionStartDate}
                  className="logininputs"
                  placeholder="contributionStartDate:"
                /> */}

                <DatePicker
                  selected={this.state.newRound.contributionStartDate}
                  onChange={(date) =>
                    this.handleChangeDate({
                      name: "contributionStartDate",
                      value: date,
                    })
                  }
                  //onChange={this.handleChangeDate}
                  name="contributionStartDate"
                  //maxDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  id="validationDefault01"
                  required
                  placeholderText="Pick a date"
                />
              </div>
            </div>
            <div className="mat ml-4">
              <label className="lebelenter">Note </label>
              <textarea
                type="text"
                id="param2"
                name="note"
                id="validationDefault01"
                required
                onChange={(e) => this.handleChange(e)}
                value={this.state.newRound.note}
                className="parainputsmestext"
                placeholder="Note to Approvers:"
              />
            </div>
            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>
            <br />
          </form>
          <div className="schoolModal">
            <Modal show={this.state.openSucess} className="modalAll">
              <Modal.Body className="Madl">
                <div>
                  <p className="btnaddblog" onClick={this.closeSuucess}>
                    <i className="fas fa-times"></i>
                  </p>
                </div>
                <div>
                  <p className="sent">Successfully Created!</p>
                  <p className="succpar">
                    You have successfully created a Round . Congrats!
                  </p>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default CreateRound;
