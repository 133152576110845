import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, goto, numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
import { confirm } from "../utils/Confirmation";
import Loading from "../loading";
export class AdminSettings extends Component {
  constructor() {
    super();
    this.state = {
      users: null,
      recordid: "",
      recordtype: "payment",
      reportResults: [],
      userid: "",
      //userlist: null,
      loading: false,
      openModal: false,
      groupinfo: { userid: "", action: "", groupid: "", gname: "" },
    };
    //this.getReport = this.getReport.bind(this);
  }

  getRounds = (reptype) => {
    var token = localStorage.getItem("token");

    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "AdminSettings");
    axios
      .post(link() + `&do=get&ra=round&ft=all`, postData, { headers: headers })
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res.data);
        if (Array.isArray(res.data))
          this.setState({
            reportResults: res.data,
            loading: false,
          });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getRounds("");
  };

  handleCreateRound = () => {
    //window.location.href = window.location.origin + "/Main/CreateRound";
    goto("Main/CreateRound");
  };

  handleViewRound = (roundid) => {
    // window.location.href =
    //   window.location.origin + `/Main/RoundInfo/${roundid}`;
    goto(`Main/RoundInfo/${roundid}`);
  };

  handleEditRound = (roundid) => {
    //e.preventDefault();
    // window.location.href =
    //   window.location.origin + "/Main/EditRound/" + roundid;
    goto(`Main/EditRound/${roundid}`);
  };

  async requestActivation(e, roundid, action) {
    //e.preventDefault();
    console.log("Action is " + action);
    if (action === "activate") {
      if (
        await confirm(
          "This request can cause serious and irreversible consequences. Do you want to continue?"
        )
      ) {
      } else {
        return;
      }
    }
    console.log("Requesting Activationuseradd");
    var token = localStorage.getItem("token");
    var postData = this.state.groupinfo;
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    //localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=${action}&ra=round&elmt=roundID&id=${roundid}`,

        postData,
        { headers: headers }
      )
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res.data);
        alert("Request submitted.");
        this.getRounds("");
      })
      .catch((err) => {
        this.setState({
          isitLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
        alert("Something went wrong. Please try again later.");
      });

    this.closeModal();
  }

  async requestCancel() {
    //e.preventDefault();
    console.log("Request " + this.state.recordtype + " (" + this.state.recordid + ") cancellation");

    if(this.state.recordtype ==="" || this.state.recordid==="") {alert("Error. ID or type cannot be empty.");

      return;
    }
    //if (action === "activate") {
    if (
      await confirm(
        "This action can cause serious and irreversible damages. Do you want to continue?"
      )
    ) {
    } else {
      return;
    }
    //}
    //console.log("Requesting Activationuseradd");
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    //localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=cancel&ra=${this.state.recordtype}&${this.state.recordtype}ID=${this.state.recordid}`,

        postData,
        { headers: headers }
      )
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res.data);
        if(res.data!="Success") alert("Something went wrong. Please try again later.");
       // this.getRounds("");
      })
      .catch((err) => {
        this.setState({
          isitLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
        alert("Something went wrong. Please try again later.");
      });

    // this.closeModal();
  }

  handleInputChange = (e) => {
    e.preventDefault();
    //let id = this.state.id;
    // id = e.target.value;
    this.setState({ recordid: e.target.value });

  }

  handleNewType = (e) => {
    e.preventDefault();
    //let id = this.state.id;
    // id = e.target.value;
    this.setState({ recordtype: e.target.value });

  }

  openModal = (groupelement) => {
    //console.log("1. Target= " + e.target.name + ", value= " + e.target.value);
    this.getUser();
    var groupinfo = this.state.groupinfo;

    groupinfo.userid = groupelement.userID;
    groupinfo.action = "adduser";
    groupinfo.groupid = groupelement.mgroupID;
    groupinfo.gname = groupelement.otherName;
    this.setState({
      openModal: true,
      //recordtoverify: recordv,
      groupinfo: groupinfo,
    });

    console.log(
      "1. Selected Group= " +
      groupelement.otherName +
      ", action= " +
      groupinfo.action
    );
  };
  closeModal = () => {
    let groupinfo = this.state.groupinfo;

    groupinfo.userid = "";
    groupinfo.action = "adduser";
    groupinfo.groupid = "";
    groupinfo.gname = "";
    this.setState({
      openModal: false,
      groupinfo: groupinfo,
    });
  };

  render() {
    const { reportResults, groupinfo, users, loading } = this.state;

    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">System Settings</h6>
            <div className="membadd">
              <Link to="/Main/Members" className="contrilink">
                Users
              </Link>
              <Link to="/Main/Assets" className="contrilink">
                Assets
              </Link>
              <Link to="/Main/Usergroups" className="contrilink">
                User Groups
              </Link>
              <Link to="/Main/Adminsettings" className="contrilink">
                Round Settings
              </Link>
              <Link to="/Main/Profile" className="contrilink">
                Admin Settings
              </Link>
            </div>
            <h6 className="activeuser mt-3">
              <div className="col conteinpu">
                Admin Settings:
                {/* <span className="unity"> {reportResults.roundName}</span> */}
              </div>
            </h6>
            <div className="cardtable ">
              <div className="membadd">
                <p className=" contentmemb"> Round List</p>

                <div
                  className="btnaddblogaddsd  "
                  onClick={this.handleCreateRound}
                >
                  <i class="fas fa-plus"></i>
                </div>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div>
                    <table className="table table-hover  table-responsive  tablealot">
                      <thead>
                        {" "}
                        <tr>
                          <th className="userpronyblue ">Round ID</th>
                          <th className="userpronyblue ">Name</th>
                          <th className="userpronyblue ">Start Date</th>
                          <th className="userpronyblue ">End Date</th>
                          <th className="userpronyblue ">Share Value</th>
                          <th className="userpronyblue ">
                            Monthly Reserve
                            <br />
                            Share Value
                          </th>
                          <th className="userpronyblue ">
                            Monthly Payable
                            <br />
                            Share Value
                          </th>
                          <th className="userpronyblue ">
                            Capital Contribution
                            <br />
                            Due Date
                          </th>
                          <th className="userpronyblue ">Status</th>
                          <th className="userpronyblue "></th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportResults &&
                          reportResults.map((round) => {
                            return (
                              <tr>
                                <td className="userinfo ">{round.roundID}</td>
                                <td
                                  className="userproneruloan  userinfo "
                                  // className="userproneruloan  "
                                  onClick={(e) =>
                                    this.handleViewRound(round.roundID)
                                  }
                                >
                                  {round.name}
                                </td>
                                <td className="userinfo ">
                                  {round.startDate
                                    ? round.startDate
                                      .toString()
                                      .substring(0, 10)
                                    : ""}
                                </td>
                                <td className="userinfo ">
                                  {round.endDate
                                    ? round.endDate.toString().substring(0, 10)
                                    : ""}
                                </td>
                                <td className="userinfo ">
                                  {round.currency}
                                  {numb2(round.shareValue)}
                                </td>
                                <td className="userinfo ">
                                  {round.currency}
                                  {numb2(round.savingContributionAmount)}
                                </td>
                                <td className="userinfo ">
                                  {round.currency}
                                  {numb2(round.membershipContributionAmount)}
                                </td>
                                <td className="userinfo ">
                                  {round.capitalDueDate
                                    ? round.capitalDueDate
                                      .toString()
                                      .substring(0, 10)
                                    : ""}
                                </td>
                                <td className="userinfo ">
                                  {round.roundStatus}
                                </td>
                                <td className="d-flex ">
                                  {round.current === "Yes" ||
                                    round.roundStatus === "Pending" ? (
                                    <span
                                      className="Editbuttonstuword  mr-3 "
                                      onClick={(e) =>
                                        this.handleEditRound(round.roundID)
                                      }
                                    >
                                      {" "}
                                      Edit
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {round.roundStatus === "Pending" ? (
                                    <span
                                      className="Editbuttonstuwo "
                                      onClick={(e) =>
                                        this.requestActivation(
                                          e,
                                          round.roundID,
                                          "activate"
                                        )
                                      }
                                    >
                                      Request Activation
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {round.roundStatus === "Pending" ? (
                                    <span
                                      className="Editbuttonstuwo mr-3 "
                                      onClick={(e) =>
                                        this.requestActivation(
                                          e,
                                          round.roundID,
                                          "migrate"
                                        )
                                      }
                                    >
                                      Import Members
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <br />
                  </div>
                </>
              )}
              {/* <>-----------------------------------------------------------------</> */}
              <Modal show={this.state.openModal} className="modalAll">
                <Modal.Body className="Madl">
                  <div>
                    <p className="closeModal" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className="rowuno mt-3">
                    <div className="row ml-3">
                      <div className="col conteinpu ml-3">
                        Group:
                        <span className="unity">
                          {" "}
                          {this.state.groupinfo.gname}
                        </span>
                      </div>
                      <div className="col conteinpu ml-5">
                        Add User:
                        <select
                          className="form-select  logininputsselect"
                          value={groupinfo.userid}
                          required
                          onChange={(e) => this.handleChangeSelect(e)}
                          //disabled={this.state.hideuserid ? true : null}
                          name="userID"
                          id="userID"
                        >
                          <option value=""> user</option>

                          {users &&
                            users.map((aca) => (
                              <option value={aca.userID} id={aca.userID}>
                                {aca.firstName} {aca.lastName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="d-block  ml-5">
                        <button
                          className="viewdetcli"
                          method="post"
                          onClick={(e) => this.sendChanges(e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="cardtable ">
              <div className="membadd">
                <p className=" contentmemb"> Remove Record</p>


              </div>
              {loading ? (
                <Loading />
              ) : (

                <div className="membadd">
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      Select Record Type:

                      <select
                        id="recordtype"
                        type="text"
                        name="recordtype"
                        className="logininputsselectyu"
                        placeholder="Select type"
                        value={this.state.selectedtype}
                        required
                        onChange={(e) => this.handleNewType(e)}
                      >
                        <option value="payment">Payment</option>
                        <option value="spending">Spending</option>
                        <option value="loan">Loan</option>

                      </select>
                    </div>
                  </p>{" "}
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      <input
                        type="text"
                        id="recordid"
                        name="recordid"
                        className="loginsearchfayui"
                        placeholder=" Input Record ID"
                        onChange={(e) => this.handleInputChange(e)}

                      />
                    </div>
                  </p>{" "}


                  <p className="">
                    <div className=" conteinpu ">
                      <span
                        className="Editbuttonstuword  mr-3 "
                        onClick={(e) =>
                          this.requestCancel()
                        }
                      >
                        {" "}
                        Submit
                      </span>
                    </div>
                  </p>{" "}
                </div>

              )}

            </div>
            <br />
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default AdminSettings;
