import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Siderbar from "./Siderbar";
import Overall from "./Overall";
import RoundInfo from "./Round/roundInfo/roundInfom";
import Members from "./members/members";
import EditMember from "./members/editmember";
import AddMember from "./members/addmember";
import EditRound from "./Round/roundInfo/editround";
import CreateRound from "./Round/roundInfo/createround";
import AddMemberRound from "./Round/roundInfo/addMember";
import CreateMemberRound from "./Round/roundInfo/creatememberround";
import Contribution from "./Round/contribution/contribution";
import Capital from "./Round/contribution/capital";
import Distribution from "./Round/distribution/distributionInfo";
import CreatePayment from "./payments/createpayments";
import RecordPayment from "./payments/recordpayments";
import RecordExpenses from "./payments/recordexpenses";
import PaymentApproval from "./payments/paymentapproval";
import Spendings from "./payments/spending";
import Convert from "./payments/convert";
import Allloans from "./loan/allloans";
import LoanRequest from "./loan/requestloan";
import LoanAproval from "./loan/LoanApprove";
import LoanEdit from "./loan/editloan";
import LoanCheck from "./loan/checkApproval";
import IncomeReport from "./reports/Income";
import ExpenseReport from "./reports/expenses";
import Cashbook from "./reports/cashbook";
import Financialstatements from "./reports/financialstatements";
import OtherReport from "./reports/otherreports";
import Commissions from "./Round/contribution/commission";
import Penalties from "./Round/contribution/penalities";
import Usergroups from "./SystemSettings/Usergroups";
import Myapproval from "./Requests/Myapproval";
import RoundSchedules from "./Round/distribution/roundschedules";
import Memberdetails from "./members/memberdetails";
import LoanDetails from "./loan/laondetails";
import AdminSettings from "./SystemSettings/adminsettings";
import Filem from "./file/filemanager";
import Auth from "../Auth";
import Assets from "./assets/assets";
import AddAsset from "./assets/addAsset";
import EditAsset from "./assets/editAsset";
import Profile from "./SystemSettings/profile";

// import packageJson from "../../package.json";
// import Reset from "./Reset";

export class Main extends Component {
  render() {
    //alert("Calling main");
    Auth.caching();
    Auth.timecheck(false);
    return (
      <div className="page-content">
        <Siderbar />

        <Switch>
          <Route path="/Main/Overall" component={Overall} />
          <Route path="/Main/RoundInfo" component={RoundInfo} />
          <Route path="/Main/Profile" component={Profile} />
          <Route path="/Main/Members" component={Members} />
          <Route path="/Main/AddMember" component={AddMember} />
          <Route path="/Main/EditMember" component={EditMember} />
          <Route path="/Main/EditRound" component={EditRound} />
          <Route path="/Main/CreateRound" component={CreateRound} />
          <Route path="/Main/AddMemberRound" component={AddMemberRound} />
          <Route path="/Main/CreateMemberRound" component={CreateMemberRound} />
          <Route path="/Main/Contribution" component={Contribution} />
          <Route path="/Main/Capital" component={Capital} />
          <Route path="/Main/Distribution" component={Distribution} />
          <Route path="/Main/RecordPayment" component={RecordPayment} />
          <Route path="/Main/RecordExpenses" component={RecordExpenses} />
          <Route path="/Main/PaymentApproval" component={PaymentApproval} />
          <Route path="/Main/Spendings" component={Spendings} />
          <Route path="/Main/CreatePayment" component={CreatePayment} />
          <Route path="/Main/Convert" component={Convert} />
          <Route path="/Main/Allloans" component={Allloans} />
          <Route path="/Main/LoanRequest" component={LoanRequest} />
          <Route path="/Main/LoanAproval" component={LoanAproval} />
          <Route path="/Main/LoanCheck" component={LoanCheck} />
          <Route path="/Main/LoanEdit" component={LoanEdit} />
          <Route path="/Main/IncomeReport" component={IncomeReport} />
          <Route path="/Main/ExpenseReport" component={ExpenseReport} />
          <Route path="/Main/Cashbook" component={Cashbook} />
          <Route path="/Main/otherreports" component={OtherReport} />
          <Route path="/Main/file" component={Filem} />
          <Route
            path="/Main/Financialstatements"
            component={Financialstatements}
          />
          <Route path="/Main/Commissions" component={Commissions} />
          <Route path="/Main/Penalties" component={Penalties} />
          <Route path="/Main/Usergroups" component={Usergroups} />
          <Route path="/Main/Adminsettings" component={AdminSettings} />
          <Route path="/Main/Myapproval" component={Myapproval} />
          <Route path="/Main/Memberdetails" component={Memberdetails} />
          <Route path="/Main/LoanDetails" component={LoanDetails} />
          <Route path="/Main/Roundschedules" component={RoundSchedules} />
          <Route path="/Main/Assets" component={Assets} />
          <Route path="/Main/AddAsset" component={AddAsset} />
          <Route path="/Main/EditAsset" component={EditAsset} />
          {/* <Route path="/Main/Reset" component={Reset} /> */}
        </Switch>
      </div>
    );
  }
}

export default Main;
