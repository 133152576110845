import React, { Component } from "react";
// import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  width: "97%",
  height: "350px",
  position: "relative",
  borderRadius: "10px",
  marginRight: "30px",
};

class MapContainer extends Component {
  render() {
    return (
      // <Map
      //   google={this.props.google}
      //   zoom={14}
      //   style={mapStyles}
      //   initialCenter={{
      //     lat: -1.2884,
      //     lng: 36.8233,
      //   }}
      // />
      <div></div>
    );
  }
}

// export default GoogleApiWrapper({
//   apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
// })(MapContainer);
export default MapContainer;
