import React, { Component } from "react";
import { Container, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../Auth";
import { linkbasic, goto } from "./link.js";
import axios from "axios";
class Reset extends Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      blocker: [],
      isitLoading: false,
      success: false,
      successmessage: "",
    };
  }

  handleChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    var errors = "";
    if (e.target.name === "password") {
      this.setState({
        errors: "",
      });
    } else {
      this.validate();
    }

    this.setState({
      input,
    });
    //console.log(this.state.input);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let input = {};
    input["password2"] = "";
    input["password"] = "";
    this.setState({ input: input });

    let validLogin = this.validate();

    //console.log("Validation is=" + validLogin);
    if (validLogin) {
      this.login();
    }
  };

  login() {
    Auth.authenticate();
    let path = window.location.hash;
    let sstring = path.split("/")[2] || "";
    //console.log("sstring is=" + sstring);
    if (sstring === "") return;

    var axios = require("axios");
    // var data = JSON.stringify({});

    var config = {
      method: "post",
      url:
        linkbasic() +
        `view=login&action=reset&s=${sstring}&jmb=` +
        this.state.input["password"],
      headers: {},
      data: {
        //   email: this.state.input["email"],
        //jmb: this.state.input["password"],
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        if ((response.data = "Success")) {
          this.setState({
            success: true,
            successmessage: "Password reset successfully.",
          });
        }
        {
          let errors = {};
          errors["password2"] =
            "Password reset failed. Please try again later.";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        let errors = {};
        errors["password2"] = "Password reset failed. Please try again later.";
        this.setState({
          errors: errors,
        });
      });
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = false;

    if (typeof input["email"] !== undefined) {
      // var pattern = new RegExp(/^\S+@\S+\.\S+$/);
      // if (!pattern.test(input["email"])) {
      //   isValid = false;
      //   errors["email"] = "The User name field is required.";
      // }
      isValid = true;
    }
    if (input["password"] === input["password2"]) {
      // var patternPass = new RegExp("^[a-zA-Z0-9_]*$");
      // if (!patternPass.test(input["password"])) {
      isValid = true;
      //
      // }
    } else {
      errors["password2"] = "Both passwords must be the same";
      isValid = false;
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    //console.log("Reset page " + window.location.hash);
    return (
      <div
        className="login"
        style={{
          backgroundImage: "url('/images/logina.png') ",
          height: "100vh",
          width: "100%",
          position: "relative",
          zIndex: "0",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className=" overlay1">
          <div className="login">
            <Container>
              <Card className="caerdlog">
                <Card.Subtitle className="logintitle">
                  Password Reset
                </Card.Subtitle>
                <Card.Body>
                  {!this.state.success ? (
                    <form
                      onSubmit={this.handleSubmit}
                      className="formdata"
                      method="POST"
                    >
                      <div className="form-group forminput">
                        <label className="lebelenter">
                          {" "}
                          Enter new password{" "}
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={this.state.input.password}
                          onChange={this.handleChange}
                          className="formModal"
                          placeholder="Enter password"
                        />
                      </div>

                      <div className="form-group forminput">
                        <label className="lebelenter">
                          {" "}
                          Re-enter your password{" "}
                        </label>
                        <input
                          type="password"
                          name="password2"
                          value={this.state.input.password2}
                          onChange={this.handleChange}
                          className="formModal"
                          placeholder="Re-enter password"
                        />
                      </div>
                      <div className="dangerdemor">
                        {this.state.errors.password2}
                      </div>

                      <input
                        type="submit"
                        value="Submit"
                        className="btnapplsign mt-5"
                      />
                      <br />
                    </form>
                  ) : (
                    <div>{this.state.successmessage}</div>
                  )}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Reset;
