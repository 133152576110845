import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";
import axios from "axios";
import Footer from "../footer";
export class EditMember extends Component {
  constructor() {
    super();
    this.state = {
      users: {},
      member: [],
      loading: false,
    };
  }
  componentDidMount = () => {
    setTimeout(this.getMember(), 3000);
  };

  getMember() {
    let path = window.location.hash;
    let memberId = path.split("/")[3];
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: link() + `&do=get&ra=user&ft=reclist&elmt=userID&id=${memberId}`,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          //member: response.data,
          users: response.data[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuccess = () => {
    //window.location.href = window.location + "Main/Members";
    goto("Main/Members");
  };

  handleChange = (e) => {
    let users = this.state.users;
    users[e.target.name] = e.target.value;
    this.setState({ users });
  //  .console.log("user= " + JSON.stringify(this.state.users));
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    // var newUser = JSON.stringify({
    //   firstName: this.state.users.firstname,
    //   lastName: this.state.users.lastname,
    //   middleName: this.state.users.middlename,
    //   jmb: this.state.users.password,
    //   email: this.state.users.email,
    //   mobile: this.state.users.mobile,
    // });
    var newUser = this.state.users;

    // .console.log(
    //   this.state.users.firstname +
    //     "/" +
    //     this.state.users.lastname +
    //     "/" +
    //     this.state.users.username,
    //   "/",
    //   newUser
    // );
    var token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: link() + `&do=update&ra=user`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newUser,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { member, users, loading } = this.state;
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">User</h6>
        </div>

        <h6 className="activeuser"> Edit user informations</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div class="form-group">
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  id="validationDefault01"
                  onChange={(e) => this.handleChange(e)}
                  required
                  value={users.firstName}
                  className="logininputs mb-3"
                  placeholder="Enter First Name"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="middleName"
                  name="middleName"
                  id="validationDefault01"
                  onChange={(e) => this.handleChange(e)}
                  // required
                  className="logininputs  mb-3"
                  value={users.middleName}
                  placeholder="Enter Middle Name if exists"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  id="validationDefault01"
                  onChange={(e) => this.handleChange(e)}
                  //required
                  className="logininputs  mb-3"
                  value={users.lastName}
                  placeholder="Enter Family Name"
                />
              </div>
            </div>
            <div className="rowuno mt-2">
              {/* <div class="form-group">
                <input
                  type="email"
                  id="param2"
                  name="email"
                  id="validationDefault01"
                  required
                  className="logininputs"
                  value={member[0] && member[0].email}
                />
              </div> */}
              <div class="form-group">
                <input
                  type="number"
                  id="mobile"
                  id="validationDefault01"
                  //required
                  onChange={(e) => this.handleChange(e)}
                  name="mobile"
                  className="logininputs  mb-3"
                  value={users.mobile}
                  placeholder="Enter Mobile Phone"
                />
              </div>
              <div>
                <select
                  id="userStatus"
                  type="text"
                  name="userStatus"
                  required
                  id="validationDefault01"
                  onChange={(e) => this.handleChange(e)}
                  selected={this.state.users.userStatus}
                  className="logininputsselect "
                  placeholder="Select Category"
                >
                  <option value="Active">Active</option>
                  <option value="Cancelled">Disabled</option>
                </select>
              </div>
            </div>
            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>

            <br />
          </form>
        </div>
        <div className="schoolModal">
          <Modal show={this.state.openSucess} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="btnaddblog" onClick={this.closeSuccess}>
                  <i className="fas fa-times"></i>
                </p>
              </div>
              <div>
                <p className="sent">Successfully update information!</p>
                <p className="succpar">
                  You have successfully updated user information.
                  Congratulations!
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default EditMember;
