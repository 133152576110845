import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";
import Success from "../success";
import Footer from "../footer";
export class AddMember extends Component {
  constructor() {
    super();
    this.state = {
      users: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        password: "",
        middlename: "",
      },
      openSucess: false,
      openError: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuucess = () => {
  //  .console.log("Modal close test");

    goto("Main/AddMember");
  };

  closeError = () => {
    //window.location.href = window.location + "Main/Signin";
    goto("Main/Signin");
  };
  handleChange = (e) => {
    let users = this.state.users;
    users[e.target.name] = e.target.value;
    this.setState({ users });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var newUser = JSON.stringify({
      firstName: this.state.users.firstname,
      lastName: this.state.users.lastname,
      middleName: this.state.users.middlename,
      jmb: this.state.users.password,
      email: this.state.users.email,
      mobile: this.state.users.mobile,
    });
    // .console.log(
    //   this.state.users.firstname +
    //     "/" +
    //     this.state.users.lastname +
    //     "/" +
    //     this.state.users.username,
    //   "/",
    //   newUser
    // );
    var token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: link() + `&do=create&ra=user`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newUser,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { loading } = this.state;
  //  .console.log(this.state.users);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">User</h6>
        </div>

        <h6 className="activeuser"> Add new user</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div clasName="d-block">
                <label className="lebelenter">First Name</label>
                <div class="form-group">
                  <input
                    id="fname"
                    type="text"
                    name="firstname"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.users.firstname}
                    className="logininputs"
                    placeholder="Enter First Name"
                  />
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Middle Name</label>
                <div class="form-group">
                  <input
                    type="text"
                    id="mname"
                    name="middlename"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.users.middlename}
                    className="logininputs"
                    placeholder="Enter Middle Name if exists"
                    //required
                  />
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Last Name</label>
                <div class="form-group">
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    id="validationDefault01"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.users.lastname}
                    className="logininputs"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
            </div>
            <div className="rowuno">
              <div clasName="d-block">
                <label className="lebelenter">Email</label>
                <div class="form-group">
                  <input
                    type="email"
                    id="param2"
                    name="email"
                    //aria-describedby="inputGroupPrepend2"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.users.email}
                    className="logininputs"
                  />
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Password</label>
                <div class="form-group">
                  <input
                    type="password"
                    id="param2"
                    name="password"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.users.password}
                    className="logininputs"
                  />
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Phone Number</label>
                <div class="form-group">
                  <input
                    type="number"
                    id="uname"
                    name="mobile"
                    //required
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.users.mobile}
                    className="logininputs"
                  />
                </div>
              </div>
            </div>
            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>

            <br />
          </form>
        </div>
        <div className="schoolModal">
          <Modal show={this.state.openSucess} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="btnaddblog" onClick={() => this.closeSuucess()}>
                  <i className="fas fa-times"></i>
                </p>
              </div>
              <div>
                <p className="sent">Successfully added!</p>
                <p className="succpar">
                  You have successfully added a user. Congrats!
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default AddMember;
