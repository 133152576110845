import React, { Component } from "react";
import packageJson from "../package.json";

const Auth = {
  isAuthenticated: false,

  authenticate() {
    this.isAuthenticated = true;
    let token = localStorage.getItem("token");
  },
  SignOut() {
    this.isAuthenticated = false;
    localStorage.removeItem("token");
    localStorage.clear();
  },
  getAuth() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  },

  caching() {
    //console.log("Entering cache validation. Auth");
    let version = localStorage.getItem("version");
    //alert("Package version = " + packageJson.version);

    if (version != packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        //alert("Deleted cache");
        //console.log("Deleted cache. Auth writing new date.");
        localStorage.setItem("rcd", new Date());
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      } else {
        //alert("No cache delete");
        //console.log("No cache delete. Auth");
      }

      //localStorage.clear();
      localStorage.setItem("version", packageJson.version);
      //localStorage.setItem("rcd", new Date());
    }
  },

  timecheck(canalert) {
    //console.log("Entering cache validation. Auth");
    //let version = localStorage.getItem("version");
    //alert("Package version = " + packageJson.version);
    //var date1 = new Date();
    var date2 = new Date();
    if (localStorage.getItem("rcd")) {
      var date1 = new Date(localStorage.getItem("rcd"));

      var Difference_In_Min = diff_minutes(date2, date1);

      //alert("Diff in minutes=" + Difference_In_Min);

      if (Difference_In_Min >= 180 || !localStorage.getItem("rcd")) {
        if (canalert) alert("Please Login again");
        window.location.href = window.location.pathname + "#/" + "Login";
        this.SignOut();
      }
    } else {
      window.location.href = window.location.pathname + "#/" + "Login";
      this.SignOut();
    }
  },
  // diff_minutes(dt2, dt1) {
  //   var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  //   diff /= 60;
  //   return Math.abs(Math.round(diff));
  // },
};
function diff_minutes(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}
export default Auth;
