import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, downloadFile, numb2, wordContains } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
import { format } from "date-fns";
import Loading from "../loading";
export class Cashbook extends Component {
  constructor() {
    super();
    this.state = {
      resultDate: [],
      moneyin: 0,
      moneyout: 0,
      transactionFee: 0,
      balance: 0,
      closingAmount: 0,
      startAmount: 0,
      filters: {
        datefrom: "",
        dateTo: "",
        userid: "",
        memberid: "",
        roundid: "",
        roundname: "",
        name: "",
        bank: "BPR",
        termstartdate: "",
        termduedate: "",
        status: "",
      },
      dataOut: 15,

      startDate: new Date(),
      endDate: new Date(),
      rounds: [],
      reportType: "cb",
      reportResults: [],
      loading: false,
    };
    //this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  handleChangeDate(date) {
    let filters = this.state.filters;
    filters.datefrom = format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
    this.setState({
      filters: filters,
      startDate: date,
    });

  //  .console.log(this.state.filters);

    this.searchAll();
  }
  handleChangeEndDate(date) {
    let filters = this.state.filters;
    filters.dateTo = format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
    this.setState({
      filters: filters,
      endDate: date,
    });

    this.searchAll();
  }
  getReport = (reptype) => {
    // if (reptype === "") return;
    var token = localStorage.getItem("token");
    var postData = JSON.stringify({
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      bank: this.state.filters.bank,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    });
    //var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=report&rec=cb`, postData, { headers: headers })
      .then((res) => {
        // console.log("RESPONSE RECEIVED: ", res);
        //resultDate: res.data.attachment,
        this.setState({
          reportResults: res.data,

          dataOut: 15,
          loading: false,
        });

        this.searchAll();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  };
  getRounds = () => {
    var token = localStorage.getItem("token");
    //var rounds = [];
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    fetch(
      link() + `&do=getshort&ra=round&ft=areclist&elmt=roundStatus&id=Active`,
      {
        method: "post",
        headers: headers,
        //body: JSON.stringify(data),
        //responseType: "blob",
      }
    ) // FETCH BLOB FROM IT
      .then((response) => response.json())
      .then((response) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        // console.log("Round list received=");
        // console.log(response);
        //rounds = response;
        this.setState({ rounds: response });

        //return response;
        //this.getReport("");
      })
      .catch((err) => {
        console.log(err);
      });

    //return rounds;
  };

  componentDidMount = () => {
    this.setState({
      startDate: null,
      endDate: null,
    });
    this.getRounds();
  };
  handleChangeSelect = (e) => {
    this.setState({
      loading: true,
    });
    let filters = this.state.filters;
    filters.bank = e.target.value;
    this.setState({
      reportType: e.target.value,
      filters: filters,
    });

    // console.log(
    //   "Target= " + e.target.name + ", value= " + e.target.value

    // );

    this.getReport(e.target.value);
    //console.log(this.state.payment);
  };
  handleRoundSelect = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let filters = this.state.filters;
    //let fitlers = this.state.payment;
    filters.roundid = e.target.value;
    // payment.userID = e.target.value;
    this.setState({
      filters: filters,
    });

    this.getReport(this.state.filters.bank);
    //console.log(this.state.payment);
  };

  download = () => {
    var token = localStorage.getItem("token");

    let reptypedefault = this.state.reportType;

    if (reptypedefault === "") {
      return;
    }

    var postData = {
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      bank: this.state.filters.bank,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    };
    var headers = {
      "Content-Type":
        "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `${token}`,
    };

    downloadFile(
      "POST",
      link() + `&do=get&ra=report&rec=cb` + `&ufile=getfile`,

      postData,
      headers
    );
  };

  isBetween = (transdate, dateFrom, dateTo) => {
    // var res =
    //   ((transdate.substring(0, 10) >= dateFrom && dateFrom != "") ||
    //     dateFrom === "") &&
    //   ((transdate.substring(0, 10) <= dateTo && dateTo != "") || dateTo === "");
    // console.log(
    //   "(" +
    //     transdate.substring(0, 10) +
    //     ")(" +
    //     dateFrom +
    //     ")(" +
    //     dateTo +
    //     ") =",
    //   res
    // );

    return (
      ((transdate.substring(0, 10) >= dateFrom && dateFrom != "") ||
        dateFrom === "") &&
      ((transdate.substring(0, 10) <= dateTo && dateTo != "") || dateTo === "")
    );
  };
  handleFilterChange = (e) => {
    e.preventDefault();
    let filters = this.state.filters;
    filters[e.target.name] = e.target.value;
    this.setState({ filters });
    this.searchAll();
  };

  searchAll = () => {
    // console.log("StartDate=", this.state.startDate);
    // console.log("EndDate=", this.state.endDate);
    let moneyin = 0;
    let moneyout = 0;
    let transactionFee = 0;
    let balance = 0;
    let closingAmount = 0;
    let startAmount = 0;
    let resultDate = [];
    let filterresult =
      this.state.reportResults.attachment &&
      this.state.reportResults.attachment

        .filter(
          (ite) =>
            //ite.names?.toLowerCase().includes(this.state.filters.name)

            wordContains(
              ite.names?.toLowerCase(),
              this.state.filters.name.toLowerCase()
            ) &&
            this.isBetween(
              ite.transDate,
              this.state.filters.datefrom.substring(0, 10),
              this.state.filters.dateTo.substring(0, 10)
            )
        )
        .slice(0, this.state.dataOut)
        .forEach((item, indx) => {
          moneyin = moneyin + parseFloat(item.moneyIn);
          moneyout = moneyout + parseFloat(item.moneyOut);
          item.transactionFeetransactionFee =
            transactionFee + parseFloat(item.transactionFee);

          balance = moneyin - moneyin;
          startAmount = parseFloat(this.state.reportResults.startAmount);
          closingAmount = balance + startAmount;

          resultDate.push(item);
          // paid = paid + parseFloat(item.paid);
          // balance = balance + parseFloat(item.balance);
        });
    this.setState({
      resultDate: resultDate,
      moneyin,
      moneyout,
      transactionFee,
      balance,
      closingAmount,
      startAmount,
    });
    // console.log(this.state.resultDate);
    // console.log(this.state.reportResults);
  };

  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 20,
      };
    });
  };

  render() {
    const {
      reportResults,
      resultDate,
      rounds,
      loading,
      moneyin,
      moneyout,
      transactionFee,
      balance,
      closingAmount,
      startAmount,
    } = this.state;
    var count = 1;
    var length = reportResults.attachment && reportResults.attachment.length;
    // const dateFilter =
    //   this.state.startDate != undefined
    //     ? format(Date.parse(this.state.startDate), "yyyy-MM-dd")
    //     : "";
    // const dateFilterEnd =
    //   this.state.endDate != undefined
    //     ? format(Date.parse(this.state.endDate), "yyyy-MM-dd")
    //     : "";

    // const resultDate =
    //   reportResults.attachment &&
    //   reportResults.attachment.filter((ite) => {
    //     return (
    //       ((ite.transDate.substring(0, 10) >= dateFilter && dateFilter != "") ||
    //         dateFilter === "") &&
    //       ((ite.transDate.substring(0, 10) <= dateFilterEnd &&
    //         dateFilterEnd != "") ||
    //         dateFilterEnd === "")
    //     );
    //   });
    // console.log("filters", this.state.filters);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Reports</h6>
            <div className="report">
              <Link to="/Main/IncomeReport" className="contrilink">
                Income
              </Link>
              <Link to="/Main/ExpenseReport" className="contrilink">
                Expense
              </Link>
              <Link to="/Main/Cashbook" className="contrilink">
                Cash Book
              </Link>
              <Link to="/Main/financialstatements" className="contrilink">
                Financial Statements
              </Link>
              {/* <Link to="/Main/otherreports" className="contrilink">
                Other Reports
              </Link> */}
            </div>
            <h6 className="activeuser mt-3 d-flex">
              <div className="col conteinpu">
                Cashbook Report:
                <span className="unity"> {reportResults.roundName}</span>
              </div>
              {length > 0 ? (
                <div className="print" onClick={() => this.download()}>
                  <i class="fas fa-download"></i>
                </div>
              ) : (
                ""
              )}
            </h6>
            <div className="cardtable ">
              <div className="containeruserscroll">
                <div className="membaddd">
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      Select Round:
                      <select
                        id="reporttype"
                        type="text"
                        name="reporttype"
                        onChange={(e) => this.handleRoundSelect(e)}
                        selected={this.state.filters.roundid}
                        className="logininputsselectyu"
                        placeholder="Select Bank type"
                      >
                        <option value="">Select Round</option>{" "}
                        {rounds &&
                          rounds.map((round) => {
                            return (
                              <option value={round.roundID}>
                                {round.roundName}
                              </option>
                            );
                          })}
                        {/* <option value="incomecontr">Round 9</option> */}
                      </select>
                    </div>
                  </p>
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      Select Account
                      <select
                        id="bank"
                        type="text"
                        name="reporttype"
                        onChange={(e) => this.handleChangeSelect(e)}
                        selected={this.state.reportType}
                        className="logininputsselectyu"
                        placeholder="Select Bank type"
                      >
                        <option value="">Select Account</option>
                        <option>BPR</option>
                        <option>COPEDU</option>
                        <option>Momo</option>
                      </select>
                    </div>
                  </p>
                  <p className="d-flex ">
                    <div className="conteinpu ml-2 mt-2">Date From</div>
                    <div className=" parainputsapphf ">
                      <DatePicker
                        selected={this.state.startDate}
                        //onChange={(e) => this.handleChangeDate(e)}
                        onChange={this.handleChangeDate}
                        name="transactionDate"
                        className="logininputsselectyu"
                        dateFormat="yyyy-MM-dd"
                        placeholder="Pick a date"
                        id="validationDefault01"
                        required
                      />
                    </div>
                  </p>
                  <p className="d-flex ">
                    <div className="conteinpu ml-2 mt-2">Date To</div>
                    <div className=" parainputsapphf ">
                      <DatePicker
                        selected={this.state.endDate}
                        //onChange={(e) => this.handleChangeDate(e)}
                        onChange={this.handleChangeEndDate}
                        name="transactionDate"
                        className="logininputsselectyu"
                        dateFormat="yyyy-MM-dd"
                        placeholder="Pick a date"
                        id="validationDefault01"
                        required
                      />
                    </div>
                  </p>
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="loginsearchfayui"
                        placeholder=" Search payer"
                        onChange={(e) => this.handleFilterChange(e)}
                        // value={this.state.filters.name}
                      />
                    </div>
                  </p>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="activeuserr ">
                      <div className=" conteinpu">
                        Bank Name:
                        <span className="unity">
                          {" "}
                          {reportResults.searchbank}
                        </span>
                      </div>
                      <div className=" conteinpu">
                        Cash at the biginning:
                        <span className="unity"> {numb2(startAmount)}</span>
                      </div>
                      <div className=" conteinpu">
                        Money In:
                        <span className="unity">
                          {reportResults.currency} {numb2(moneyin)}
                        </span>
                      </div>
                      <div className=" conteinpu">
                        Money Out:
                        <span className="unity">
                          {reportResults.currency} {numb2(moneyout)}
                        </span>
                      </div>
                      <div className=" conteinpu">
                        Transaction Fees:
                        <span className="unity">
                          {reportResults.currency} {numb2(transactionFee)}
                        </span>
                      </div>
                      <div className=" conteinpu">
                        In-Out:
                        <span className="unity">
                          {" "}
                          {reportResults.currency} {numb2(balance)}
                        </span>
                      </div>

                      <div className=" conteinpu">
                        Balance:
                        <span className="unity">
                          {" "}
                          {reportResults.currency} {numb2(closingAmount)}
                        </span>
                      </div>
                    </div>

                    <table className="table table-hover  table-responsive  tablealot  ">
                      <thead>
                        <tr>
                          <th className="userpronyblue "> </th>
                          <th className="userpronyblue ">ID </th>
                          <th className="userpronyblue "> Transaction Date</th>
                          <th className="userpronyblue ">Payer/Payee</th>
                          <th className="userpronyblue ">Category</th>
                          <th className="userpronyblue ">Transaction Code</th>
                          <th className="userpronyblue ">Description</th>
                          <th className="userpronyblue ">Bank Type</th>
                          <th className="userpronyblue ">Bank Name</th>
                          {/* <th className="userpronyblue ">Paid Date</th> */}
                          <th className="userpronyblue ">Money In</th>
                          <th className="userpronyblue ">Money Out</th>
                          <th className="userpronyblue ">Bank Fees</th>
                          {/* <th className="userpronyblue ">Balance</th>
                    <th className="userpronyblue ">-</th> */}
                        </tr>
                      </thead>
                      {/* {dateFilter != undefined && dateFilterEnd != undefined ? ( */}
                      <tbody>
                        {resultDate &&
                          resultDate

                            .slice(0, this.state.dataOut)
                            .map((item) => {
                              return (
                                <tr>
                                  <td className="userinfo ">{count++}</td>
                                  <td className="userinfo ">{item.recordID}</td>
                                  <td className="userinfo ">
                                    {item.transDate.toString().substring(0, 10)}
                                  </td>
                                  <td className="userinfo ">{item.names}</td>
                                  <td className="userinfo ">{item.category}</td>
                                  <td className="userinfo ">
                                    {item.transactionReference}
                                  </td>
                                  <td className="userinfo ">{item.remark}</td>
                                  <td className="userinfo">{item.bankType}</td>
                                  <td className="userinfo">{item.bankName}</td>

                                  <td className="userinfo">
                                    {numb2(item.moneyIn)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(item.moneyOut)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(item.transactionFee)}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                      {/* ) : ( */}
                      ......
                      {/* <tbody>
                        {reportResults.attachment &&
                          reportResults.attachment
                            .slice(0, this.state.dataOut)
                            .map((item) => {
                              return (
                                <tr>
                                  <td className="userinfo ">{count++}</td>
                                  <td className="userinfo ">
                                    {item.transDate.toString().substring(0, 10)}
                                  </td>
                                  <td className="userinfo ">{item.names}</td>
                                  <td className="userinfo ">{item.category}</td>
                                  <td className="userinfo ">
                                    {item.transactionReference}
                                  </td>
                                  <td className="userinfo ">{item.remark}</td>
                                  <td className="userinfo">{item.bankType}</td>
                                  <td className="userinfo">{item.bankName}</td>
                                
                                  <td className="userinfo">
                                    {numb2(item.moneyIn)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(item.moneyOut)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(item.transactionFee)}
                                  </td>
       
                                </tr>
                              );
                            })}
                      </tbody> */}
                      {/* )} */}
                      {reportResults.attachment &&
                      reportResults.attachment.length <= this.state.dataOut ? (
                        ""
                      ) : (
                        <div className="buttoncenter">
                          {reportResults.attachment &&
                          reportResults.attachment.length >=
                            this.state.dataOut ? (
                            <button
                              type="button"
                              className="btnbookrermore  "
                              onClick={() => this.loadmore()}
                            >
                              <span className="mr-2 mt-1">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                              <span>Load More</span>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </table>
                  </>
                )}
              </div>
            </div>
            <br />
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Cashbook;
