import React, { Component } from "react";
import MapContainer from "./Map";
import Auth from "../Auth";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Modal,
  Button,
  Col,
  Row,
  Carousel,
} from "react-bootstrap";
import Slider from "react-slick";
// import emailjs from "emailjs-com";
import Footer from "./footer";
import logo from "../components/image/lap.png";
export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      madalOpen: false,
      madleOpenRequest: false,
      nav: false,
      name: "",
      email: "",
      phone: "",
      Time: "",
      Message: "",
    };
  }
  loginPage = () => {
    window.location.href = window.location + "Login";
  };

  success = (e) => {
    if (this.sendEmail) {
      alert("Message well sent");
    } else {
      alert("OPPS SOMETHING WENT WRONG");
    }
  };

  OpenMadle = () => {
    this.setState({
      madleOpen: true,
    });
  };

  CloseMadle = () => {
    this.setState({
      madleOpen: false,
    });
  };
  OpenMadleRequest = () => {
    this.setState({
      madleOpenRequest: true,
    });
  };

  CloseMadleRequest = () => {
    this.setState({
      madleOpenRequest: false,
    });
  };

  render() {
    //alert("Calling home");
    Auth.caching();
    return (
      <div>
        {/* <div className="jumbotron ">
          <div className="banner"> */}
        <header
          id="header"
          className="fixed-top d-flex align-items-center  header-transparent "
        >
          <Navbar
            expand="lg"
            // className={`w-100 Nav ${this.state.nav && "Nav__black"}`}
            className="Nav__black"
          >
            <Navbar.Brand>
              <Link className="logo" to="/">
                {/* <img
                  src="./images/thetracke.png"
                  alt=""
                  className="trackLogo"
                  id="trakshow"
                /> */}
                <p className="logoparag">IKIMINA TOWARDS THE SUCCESS</p>
                {/* <img
                  src="./images/logofinal.jpeg"
                  alt=""
                  className="trakshow"
                  id="trakshow"
                /> */}
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav " />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="">
                {/* <li>
                  <a className="nav-link scrollto  " href="#about">
                    About us
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="#features">
                    Features
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="#services">
                    Services
                  </a>
                </li>

                <li>
                  <a className="nav-link scrollto " href="#pricing">
                    Pricing model
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="#contactus">
                    Contact us
                  </a>
                </li> */}

                <li>
                  <button className="btnbookr" onClick={() => this.loginPage()}>
                    Login
                  </button>
                </li>
                {/* <li>
                  <a
                    className=" loginin  text-white  ml-5 scrollto  "
                    href="http://app.thetracker.africa"
                    target="blanc"
                  >
                    Login
                  </a>
                </li> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <section className="aboutsection">
          <div className=" container ">
            <div className="row align-items-center rowsed ">
              <div className="col-md-6 ">
                <div className=" aboutus d-flex">
                  <h3 className="titleabout">
                    Ikimina Information Management System
                  </h3>
                </div>
                {/* <p className="content">
                  Ikimina towards the success system automates your fininace get
                  and comprehensive analytics and motivate your team via free
                  collaboration suite inside.
                </p> */}
                <div classname="d-flex">
                  <button
                    className=" loginin "
                    onClick={() => this.loginPage()}
                  >
                    Get Started
                  </button>
                  {/* <button className=" more ">Try for free</button> */}
                </div>
              </div>
              <div className="col-md-6 homeimg">
                <img src={logo} className="img-fluid" alt="image" />
              </div>
            </div>

            <br />
          </div>
        </section>

        {/* <section id="features" className="features">
          <div className="containerabout">
            <div className=" Core d-flex ">
              <p className="Ligncore"></p>
              <h3 className="headercta">Core App Features</h3>
            </div>

            <div className="row  corerow ">
              <div className="col-lg-3  text-center mb-3 ">
                <Row>
                  <Col></Col>
                  <Col>
                    <p className="circle">
                      <i className="fas fa-map-marker-alt"></i>
                    </p>
                  </Col>
                  <Col></Col>
                </Row>

                <h3 className="titlecta">Management Information System </h3>
                <p className="parafeature">
                  Our expert monitoring services are designed to help you lower
                  operational costs and improve profit margins, while offering
                  regulatory and environmental protection to keep you legally
                  compliant and give you peace of mind.
                </p>
              </div>
              <div className="col-lg-3 text-center " md="3">
                <Row>
                  <Col></Col>
                  <Col>
                    {" "}
                    <p className="circle">
                      <i class="fas fa-expand"></i>
                    </p>
                  </Col>
                  <Col></Col>
                </Row>

                <h3 className="titlecta">Financial statements</h3>
                <p className="parafeature">
                  Our expert monitoring services are designed to help you lower
                  operational costs and improve profit margins, while offering
                  regulatory and environmental protection to keep you legally
                  compliant and give you peace of mind.
                </p>
              </div>
              <div className="col-lg-3 text-center mb-3">
                <Row>
                  <Col></Col>
                  <Col>
                    <p className="circle">
                      <i className="fas fa-tablet-alt"></i>
                    </p>
                  </Col>
                  <Col></Col>
                </Row>

                <h3 className="titlecta">Progress report</h3>
                <p className="parafeature">
                  Our expert monitoring services are designed to help you lower
                  operational costs and improve profit margins, while offering
                  regulatory and environmental protection to keep you legally
                  compliant and give you peace of mind.
                </p>
              </div>
              <div className="col-lg-3 text-center  mb-3">
                <Row>
                  <Col></Col>
                  <Col>
                    <p className="circle">
                      <i className="fas fa-hand-paper"></i>
                    </p>
                  </Col>
                  <Col></Col>
                </Row>

                <h3 className="titlecta">social capital</h3>
                <p className="parafeature">
                  Our expert monitoring services are designed to help you lower
                  operational costs and improve profit margins, while offering
                  regulatory and environmental protection to keep you legally
                  compliant and give you peace of mind.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="blacklivesmatters">
          <div className="blacklives">
            <h2 className="matters">
              Every member Matters.
              <span style={{ color: "#16977f" }}>
                {" "}
                every contribution counts.
              </span>{" "}
            </h2>
            <p className="paranoid">
              You need a powerful platform that empowers you to be your best,
              whether you manage 1 or 1000 members .
            </p>
          </div>
          <hr className="linehre" />
        </section>

        <section className="Service">
          <div className="container">
            <div id="services" className="row content aos-init aos-animate  ">
              <div className="col-lg-6 pt-4 pt-lg-0 " id="slider">
                <img
                  src="/images/circlep.png"
                  className=" img-fluid servicedrip2"
                  id=""
                  alt="2"
                />

                <br />
                <div className=" aboutus d-flex">
                  {" "}
                  <p className="Lignser"></p>
                  <h3 className="titleser">OUR SERVICES</h3>
                </div>

                <div className="maincomntent">
                  <div className=" boxser d-flex">
                    <p className="number">01</p>
                    <div className="sercontent">
                      <h6 className="headSubser">
                        Management Information SystemY
                      </h6>
                      <p className="paraSubser">
                        Ikimina towards the success system automates your
                        fininace get and comprehensive analytics
                      </p>
                    </div>
                  </div>
                  <div className=" boxser d-flex ">
                    <p className="number">02</p>
                    <div className="sercontent">
                      <h6 className="headSubser">Financial statements</h6>
                      <p className="paraSubser">
                        Ikimina towards the success system automates your
                        fininace get and comprehensive analytics
                      </p>
                    </div>
                  </div>
                  <div className=" boxser d-flex">
                    <p className="number">03</p>
                    <div className="sercontent">
                      <h6 className="headSubser">Progress report</h6>
                      <p className="paraSubser">
                        Easy to use, user interface providing accurate date and
                        customization, monitoring of speed, direction
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <img
                  src="/images/vect.png"
                  className="img-fluid  diverer"
                  id="rightslider"
                  alt="riding"
                />
              </div>
            </div>

            <br />
          </div>
        </section>

        <section id="pricing" className="cta">
          <div id="pricing" className="containerpri">
            <div className=" Core d-flex ">
              <p className="Lignpri"></p>
              <h3 className="headercta">Pricing Plans</h3>
            </div>
            <div className="cardpricing d-flex row">
              <div className="cardone col-md-3 col-sm-6 col-6">
                <div className="card-title">
                  <h6>Pricing Table</h6>
                </div>
                <div className="card-body">
                  <div className="contentpri mb-3">
                    <p className="veh">Vehicles</p>
                    <hr />
                    <p className="veh">Alert</p>
                    <hr />

                    <p className="veh">Geofences</p>
                    <hr />
                    <p className="veh">POI</p>
                    <hr />
                    <p className="veh">Reports</p>
                  </div>
                </div>
              </div>
              <div className="card col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="card-tit">
                  <h6 className="basic">Basic</h6>
                  <p className="money">10,000Rwf/Vehicle</p>
                </div>
                <div className="card-body">
                  <div className="contentpris mb-3">
                    <p className="devise">1-10</p>
                    <hr />
                    <p>
                      <i className="fas fa-times"></i>
                    </p>
                    <hr />

                    <p>
                      <i className="fas fa-times"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>

                    <button className="buynow" onClick={this.OpenMadleRequest}>
                      Start Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="cardone d-lg-none col-sm-6 col-6">
                <div className="card-title">
                  <h6>Pricing Table</h6>
                </div>
                <div className="card-body">
                  <div className="contentpri mb-3">
                    <p className="veh">Vehicles</p>
                    <hr />
                    <p className="veh">Alert</p>
                    <hr />

                    <p className="veh">Geofences</p>
                    <hr />
                    <p className="veh">POI</p>
                    <hr />
                    <p className="veh">Reports</p>
                    <hr />
                    <p className="veh">Sms gateway</p>
                    <hr />
                    <p className="veh">Send Command</p>
                    <hr />
                    <p className="veh">History</p>
                    <hr />
                    <p className="veh">Maintenance</p>
                    <hr />
                    <p className="veh">Camera/Media</p>
                    <hr />
                    <p className="veh">Device Camera</p>
                    <hr />
                    <p className="veh">Tasks</p>
                    <hr />
                    <p className="veh">Chat</p>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="card col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="card-tit">
                  <h6 className="basic">Pro</h6>
                  <p className="money">8,000Rwf/Vehicle</p>
                </div>
                <div className="card-body">
                  <div className="contentpris mb-3">
                    <p className="devise"> 10-50</p>
                    <hr />

                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>

                    <button className="buynow" onClick={this.OpenMadleRequest}>
                      Start Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="cardone d-lg-none col-sm-6 col-6">
                <div className="card-title">
                  <h6>Pricing Table</h6>
                </div>
                <div className="card-body">
                  <div className="contentpri mb-3">
                    <p className="veh">Vehicles</p>
                    <hr />
                    <p className="veh">Alert</p>
                    <hr />

                    <p className="veh">Geofences</p>
                    <hr />
                    <p className="veh">POI</p>
                    <hr />
                    <p className="veh">Reports</p>
                    <hr />
                    <p className="veh">Sms gateway</p>
                    <hr />
                    <p className="veh">Send Command</p>
                    <hr />
                    <p className="veh">History</p>
                    <hr />
                    <p className="veh">Maintenance</p>
                    <hr />
                    <p className="veh">Camera/Media</p>
                    <hr />
                    <p className="veh">Device Camera</p>
                    <hr />
                    <p className="veh">Tasks</p>
                    <hr />
                    <p className="veh">Chat</p>
                    <hr />
                    <p className="veh">Sharing</p>
                    <hr />
                    <p className="veh">Gps device activation</p>
                    <hr />
                    <p className="veh">Custom Server</p>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="card col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="card-tit">
                  <h6 className="basic">Premium</h6>
                  <p className="money">6,000Rwf/Vehicle</p>
                </div>
                <div className="card-body">
                  <div className="contentpris mb-3">
                    <p className="devise">
                      {" "}
                      <p> 50-150</p>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />

                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>

                    <button className="buynow" onClick={this.OpenMadleRequest}>
                      Start Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="cardone d-lg-none col-sm-6 col-6">
                <div className="card-title">
                  <h6>Pricing Table</h6>
                </div>
                <div className="card-body">
                  <div className="contentpri mb-3">
                    <p className="veh">Vehicles</p>
                    <hr />
                    <p className="veh">Alert</p>
                    <hr />

                    <p className="veh">Geofences</p>
                    <hr />
                    <p className="veh">POI</p>
                    <hr />
                    <p className="veh">Reports</p>
                    <hr />
                    <p className="veh">Sms gateway</p>
                    <hr />
                    <p className="veh">Send Command</p>
                    <hr />
                    <p className="veh">History</p>
                    <hr />
                    <p className="veh">Maintenance</p>
                    <hr />
                    <p className="veh">Camera/Media</p>
                    <hr />
                    <p className="veh">Device Camera</p>
                    <hr />
                    <p className="veh">Tasks</p>
                    <hr />
                    <p className="veh">Chat</p>
                    <hr />
                    <p className="veh">Sharing</p>
                    <hr />
                    <p className="veh">Gps device activation</p>
                    <hr />
                    <p className="veh">Custom Server</p>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="card col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="card-tit">
                  <h6 className="basic">Enterprise</h6>
                  <p className="money">5,000 Rwf/Vehicle</p>
                </div>
                <div className="card-body">
                  <div className="contentpris mb-3">
                    <p className="devise"> 150 and Above</p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>
                    <hr />
                    <p>
                      {" "}
                      <i className="fas fa-check"></i>
                    </p>

                    <button className="buynow" onClick={this.OpenMadleRequest}>
                      Start Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contactus" className="contscontac">
          <div id="contactus" className="container">
            <div className=" Productus d-flex">
              {" "}
              <p className="Lignser"></p>
              <h3 className="titleser">CONTACT US</h3>
            </div>
            <div className="contactus">
              <div className="row">
                <form
                  onSubmit={this.sendEmail}
                  method="post"
                  role="form"
                  className="form php-email-form"
                >
                  <h6 className="getin">Get In Touch With Us</h6>
                  <div className="row">
                    <div className="col form-group ">
                      <input
                        type="text"
                        name="name"
                        className="form-controlin"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col form-group">
                      <input
                        type="email"
                        className="form-controlin"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-controlro"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="error-message"></div>
                  </div>
                  <div className="text-center">
                    <button className="btnsend" type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    );
  }
}

export default Home;
