import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";
import axios from "axios";
import Footer from "../footer";
export class LoanAproval extends Component {
  constructor() {
    super();
    this.state = {
      aprLoan: null,
    };
  }
  handleCreateRound = () => {
    // window.location.href = window.location + "Main/CreateRound";
    goto("Main/CreateRound");
  };
  handleShowMore = () => {
    //window.location.href = window.location + "Main/LoanCheck";
    goto("Main/LoanCheck");
  };
  handleBack = () => {
    //window.location.href = window.location + "Main/Allloans";
    goto("Main/Allloans");
  };
  componentDidMount = () => {
    this.getApprovedlaons();
  };
  getApprovedlaons() {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: link() + `&do=get&ra=approval&ft=areclist&elmt=category&id=loan`,

      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          aprLoan: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { aprLoan } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Loan</h6>
            <div className="searchadd">
              <button className="btndistrer" onClick={this.handleBack}>
                Loans
              </button>
            </div>
            <h6 className="activeuser">Loan Approval</h6>
            <div className="cardtable ">
              <div className="membadd">
                <h6 className="activeuserpro"> Pending Review</h6>
              </div>
              <div className="cardCapitalvog">
                <table className="table table-hover  table-responsive  ml-5">
                  <thead>
                    <tr>
                      <th className="userprony ">Request Date</th>
                      <th className="userprony ">Cur</th>
                      <th className="userprony ">Capital</th>
                      <th className="userprony ">Beneficiary</th>
                      <th className="userprony ">Requestor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="userpronouwe ">8/20/2021</td>
                      <td className="userpronouwe ">rwf</td>
                      <td className="userpronouwe ">450,000.00</td>
                      <td className="userpronouwe ">John Doe</td>
                      <td className="userpronouwe ">Bruce Melody</td>
                    </tr>
                    <tr>
                      <td className="userpronouwe ">8/20/2021</td>
                      <td className="userpronouwe ">rwf</td>
                      <td
                        className="userpronouwere "
                        onClick={this.handleShowMore}
                      >
                        450,000.00
                      </td>
                      <td className="userpronouwe ">John Doe</td>
                      <td className="userpronouwe ">Bruce Melody</td>
                    </tr>
                    <tr>
                      <td className="userpronouwe ">8/20/2021</td>
                      <td className="userpronouwe ">rwf</td>
                      <td className="userpronouwe ">450,000.00</td>
                      <td className="userpronouwe ">John Doe</td>
                      <td className="userpronouwe ">Bruce Melody</td>
                    </tr>
                    <tr>
                      <td className="userpronouwe ">8/20/2021</td>
                      <td className="userpronouwe ">rwf</td>
                      <td className="userpronouwe ">450,000.00</td>
                      <td className="userpronouwe ">John Doe</td>
                      <td className="userpronouwe ">Bruce Melody</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div className="cardtable ">
              <div className="membadd">
                <h6 className="activeuserpro"> Approval History</h6>
              </div>
              <div className="cardtablecapital">
                <table className="table table-hover  table-responsive  ml-5">
                  <thead>
                    <tr>
                      <th className="userprony ">Request Date</th>
                      <th className="userprony ">Cur</th>
                      <th className="userprony ">Capital</th>
                      <th className="userprony ">My Decision</th>
                      <th className="userprony ">Decision Date</th>
                      <th className="userprony ">Beneficiary</th>
                      <th className="userprony ">Requestor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {aprLoan &&
                      aprLoan.map((aprvd) => {
                        return (
                          <tr>
                            <td className="userpronouwe ">
                              {aprvd.requestDate}
                            </td>
                            <td className="userpronouwe ">rwf</td>
                            <td className="userpronouwe ">{aprvd.amount}</td>
                            <td className="userpronouwe ">
                              {aprvd.approvalStatus}
                            </td>
                            <td className="userpronouwe ">
                              {" "}
                              {aprvd.approvalDate}
                            </td>
                            <td className="userpronouwe ">John Doe</td>
                            <td className="userpronouwe ">{aprvd.name}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default LoanAproval;
