import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { link, goto, downloadFile, wordContains, numb2 } from "../link.js";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Loading from "../loading";
import Footer from "../footer";
// import fileDownload from "js-file-download";

export class IcomeReport extends Component {
  constructor() {
    super();
    this.state = {
      topay: 0,
      paid: 0,
      balance: 0,
      filters: {
        datefrom: "",
        dateTo: "",
        userid: "",
        memberid: "",
        roundid: "",
        roundname: "",
        name: "",
        termstartdate: "",
        termduedate: "",
        status: "",
      },
      dataOut: 15,
      loading: false,
      rounds: [],
      isLoading: false,
      reportType: "incomecontr",
      reportResults: [],

      totaltopay: "0.0",
      totalbalance: "0.0",
      totalpaid: "0.0",
    };

    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.getReport = this.getReport.bind(this);
  }
  // gettotalFilterReport = () => {
  //   let topay = 0;
  //   let paid = 0;
  //   let filterresult = [];
  //   filterresult.push(
  //     this.state.reportResults.attachment &&
  //       this.state.reportResults.attachment.forEach((item, indx) => {
  //         item.filter((ite) =>
  //           ite.names?.toLowerCase().includes(this.state.filters.name)
  //         );
  //       })
  //   );
  //   console.log(filterresult);
  //   topay = topay + filterresult.topay;
  //   paid = paid + filterresult.paid;

  //   this.setState({
  //     topay,
  //     paid,
  //   });
  // };
  handleFilterChange = (e) => {
    e.preventDefault();
    let filters = this.state.filters;
    filters[e.target.name] = e.target.value;
    this.setState({ filters });
    //console.log(this.state.filters);

    let topay = 0;
    let paid = 0;
    let balance = 0;
    let filterresult =
      this.state.reportResults.attachment &&
      this.state.reportResults.attachment
        .filter((ite) =>
          //ite.names?.toLowerCase().includes(this.state.filters.name)

          wordContains(
            ite.names?.toLowerCase(),
            this.state.filters.name.toLowerCase()
          )
        )
        .slice(0, this.state.dataOut)
        .forEach((item, indx) => {
          topay = topay + parseFloat(item.topay);
          paid = paid + parseFloat(item.paid);
          balance = balance + parseFloat(item.balance);
        });
    this.setState({
      topay,
      paid,
      balance,
    });
  };

  // handleUserChange = (e) => {
  //   this.setState({
  //     filter.userid: e.target.value,
  //   });
  // };
  getReport = (reptype) => {
    var token = localStorage.getItem("token");

    let reptypedefault = this.state.reportType;

    if (reptype === "") {
      if (reptypedefault !== "") reptype = reptypedefault;
      else return false;
    }
    var postData = {
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=report&rec=${reptype}`, postData, {
        headers: headers,
      })
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res.data);
        setTimeout(() => {
          //var rid= res.data.roundid;
          let filters = this.state.filters;
          filters.roundid = res.data.roundid;
          this.setState({
            reportResults: res.data,
            filters: filters,
            loading: false,
            dataOut: 15,
          });
        }, 1000);

        return true;
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
    return false;
  };

  getRounds = () => {
    var token = localStorage.getItem("token");
    //var rounds = [];
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    fetch(
      link() + `&do=getshort&ra=round&ft=areclist&elmt=roundStatus&id=Active`,
      {
        method: "post",
        headers: headers,
        //body: JSON.stringify(data),
        //responseType: "blob",
      }
    ) // FETCH BLOB FROM IT
      .then((response) => response.json())
      .then((response) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
      //  .console.log("Round list received=");
      //  .console.log(response);
        //rounds = response;
        this.setState({ rounds: response });
        //return response;
        this.getReport("");
      })
      .catch((err) => {
        console.log(err);
      });

    //return rounds;
  };

  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getRounds();
    // this.gettotalFilterReport();

    //this.getReport("incomecontr");
  };

  handleChangeSelect = (e) => {
    this.setState({
      loading: true,
    });
    let reptype = this.stage;
    //let fitlers = this.state.payment;
    //payment.firstName = e.target.options[e.target.selectedIndex].text;
    // payment.userID = e.target.value;
    this.setState({
      reportType: e.target.value,

      //payment: payment,
    });
    if (e.target.value) {
    }

  //  .console.log(
      // "Target= " + e.target.name + ", value= " + e.target.value
      //+
      //", content= " +
      //e.target.value
    // );

    this.getReport(e.target.value);
    //console.log(this.state.payment);
  };

  handleRoundSelect = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let filters = this.state.filters;
    //let fitlers = this.state.payment;
    filters.roundid = e.target.value;
    // payment.userID = e.target.value;
    this.setState({
      filters: filters,
    });

  //  .console.log(this.state.filters);

    this.getReport(this.state.reportType);
    //console.log(this.state.payment);
  };

  handleShowMore = () => {
    //window.location.href = window.location + "Main/LoanCheck";
    goto("Main/LoanCheck");
  };

  download = () => {
    var token = localStorage.getItem("token");

    let reptypedefault = this.state.reportType;

    if (reptypedefault === "") {
      return;
    }

    var postData = {
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    };
    var headers = {
      "Content-Type":
        "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `${token}`,
    };

    downloadFile(
      "POST",
      link() + `&do=get&ra=report&rec=${reptypedefault}` + `&ufile=getfile`,
      postData,
      headers, "report.xlsx",
    );
  };
  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 20,
      };
    });
  };
  render() {
    const { reportResults, rounds, topay, paid, balance, loading } = this.state;
    var length = reportResults.attachment && reportResults.attachment.length;
    var count = 1;

    // console.log(reportResults.attachment && reportResults.attachment.length);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Reports</h6>
            <div className="report">
              <Link to="/Main/IncomeReport" className="contrilink">
                Income
              </Link>
              <Link to="/Main/ExpenseReport" className="contrilink">
                Expense
              </Link>
              <Link to="/Main/Cashbook" className="contrilink">
                Cash Book
              </Link>
              <Link to="/Main/financialstatements" className="contrilink">
                Financial Statements
              </Link>
              {/* <Link to="/Main/otherreports" className="contrilink">
                Other Reports
              </Link> */}
            </div>
            <h6 className="activeuser mt-3 d-flex">
              <div className="col conteinpu">
                Incomes:
                <span className="unity">
                  {this.state.reportResults.roundName}{" "}
                </span>
              </div>
              {length > 0 ? (
                <div className="print" onClick={() => this.download()}>
                  <i class="fas fa-download"></i>
                </div>
              ) : (
                ""
              )}
            </h6>

            <div className="cardtable ">
              <div className="containeruserscroll">
                <div className="membaddy ">
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      Select Round:
                      <select
                        id="reporttype"
                        type="text"
                        name="reporttype"
                        onChange={(e) => this.handleRoundSelect(e)}
                        selected={this.state.filters.roundid}
                        className="logininputsselectyu"
                        placeholder="Select Bank type"
                      >
                        <option value="">Select Round</option>{" "}
                        {rounds &&
                          rounds.map((round) => {
                            return (
                              <option value={round.roundID}>
                                {round.roundName}
                              </option>
                            );
                          })}
                        {/* <option value="incomecontr">Round 9</option> */}
                      </select>
                    </div>
                  </p>
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      Select Report:
                      <select
                        id="reporttype"
                        type="text"
                        name="reporttype"
                        onChange={(e) => this.handleChangeSelect(e)}
                        selected={this.state.reportType}
                        className="logininputsselectyu"
                        placeholder="Select Bank type"
                      >
                        <option value="incomecontr">
                          Income from Contributions
                        </option>
                        <option value="incomecontrpnlt">
                          Income from Penalties on Contributions
                        </option>
                        <option value="incomepnltothers">
                          Income from Penalties on Absence & Latency
                        </option>
                        <option value="incomeloan">Income from Loans</option>
                        <option value="incomeinvest">
                          Income from Investment
                        </option>
                        <option value="incomeother">Income from Others</option>
                        <option value="mreserve">Monthly Reserves</option>
                        <option value="incomeunidentified">
                          Unindentified
                        </option>
                        <option value="allreceivables">Receivables</option>
                        <option value="allpayables">Payable</option>
                      </select>
                    </div>
                  </p>{" "}
                  <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="loginsearchfayui"
                        placeholder=" Search payer"
                        onChange={(e) => this.handleFilterChange(e)}
                        // value={this.state.filters.name}
                      />
                    </div>
                  </p>{" "}
                  {/* <p className="parainputsapphf ">
                    <div className=" conteinpu ">
                      <button
                        type="submit"
                        id="go"
                        className="viewdetcli ml-5"
                        placeholder=" Search payer"
                        onClick={(e) => this.getReport(this.state.reportType)}
                        value="Go"
                      />
                    </div>
                  </p> */}
                </div>

                {/* </div> */}
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {this.state.reportType == "allpayables" ||
                    this.state.reportType == "allreceivables" ? (
                      <div className="activeuser d-flex ">
                        <div className="col conteinpu">
                          Payable:
                          {this.state.filters.name == "" ? (
                            <span className="unity">
                              {this.state.reportResults.currency}{" "}
                              {numb2(
                                this.state.reportResults.capitalbalanceTotal
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col conteinpu">
                          Others:
                          {this.state.filters.name == "" ? (
                            <span className="unity">
                              {this.state.reportResults.currency}{" "}
                              {numb2(
                                this.state.reportResults.penaltybalanceTotal
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col conteinpu">
                          Sum:
                          {this.state.filters.name == "" ? (
                            <span className="unity">
                              {this.state.reportResults.currency}
                              {numb2(this.state.reportResults.totalbalance)}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="activeuser d-flex ">
                        <div className="col conteinpu">
                          Total to Pay:
                          {this.state.filters.name == "" ? (
                            <span className="unity">
                              {this.state.reportResults.currency}{" "}
                              {numb2(this.state.reportResults.topay)}
                            </span>
                          ) : (
                            <span className="unity">
                              {this.state.reportResults.currency} {numb2(topay)}
                            </span>
                          )}
                        </div>
                        <div className="col conteinpu">
                          Total Paid:
                          {this.state.filters.name == "" ? (
                            <span className="unity">
                              {this.state.reportResults.currency}{" "}
                              {numb2(this.state.reportResults.paid)}
                            </span>
                          ) : (
                            <span className="unity">
                              {this.state.reportResults.currency} {numb2(paid)}
                            </span>
                          )}
                        </div>
                        <div className="col conteinpu">
                          Balance:
                          {this.state.filters.name == "" ? (
                            <span className="unity">
                              {this.state.reportResults.currency}{" "}
                              {numb2(this.state.reportResults.balance)}
                            </span>
                          ) : (
                            <span className="unity">
                              {this.state.reportResults.currency}{" "}
                              {numb2(balance)}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <table className="table table-hover  table-responsive  tablealot  dupa">
                      {this.state.isLoading ? (
                        <Loading />
                      ) : (
                        <>
                          {this.state.reportType == "allpayables" ||
                          this.state.reportType == "allreceivables" ? (
                            <>
                              {" "}
                              <thead>
                                <tr>
                                  <th className="userpronyblue "> </th>
                                  <th className="userpronyblue ">ID</th>
                                  <th className="userpronyblue ">Month/Date</th>
                                  <th className="userpronyblue ">Payer</th>
                                  <th className="userpronyblue ">Category</th>
                                  <th className="userpronyblue ">Type</th>
                                  <th className="userpronyblue ">
                                    Description
                                  </th>
                                  <th className="userpronyblue ">Curr</th>

                                  <th className="userpronyblue ">Payable</th>
                                  <th className="userpronyblue ">Other</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportResults.attachment &&
                                  reportResults.attachment

                                    .filter((item) =>
                                      // item.names
                                      //   ?.toLowerCase()
                                      //   .includes(this.state.filters.name)
                                      wordContains(
                                        item.names?.toLowerCase(),
                                        this.state.filters.name.toLowerCase()
                                      )
                                    )
                                    .slice(0, this.state.dataOut)
                                    .map((item) => {
                                      //let dt = item.paiddate.toString().substring(0, 10);
                                      return (
                                        <tr>
                                          <td className="userinfo ">
                                            {count++}
                                          </td>
                                          <td className="userinfo ">
                                            {item.itemid}
                                          </td>
                                          <td className="userinfo ">
                                            {item.month}
                                          </td>
                                          <td className="userinfo ">
                                            {item.names}
                                          </td>
                                          <td className="userinfo ">
                                            {item.category}
                                          </td>
                                          <td className="userinfo ">
                                            {item.type}
                                          </td>
                                          <td className="userinfo">
                                            {item.description}
                                          </td>
                                          <td className="userinfo">
                                            {item.currency}
                                          </td>
                                          <td className="userinfo">
                                            {numb2(item.capitalbalance)}
                                          </td>
                                          <td className="userinfo">
                                            {numb2(item.penaltybalance)}
                                          </td>

                                          {/* <td className="userinfo">-</td> */}
                                        </tr>
                                      );
                                    })}
                              </tbody>
                            </>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th className="userpronyblue "> </th>
                                  <th className="userpronyblue ">ID</th>
                                  <th className="userpronyblue ">
                                    {" "}
                                    Month/Date
                                  </th>
                                  <th className="userpronyblue ">Payer</th>
                                  <th className="userpronyblue ">Category</th>
                                  <th className="userpronyblue ">Type</th>
                                  <th className="userpronyblue ">
                                    Description
                                  </th>
                                  <th className="userpronyblue ">Curr</th>
                                  <th className="userpronyblue ">Paid Date</th>
                                  <th className="userpronyblue ">
                                    Total to Pay
                                  </th>
                                  <th className="userpronyblue ">Paid</th>
                                  <th className="userpronyblue ">Balance</th>
                                  {/* <th className="userpronyblue ">-</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {reportResults.attachment &&
                                  reportResults.attachment

                                    .filter((item) =>
                                      // item.names
                                      //   ?.toLowerCase()
                                      //   .includes(this.state.filters.name)
                                      wordContains(
                                        item.names?.toLowerCase(),
                                        this.state.filters.name.toLowerCase()
                                      )
                                    )
                                    .slice(0, this.state.dataOut)
                                    .map((item) => {
                                      //let dt = item.paiddate.toString().substring(0, 10);
                                      return (
                                        <tr>
                                          <td className="userinfo ">
                                            {count++}
                                          </td>
                                          <td className="userinfo ">
                                            {item.itemid}
                                          </td>
                                          <td className="userinfo ">
                                            {item.month}
                                          </td>
                                          <td className="userinfo ">
                                            {item.names}
                                          </td>
                                          <td className="userinfo ">
                                            {item.category}
                                          </td>
                                          <td className="userinfo ">
                                            {item.type}
                                          </td>
                                          <td className="userinfo">
                                            {item.description}
                                          </td>
                                          <td className="userinfo">
                                            {item.currency}
                                          </td>
                                          <td className="userinfo">
                                            {item.paiddate
                                              .toString()
                                              .substring(0, 10)}
                                          </td>
                                          <td className="userinfo">
                                            {numb2(item.topay)}
                                          </td>
                                          <td className="userinfo">
                                            {numb2(item.paid)}
                                          </td>
                                          <td className="userinfo">
                                            {numb2(item.balance)}
                                          </td>
                                          {/* <td className="userinfo">-</td> */}
                                        </tr>
                                      );
                                    })}
                              </tbody>
                            </>
                          )}
                        </>
                      )}

                      {reportResults.attachment &&
                      reportResults.attachment.length <= this.state.dataOut ? (
                        ""
                      ) : (
                        <div className="buttoncenter">
                          {reportResults.attachment &&
                          reportResults.attachment.length >=
                            this.state.dataOut ? (
                            <button
                              type="button"
                              className="btnbookrermore  "
                              onClick={() => this.loadmore()}
                            >
                              <span className="mr-2 mt-1">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                              <span>Load More</span>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </table>
                  </>
                )}
              </div>
            </div>

            <br />
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default IcomeReport;
