import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../Auth";
import Navbar from "../../Navbar";
import SignOut from "../../SignOut";
import { link, numb2, wordContains, goto } from "../../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../../footer";
import Loading from "../../loading";
export class RoundSchedules extends Component {
  constructor() {
    super();
    this.state = {
      schedule: {},

      reportResults: {},
      openModal: false,
      loading: false,
      errmessage: "",
      view: "member",
      mheader: [],
      searchedname: "",
      groupinfo: {},
      roundid: "",
      names: "",
      title: "Member Schedule View",
      buttonmessage: "Save",
    };
  }

  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getSchedules();
  };

  getSchedules() {
    var token = localStorage.getItem("token");
    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";
    //console.log("path is " + path);
    //console.log("roundID= " + path);
    this.setState({ roundid: roundId });
    var config = {
      method: "post",

      url: link() + `&do=getroundschedules&ra=round&elmt=roundId&id=` + roundId,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data);

        if (Object.keys(response.data).length > 0) {
          //console.log("Data saved");
          this.setState({
            reportResults: response.data,
            loading: false,
          });

          this.parseMonths();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  parseMonths = () => {
    const { mheader, reportResults } = this.state;

    reportResults.attachment &&
      reportResults.attachment.map((member) => {
        member.attachment &&
          member.attachment.map((schedule) => {
            if (!mheader.includes(schedule.month)) {
              // ✅ only runs if value not in array
              mheader.push(schedule.month);
            }
          });
      });
    mheader.sort();
    //console.log("mheader= " + mheader);
    this.setState({ mheader });
  };

  handleChange = (e) => {
    //console.log("Changing month comment= " + e.target.value);

    const { schedule } = this.state;
    schedule[e.target.name] = e.target.value;
    this.setState({
      schedule,
      errmessage: "",
    });

    if (
      this.state.schedule.id === "" ||
      this.state.schedule.month.trim().length != 7
    ) {
      this.setState({
        errmessage: "Use the right format YYYY-MM. e.g '2022-03'",
      });

      //return;
    }

    var enddate =
      this.state.reportResults.distrenddate &&
      this.state.reportResults.distrenddate.substring(0, 7);
    var startdate =
      this.state.reportResults.distrstartdate &&
      this.state.reportResults.distrstartdate.substring(0, 7);
    if (
      startdate > this.state.schedule.month.trim() ||
      enddate < this.state.schedule.month.trim()
    ) {
      this.setState({
        errmessage:
          "Month must be between '" + startdate + "' and '" + enddate + "' ",
      });

      //return;
    }

    //console.log(this.state.schedule);
  };

  handleChangeSelect = (e) => {
    var title =
      e.target.value === "member"
        ? "Member Schedule View"
        : "Month Schedule View";
    this.setState({ view: e.target.value, title: title });

    //console.log("view is now= " + this.state.view);
  };

  handleChangeSearch = (e) => {
    this.setState({
      searchedname: e.target.value,
    });
  };

  openModal = (item, names, startdate, enddate) => {
    console.log("Modal Opened for " + names);
    this.setState({
      openModal: true,
      schedule: item,
      names: names,
      buttonmessage: "Save",
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
      errmessage: "",
      schedule: {},
      names: "",
    });
  };
  takeAction(e, action) {
    e.preventDefault();

    if (
      this.state.schedule.id === "" ||
      this.state.schedule.month.trim().length != 7
    ) {
      this.setState({
        errmessage: "Use the right format YYYY-MM. e.g '2022-03'",
      });

      return;
    }

    var enddate =
      this.state.reportResults.distrenddate &&
      this.state.reportResults.distrenddate.substring(0, 7);
    var startdate =
      this.state.reportResults.distrstartdate &&
      this.state.reportResults.distrstartdate.substring(0, 7);
    if (
      startdate > this.state.schedule.month.trim() ||
      enddate < this.state.schedule.month.trim()
    ) {
      this.setState({
        errmessage:
          "Month must be between '" + startdate + "' and '" + enddate + "' ",
      });

      return;
    }

    this.setState({ buttonmessage: "Please wait !" });

    var token = localStorage.getItem("token");
    var config = {
      method: "post",
      url:
        link() +
        `&do=update&w=${action}&ra=distribution&elmt=distributionID&id=${this.state.schedule.id}&newschedule=` +
        this.state.schedule.month.trim(),
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log("Response of update=");
        console.log(response.data);
        //this.closeModal();
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonmessage: "Save",
          errmessage: "Error occured. Please try again later. '",
        });
      });
  }

  requestChange(e, roundid, action) {
    //e.preventDefault();
    console.log("Requesting " + action);
    var token = localStorage.getItem("token");
    var postData = this.state.groupinfo;
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    // localStorage.setItem("currentView", "paymentapproval");
    axios
      .post(
        link() + `&do=${action}&ra=round&elmt=roundID&id=${roundid}`,

        postData,
        { headers: headers }
      )
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res.data);

        alert("Submitted");
        window.location.reload(true);
      })
      .catch((err) => {
        this.setState({
          isitLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
        alert("Something went wrong. Please try again later.");
      });

    this.closeModal();
  }

  render() {
    const { reportResults, mheader, title, loading } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Distribution</h6>
            <div className="searchadd">
              <button
                className="btndistr"
                disabled={loading}
                onClick={() => goto("Main/Contribution/" + this.state.roundid)}
              >
                Contribution
              </button>
              <button
                className="btndistr"
                disabled={loading}
                onClick={() => goto("Main/Distribution/" + this.state.roundid)}
              >
                Round Payment
              </button>

              {reportResults && reportResults.status === "Pending" ? (
                <button
                  className="btndistr"
                  onClick={(e) =>
                    this.requestChange(e, reportResults.roundid, "distrdate")
                  }
                >
                  Randomize Schedules
                </button>
              ) : (
                ""
              )}

              {/* <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search a Round here…
                "
              /> */}
            </div>
            <h6 className="activeuser mt-3">
              <div className="col conteinpu">
                <span className="unity">Distribution schedules for</span>
                <span className="conteinpu">{reportResults.name}</span>
              </div>
            </h6>
            <div className="cardtable ">
              <div className="alldeta">
                <div className="membaddd mb-3">
                  <div className="lebelenter mb-2"> Select View</div>
                  <select
                    id="reporttype"
                    type="text"
                    name="reporttype"
                    onChange={(e) => this.handleChangeSelect(e)}
                    selected={this.state.view}
                    className="logininputsselect"
                    placeholder="Select Bank type"
                  >
                    <option value="member">Member View</option>
                    <option value="month">Month View</option>
                  </select>

                  <div className="lebelenter mb-2"> Search Member</div>
                  <div className="conteinpu">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="loginsearchfaro"
                      placeholder=" Search Member"
                      onChange={(e) => this.handleChangeSearch(e)}
                      // value={this.state.filters.name}
                    />
                  </div>
                </div>

                <div className="activeuser">
                  <div className="unity">{title}</div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {this.state.view === "member" ? (
                      Object.keys(reportResults).length > 0 ? (
                        <table className="table table-hover  table-responsive  tablealot ">
                          <thead>
                            <tr>
                              <th className="userpronyblue ">Names</th>
                              <th className="userpronyblue "></th>
                              <th className="userpronyblue "> Months</th>
                              <th className="userpronyblue "></th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportResults.attachment &&
                              reportResults.attachment.map((member) => {
                                if (
                                  wordContains(
                                    member.names.toLowerCase(),
                                    this.state.searchedname.toLowerCase()
                                  )
                                ) {
                                  var mname = "";
                                  var printname = true;
                                  var itemnum = "";
                                  return (
                                    member.attachment &&
                                    member.attachment.map((schedule) => {
                                      mname =
                                        printname === true ? member.names : "";
                                      itemnum =
                                        printname === true
                                          ? member.itemnum
                                          : "";
                                      printname = false;
                                      return (
                                        <tr>
                                          {/* <td>{itemnum}</td> */}
                                          <td className="userinfowe ">
                                            {mname}
                                          </td>
                                          <td
                                            className="userinfowe "
                                            style={{ width: 50 }}
                                          >
                                            {""}
                                          </td>
                                          <td className="userinfowe ">
                                            {schedule.month}
                                          </td>
                                          {/* <td
                                        className="userinfowe "
                                        onClick={() =>
                                          this.openModal(
                                            schedule,
                                            member.names,
                                            reportResults.distrstartdate,
                                            reportResults.distrenddate
                                          )
                                        }
                                      >
                                        update
                                      </td> */}
                                          <td
                                            className="Editbuttonstu "
                                            onClick={() =>
                                              this.openModal(
                                                schedule,
                                                member.names,
                                                reportResults.distrstartdate,
                                                reportResults.distrenddate
                                              )
                                            }
                                          >
                                            <i class="fas fa-edit"></i>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  );
                                }
                              })}
                            <tr>
                              <td className="userinfowe "></td>
                              <td className="userinfowe "></td>
                              <td className="userinfowe "></td>
                              <td className="userinfowe "></td>
                              <td className="userinfowe "></td>

                              {/* <td className="userinfowe"></td> */}
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        "No Data"
                      )
                    ) : Object.keys(reportResults).length > 0 ? (
                      <table className="table table-hover  table-responsive  tablealot ">
                        <thead>
                          <tr>
                            <th className="userpronyblue "> Months</th>
                            <th className="userpronyblue "></th>
                            <th className="userpronyblue ">Names</th>
                            {/* <th className="userpronyblue "></th> */}
                            <th className="userpronyblue "></th>
                          </tr>
                        </thead>
                        <tbody>
                          {mheader &&
                            mheader.map((month) => {
                              //console.log("Header month=" + month);

                              var mdate = "";
                              var printname = true;
                              var itemnum = "";
                              return (
                                reportResults.attachment &&
                                reportResults.attachment.map((member) => {
                                  //-------------start-------------

                                  if (
                                    wordContains(
                                      member.names.toLowerCase(),
                                      this.state.searchedname.toLowerCase()
                                    )
                                  ) {
                                    return (
                                      member.attachment &&
                                      member.attachment.map((schedule) => {
                                        if (schedule.month === month) {
                                          // console.log(
                                          //   "found Member " +
                                          //     member.names +
                                          //     ", month=" +
                                          //     month +
                                          //     ", schedulemonth =" +
                                          //     schedule.month
                                          // );
                                          mdate =
                                            printname === true ? month : "";
                                          printname = false;
                                          return (
                                            <tr>
                                              <td className="userinfowe ">
                                                {mdate}
                                              </td>
                                              <td
                                                className="userinfowe "
                                                style={{ width: 50 }}
                                              >
                                                {""}
                                              </td>
                                              <td className="userinfowe ">
                                                {member.names}
                                              </td>

                                              <td
                                                className="Editbuttonstu "
                                                onClick={() =>
                                                  this.openModal(
                                                    schedule,
                                                    member.names,
                                                    reportResults.distrstartdate,
                                                    reportResults.distrenddate
                                                  )
                                                }
                                              >
                                                <i class="fas fa-edit"></i>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      })
                                    );
                                  }
                                })
                              );
                            })}

                          <tr>
                            <td className="userinfowe "></td>
                            <td className="userinfowe "></td>
                            <td className="userinfowe "></td>
                            <td className="userinfowe "></td>

                            {/* <td className="userinfowe"></td> */}
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      "No Data"
                    )}
                  </>
                )}
              </div>
            </div>
            <br />

            {/* <>-----------------------------------------------------------------</> */}

            <Modal show={this.state.openModal} className="modalAll">
              <Modal.Body className="Madl">
                <div className="detmod">
                  <div>
                    <p className="closeModale" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className=" conteinputitle ">
                    Update {this.state.names} Schedule
                  </div>
                  <div className="d-flex">
                    <div className="conteinpu ml-3">
                      {/* Select the action you would like to take */}
                      {/* <p className="unity"> Give Back</p>
                      <p className="unity">Convert</p> */}
                      <div class="form-group">
                        <label className="lebelenter">
                          Edit Distribution date in same format:
                        </label>

                        <input
                          type="text"
                          id="actionamount"
                          name="month"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.schedule.month}
                          className="logininputsele"
                          placeholder="Enter date: YYYY-MM"
                        />
                      </div>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "duedatemonth")}
                      >
                        Save
                      </button>
                      <p className="unity redColor">{this.state.errmessage}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default RoundSchedules;
