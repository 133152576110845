import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, goto, numb2 } from "../link.js";
import axios from "axios";
import Footer from "../footer";
import Loading from "../loading";
export class Allloans extends Component {
  constructor() {
    super();
    this.state = {
      loanlist: [],
      loading: false,
      searchItem: "",
    };
  }
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  handleLoanAprove = () => {
    //window.location.href = window.location + "Main/LoanAproval";
    goto("Main/LoanAproval");
  };
  handleRequestLoan = () => {
    //window.location.href = window.location + "Main/LoanRequest";
    goto("Main/LoanRequest");
  };
  gotToDetails = (id) => {
    //window.location.href = window.location + "Main/LoanDetails" + "/" + id;
    goto("Main/LoanDetails" + "/" + id);
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getLoan();
  };
  getLoan() {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",

      url: link() + `&do=getfull&ra=loan&ft=areclist`,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        //console.log(response.data);
        if (Array.isArray(response.data))
          this.setState({
            loanlist: response.data,
            loading: false,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { loanlist, loading } = this.state;
  //  .console.log(loanlist);

    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />

          <br />
          <Container fluid>
            <div className="welcome ">
              <h6 className="activejobs">Loan </h6>
            </div>
            <div className="searchexp">
              <button
                className="btndistr"
                disabled={loading}
                onClick={this.handleRequestLoan}
              >
                Request a Loan
              </button>
              {/* <button className="btndistr" onClick={this.handleLoanAprove}>
                Approval Requests
              </button> */}
            </div>
            <h6 className="activeuser"> All Loans</h6>

            <div className=" cardtable">
              <div className="searchloan">
                <input
                  type="text"
                  id="search"
                  className="loginsearchfa"
                  placeholder=" Search name…
                "
                  onChange={(e) => this.handleUserChange(e)}
                />
              </div>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <table className="table table-hover  table-responsive  tablealot ">
                    <thead>
                      <tr>
                        <th className="userprony ">Request Date</th>
                        <th className="userprony ">Effective Date</th>
                        <th className="userprony ">Names</th>
                        <th className="userprony ">Curr</th>
                        <th className="userprony ">Capital Requested</th>
                        <th className="userprony ">Interest</th>
                        <th className="userprony ">Penalties</th>
                        <th className="userprony ">Others</th>
                        <th className="userprony ">Loan Paid Date</th>
                        <th className="userprony "> Cap Paid</th>
                        <th className="userprony ">Int Paid</th>
                        <th className="userprony ">Penalty Paid</th>
                        <th className="userprony ">Other Paid</th>
                        <th className="userprony ">Total Paid</th>
                        <th className="userprony ">Cap Unpaid</th>
                        <th className="userprony ">Int Unpaid</th>
                        <th className="userprony ">Penalty Unpaid</th>
                        <th className="userprony ">Others Unpaid</th>
                        <th className="userprony ">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanlist &&
                        loanlist
                          .filter((item) =>
                            item.names

                              ?.toLowerCase()
                              .includes(this.state.searchItem)
                          )
                          .map((loan) => {
                            if (loan.approvalstatus !== "Rejected")
                              return (
                                <tr>
                                  <td className="userproneru ">
                                    {" "}
                                    {loan.requestdate
                                      ? loan.requestdate
                                          .toString()
                                          .substring(0, 10)
                                      : ""}
                                  </td>
                                  <td className="userproneru ">
                                    {loan.status === "Pending"
                                      ? "Pending"
                                      : loan.effectivedate
                                      ? loan.effectivedate
                                          .toString()
                                          .substring(0, 10)
                                      : ""}
                                  </td>
                                  <td
                                    className="userproneruloan  "
                                    onClick={() =>
                                      this.gotToDetails(loan.loanid)
                                    }
                                  >
                                    {loan.names}
                                  </td>
                                  <td className="userproneru ">
                                    {loan.currency}
                                  </td>
                                  <td className="userproneru">
                                    {loan.status == "Pending"
                                      ? "Pending"
                                      : numb2(loan.capitaltopay)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.interesttopay)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.penaltytopay)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.othertopay)}
                                  </td>
                                  <td className="userinfo">
                                    {loan.loanpaiddate.substring(0, 10)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.capitalpaid)}
                                  </td>
                                  <td className="userinfo">
                                    {" "}
                                    {numb2(loan.interestpaid)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.penaltypaid)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.otherother)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.paid)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.capitalbalance)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.interestbalance)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.penaltybalance)}
                                  </td>
                                  <td className="userinfo">
                                    {" "}
                                    {numb2(loan.otherbalance)}
                                  </td>
                                  <td className="userinfo">
                                    {numb2(loan.balance)}
                                  </td>
                                </tr>
                              );
                          })}
                      <tr>
                        <td colspan="20">
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              <br />
            </div>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Allloans;
