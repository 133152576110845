import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../Auth";
import Navbar from "../../Navbar";
import SignOut from "../../SignOut";
import { link, goto, numb2 } from "../../link.js";
import axios from "axios";
import Footer from "../../footer";
import Loading from "../../loading";
export class Distribution extends Component {
  constructor() {
    super();
    this.state = {
      cont: [],
      mheader: [],
      ytdtopay: 0,
      ytdpaid: 0,
      membership: 0,
      membershipamount: 0,
      mapytdtopay: new Map(),
      mapytdpaid: new Map(),
      numberofmembers: 0,
      loading: false,
      roundid: "",
      searchItem: "",
    };
  }
  // handleCreateRound = () => {
  //   window.location.href = window.location + "Main/CreateRound";
  // };
  // handleEditRond = () => {
  //   window.location.href = window.location + "Main/EditRound";
  // };
  // handleMigrateRond = () => {
  //   window.location.href = window.location + "Main/MigrateRound";
  // };
  handleContribution = () => {
    goto("Main/Contribution/" + this.state.roundid);
  };
  handleDistribution = () => {
    goto("Main/Distribution/" + this.state.roundid);
  };
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getDistribution();
  };
  getDistribution() {
    var token = localStorage.getItem("token");
    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";
    console.log("path is " + path);
    console.log("roundID= " + path);
    this.setState({ roundid: roundId });
    var config = {
      method: "post",

      url: link() + `&do=getdistributions&ra=round&elmt=roundId&id=` + roundId,
      headers: {
        Authorization: `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        //const sums = [];
        let mheader = [];
        let ytdtopay = 0;
        let ytdpaid = 0;
        let numberofmembers = 0;

        let membership = 0;
        let membershipamount = 0;
        let mapytdtopay = new Map();
        let mapytdpaid = new Map();
        response.data.attachement &&
          response.data.attachement.map((item) => {
            numberofmembers++;
            membership = membership + parseFloat(item.numberOfMemberships);
            membershipamount =
              membershipamount * parseFloat(item.membershipTermAmount);
            //item.attachment.Distribution.map((val) => {
            ytdtopay =
              ytdtopay + parseFloat(item.attachment.Distribution.topay || 0);
            ytdpaid =
              ytdpaid + parseFloat(item.attachment.Distribution.paid || 0);
            item.attachment.Distribution &&
              item.attachment.Distribution.attachment.map((mdistr) => {
                if (!mheader.includes(mdistr.month)) {
                  // ✅ only runs if value not in array
                  mheader.push(mdistr.month);
                }

                let tp = parseFloat(mapytdtopay.get(mdistr.month)) || 0;

                tp = tp + parseFloat(mdistr.topay);
                mapytdtopay.set(mdistr.month, tp);
                //console.log("2. tp=" + tp);
                let pd = parseFloat(mapytdpaid.get(mdistr.month)) || 0;
                //console.log("1. pd=" + pd);
                pd = pd + parseFloat(mdistr.paid);
                mapytdpaid.set(mdistr.month, pd);
                //console.log("2. pd=" + pd);
              });

            mheader.sort();
          });

        console.log(mheader);
        this.setState({
          cont: response.data,
          mheader,
          ytdtopay,
          ytdpaid,
          membership,
          membershipamount,
          mapytdtopay,
          mapytdpaid,
          numberofmembers,
          roundid: response.data.roundID,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let distrlist = this.state.cont;
    let mheader = this.state.mheader;
    console.log("Full list");
    console.log(distrlist);
    console.log(this.state.roundid);
    var startDate = distrlist.startDate || "";
    var endDate = distrlist.endDate || "";
    var distributiontermamount = distrlist.membershipContributionAmount || 0;

    startDate = startDate.substring(0, 10);
    endDate = endDate.substring(0, 10);
    const { loading } = this.state;
    //console.log("sdate= " + startDate);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Distributions</h6>
            <div className="searchaddRoun">
              <button
                className="btndistr"
                onClick={this.handleContribution}
                disabled={loading}
              >
                Contribution
              </button>
              <button className="btndistr" onClick={this.handleDistribution}>
                Round Payment
              </button>
              <button
                className="btndistr"
                onClick={() =>
                  goto("Main/Roundschedules/" + this.state.roundid)
                }
                disabled={loading}
              >
                Round Schedules
              </button>
              <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search a Round here…
                "
              />
            </div>
            <div className="activeuser">
              {" "}
              <div class="row ">
                <div class=" col-lg-3">
                  <div className="col conten">
                    Round Name:
                    <span className="unity"> {distrlist.name}</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div className="col conten">
                    Start Date:
                    <span className="unity">
                      {startDate ? startDate.toString().substring(0, 10) : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    End Date:
                    <span className="unity">
                      {" "}
                      {endDate ? endDate.toString().substring(0, 10) : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    Current Month:
                    <span className="unity"> </span>
                  </div>
                </div>
                <div class="col col-lg-3.5">
                  <div className="col conten">
                    Number of Members:
                    <span className="unity"> {this.state.numberofmembers}</span>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className=" cardtable">
              <div className="containeruserscroll">
                <div className="membadd">
                  <p className=" contentmemb">Monthly Round Payment</p>
                  <input
                    type="text"
                    id="search"
                    className="loginsearchfaro"
                    placeholder=" Search a name...
                
                "
                    onChange={(e) => this.handleUserChange(e)}
                  />
                  <div
                    className="btnaddblogadds  "
                    onClick={this.handlememberRond}
                  >
                    <i class="fas fa-plus"></i>
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <table className="table table-hover  table-responsive  tablealot">
                      <thead>
                        <tr>
                          <th className="userpronou ">Names</th>
                          <th className="userpronou ">Payable Rounds</th>

                          <th className="userpronou ">
                            Monthly Contribution
                            <br />
                            Amount
                          </th>
                          <th className="userpronou "></th>
                          <th className="userpronou "></th>

                          {mheader.map((element) => {
                            //console.log("Element or array=" + element);
                            return <th className="userpronou ">{element}</th>;
                          })}
                          {/* <th className="userpronou ">Jul-21</th>
                    <th className="userpronou "> Aug-21</th>
                    <th className="userpronou ">Sep-21</th>
                    <th className="userpronou ">Oct-21</th>
                    <th className="userpronou ">Nov-21</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="userprony "></td>
                          <td className="userprony ">
                            {this.state.membership}
                          </td>

                          <td className="userprony ">
                            {numb2(
                              distributiontermamount * this.state.membership
                            )}
                          </td>
                          <td className="userpronou "></td>
                          <td className="userpronou ">
                            {numb2(this.state.ytdtopay)}
                          </td>
                          {/* <td className="userprony "></td> */}
                          {mheader.map((element) => {
                            // console.log(
                            //   "To pay header array=" +
                            //     this.state.mapytdtopay.get("2022-11")
                            // );
                            return (
                              <th className="userpronou ">
                                {numb2(this.state.mapytdtopay.get(element))}
                              </th>
                            );
                          })}
                        </tr>
                        <tr>
                          <td className="userpronouwe "></td>
                          <td className="userpronouwe "></td>

                          <td className="userpronouwe "></td>
                          <td className="userpronouwe ">Paid</td>
                          <td className="userpronouwe ">
                            {numb2(this.state.ytdpaid)}
                          </td>
                          {mheader.map((element) => {
                            // console.log("Element or array=" + element);
                            return (
                              <th className="userpronouwe ">
                                {this.state.mapytdpaid.get(element)}
                              </th>
                            );
                          })}
                        </tr>

                        {distrlist.attachement &&
                          distrlist.attachement
                            .filter((item) =>
                              item.firstName
                                ?.toLowerCase()
                                .includes(this.state.searchItem)
                            )
                            .map((item) => {
                              return (
                                <tr>
                                  <td className="userpron ">
                                    {item.firstName} {item.lastName}
                                  </td>
                                  <td className="userpron ">
                                    {item.numberOfMemberships}
                                  </td>

                                  <td className="userpron ">
                                    {" "}
                                    {numb2(
                                      item.numberOfMemberships *
                                        item.membershipTermAmount
                                    )}
                                  </td>
                                  <td>
                                    {/* <p className="userinfonou"> */}
                                    <p className="userinfonou">To Pay</p>
                                    <p className="userinfowe">Paid</p>
                                  </td>

                                  <td>
                                    <p className="userinfonpadd">
                                      {numb2(
                                        item.attachment.Distribution.topay
                                      )}
                                    </p>
                                    <p className="userinfowe">
                                      {numb2(item.attachment.Distribution.paid)}
                                    </p>
                                  </td>
                                  {mheader.map((month) => {
                                    var haselement = false;
                                    var paidamount = 0;
                                    var topayamount = 0;
                                    item.attachment.Distribution.attachment &&
                                      item.attachment.Distribution.attachment.map(
                                        (element) => {
                                          if (month === element.month) {
                                            haselement = true;
                                            //console.log(element);
                                            topayamount =
                                              topayamount + element.topay;
                                            paidamount =
                                              paidamount + element.paid;
                                          }
                                        }
                                      );
                                    return (
                                      <td className="userpronouwe">
                                        <p className="userinfonpadd">
                                          {numb2(topayamount)}
                                        </p>
                                        <p className="userinfowe">
                                          {numb2(paidamount)}
                                        </p>
                                      </td>
                                    );
                                  })}

                                  {/* //   <td>
                          //               <p className="userinfonpadd">
                          //                 {element.topay}
                          //               </p>
                          //               <p className="userinfowe">
                          //                 {element.paid}
                          //               </p>
                          //             </td>
                          // )
                          
                          // };
                          // })} */}
                                </tr>
                              );
                            })}

                        <tr>
                          <td
                            className="userpron "
                            colSpan={6 + mheader.length}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Distribution;
