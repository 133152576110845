import React, { Component } from "react";
import Navbar from "../Navbar";
import { Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Footer from "../footer";
export class CreatePayment extends Component {
  constructor() {
    super();
    this.state = {
      payment: {
        userID: "",
        type: "",
        category: "",
        roundID: "",
        amount: 0,
        payerType: "user",
        transactionDate: "",
        cbanktype: "",
        cbankname: "",
        caccountID: "",
        bankType: "",
        bankName: "",
        accountID: "",
        transactionID: "",
        transactionBankRemark: "",
        transactionCharges: "",
        note: "",
        firstName: "",
        lastName: "",
      },
      users: [],
      transtype: [],
      cats: {},
      selectedUser: "",
      openSucess: false,
      openError: false,
      loading: false,
      startDate: new Date(),
      endDate: new Date(),
      hideuserid: false,
      hidefirstname: true,
      hidebankname: false,
      hidemomo: true,
      hideotherbank: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  componentDidMount = () => {
    this.getUser();
  };
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuccess = () => {
    //window.location.href = window.location + "Main/RecordPayment";
    let payment = {
      userID: "",
      type: "",
      category: "",
      roundID: "",
      amount: 0,
      payerType: "user",
      transactionDate: "",
      cbanktype: "",
      cbankname: "",
      caccountID: "",
      bankType: "",
      bankName: "",
      accountID: "",
      transactionID: "",
      transactionBankRemark: "",
      transactionCharges: "",
      note: "",
      firstName: "",
      lastName: "",
    };
    this.setState({
      openSucess: false,
      payment: payment,
    });
    //console.log("going to new page");
    //goto("Main/CreatePayment");
    window.location.reload(true);
  };
  getUser() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "faculty");
    axios
      .post(
        link() + `&do=get&ra=user&ft=all`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: Users ", res.data);
        if (Array.isArray(res.data))
          this.setState({
            users: res.data,
          });

        this.getTransSelections("payment");
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }
  getTransSelections(rectype) {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "faculty");
    axios
      .post(
        link() +
          `&do=get&ra=rectype&ft=reclist&elmt=menuCategory&id=${rectype}`,

        postData,
        { headers: headers }
      )
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: transtype", res.data);
        if (Array.isArray(res.data)) {
          this.setState({
            transtype: res.data,
            isLoading: false,
          });
          this.retrievePaymentCategories();
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }
  retrievePaymentCategories = () => {
    let { transtype, cats } = this.state;
    transtype &&
      transtype.map((item) => {
        // console.log(item);

        if (item.rectypeStatus === "Active") {
          // ✅ only runs if value not in array
          cats[item.categoryValue1] = item.categoryName2;
        }

        this.setState({ cats });
      });
    // for (let [key, value] of cats) {
    //   console.log(`${key} = ${value}`);
    // }
    for (let key of Object.keys(cats)) {
      const value = cats[key];
      //console.log(`${key} = ${value}`);
    }
  };

  handleChangeSelect = (e) => {
    let payment = this.state.payment;
    payment.firstName = e.target.options[e.target.selectedIndex].text;
    payment.userID = e.target.value;
    this.setState({
      selectedUser: e.target.value,
      payment: payment,
    });

    // console.log(
    //   "Target= " +
    //     e.target.name +
    //     ", value= " +
    //     this.state.selectedUser +
    //     ", content= " +
    //     payment.firstName
    // );
    // console.log(this.state.payment);
  };

  handlePayment = () => {
    //window.location.href = window.location + "Main/RecordPayment";
    goto("Main/RecordPayment");
  };
  handleExpense = () => {
    //window.location.href = window.location + "Main/RecordExpenses";
    goto("Main/RecordExpenses");
  };
  handleVerify = () => {
    //window.location.href = window.location + "Main/PaymentApproval";
    goto("Main/PaymentApproval");
  };

  handleChange = (e) => {
    let payment = this.state.payment;
    payment[e.target.name] = e.target.value;
    //this.setState({ payment });
  //  .console.log("1. Target= " + e.target.name + ", value= " + e.target.value);

    if (e.target.name === "payerType" && e.target.value != "user") {
      //let payment = this.state.payment;
      payment.userID = "";
      payment.firstName = "";
      this.setState({
        hideuserid: true,
        hidefirstname: false,
        selectedUser: "",
        payment: payment,
      });

      if (e.target.name === "bankType") {
        payment.bankName = "";
      }
    } else if (e.target.name === "payerType" && e.target.value === "user") {
      payment.firstName = "";
      this.setState({
        hideuserid: false,
        hidefirstname: false,
        selectedUser: "",
        payment: payment,
      });
    } else if (e.target.name === "bankType" && e.target.value === "Bank") {
      payment.bankName = "";
      this.setState({
        hidebankname: false,
        hidemomo: true,
        hideotherbank: true,
        //selectedUser: "",
        payment: payment,
      });
    } else if (e.target.name === "bankType" && e.target.value === "Momo") {
      payment.bankName = "";
      this.setState({
        hidebankname: true,
        hidemomo: false,
        hideotherbank: true,
        //selectedUser: "",
        payment: payment,
      });
    } else if (e.target.name === "bankType" && e.target.value === "Other") {
      payment.bankName = "";
      this.setState({
        hidebankname: true,
        hidemomo: true,
        hideotherbank: false,
        //selectedUser: "",
        payment: payment,
      });
    } else this.setState({ payment });

  //  .console.log(this.state.payment);
    // console.log(
    //   "selectedUser=" +
    //     this.state.selectedUser +
    //     " , hideuserid=" +
    //     this.state.hideuserid
    // );
  };

  handleChangeDate(date) {
    this.setState({
      startDate: date,
    });
    //alert("DATE " + date + "," + this.state.startDate);
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
  //  .console.log("Button clicked");
    //var transdate = this.state.startDate.toString();

    var newPayment = JSON.stringify({
      userID: this.state.payment.userID,
      type: this.state.payment.type,
      category: this.state.payment.category,
      roundID: this.state.payment.roundID,
      amount: this.state.payment.amount,
      payerType: this.state.payment.payerType,
      transactionDate: format(Date.parse(this.state.startDate), "yyyy-MM-dd"),
      cbanktype: this.state.payment.cbanktype,
      cbankname: this.state.payment.cbankname,
      caccountID: this.state.payment.caccountID,
      bankType: this.state.payment.bankType,
      bankName: this.state.payment.bankName,
      accountID: this.state.payment.accountID,
      transactionID: this.state.payment.transactionID,
      transactionBankRemark: this.state.payment.transactionBankRemark,
      transactionCharges: this.state.payment.transactionCharges,
      note: this.state.payment.note,
      firstName: this.state.payment.firstName,
      lastName: this.state.payment.lastName,
    });

    // .console.log(
    //   this.state.payment.firstName +
    //     "/" +
    //     this.state.payment.lastName +
    //     "/" +
    //     this.state.payment.amount,
    //   "/",
    //   newPayment
    // );

    var token = localStorage.getItem("token");
    var cont;
    var config = {
      method: "post",
      url: link() + `&do=createrequest&ra=payment`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newPayment,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        //this.handlePayment();
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { users, transtype, cats, loading } = this.state;
    //console.log(startDate);

    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Payment</h6>
        </div>
        <div className="membaddd ml-2">
          <button className="btndistr" onClick={this.handlePayment}>
            Record a Payment
          </button>
          <button className="btndistr" onClick={this.handleExpense}>
            Record an Expense
          </button>
          <button className="btndistr" onClick={this.handleVerify}>
            View Records
          </button>
        </div>
        <h6 className="activeuser"> Create a payment request</h6>

        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div class="form-group">
                <label className="lebelenter">Payment Category</label>
                <select
                  id="category"
                  type="text"
                  name="category"
                  required
                  //id="validationDefault01"
                  onChange={(e) => this.handleChange(e)}
                  selected={this.state.payment.category}
                  className="logininputsselect"
                  placeholder="Select Category"
                >
                  <option value="">Select Category</option>
                  {
                    //console.log("Key size="+cats.size())
                    Object.keys(cats).map((key) => {
                      //const value = cats{key};
                      // console.log("Key=" + key + ",Value" + cats[key]);
                      if (key === "penalty" || key === "other")
                        return <option value={key}>{cats[key]}</option>;
                    })
                  }
                </select>
              </div>
              <div class="form-group">
                <label className="lebelenter">Payment Type</label>
                <select
                  id="type"
                  type="text"
                  name="type"
                  required
                  //id="validationDefault01"
                  onChange={(e) => this.handleChange(e)}
                  selected={this.state.payment.type}
                  className="logininputsselect"
                  // placeholder="Select Type"
                >
                  <option value="">Select Type</option>
                  {transtype &&
                    transtype.map((item) => {
                      //if (!this.state.cats.includes(mdistr.month)) {
                      // console.log(
                      //   "Testing " +
                      //     item.categoryValue1 +
                      //     "," +
                      //     this.state.payment.category
                      // );
                      if (item.categoryValue1 === this.state.payment.category) {
                        return (
                          <option value={item.typeValue1}>
                            {item.typeName2}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>

              <div class="form-group">
                <div>
                  <label className="lebelenter">Expected Payment Date</label>
                </div>

                <DatePicker
                  selected={this.state.startDate}
                  //onChange={(e) => this.handleChangeDate(e)}
                  onChange={this.handleChangeDate}
                  name="transactionDate"
                  minDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  placeholder="Pick a date"
                  id="validationDefault01"
                  required
                />
              </div>
              {/* <div class="form-group">
                <label className="lebelenter">Bank Type</label>
                <select
                  id="catcbanktypeegory"
                  type="text"
                  name="bankType"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  selected={this.state.payment.bankType}
                  className="logininputsselect"
                  // placeholder="Select Bank type"
                >
                  <option value="">Select Bank Type</option>
                  <option>Bank</option>
                  <option>Momo</option>
                  <option>Other</option>
                </select>
              </div>
              <div class="form-group">
                <label className="lebelenter">Bank Name</label>
                <select
                  id="bankname"
                  type="text"
                  name="bankName"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  selected={this.state.payment.bankName}
                  className="logininputsselect"
                  // placeholder="Select Bank Name"
                >
                  <option value="">Select Bank</option>
                  <option
                    show={this.state.hidebankname}
                    disabled={this.state.hidebankname ? true : null}
                    value="BPR"
                  >
                    BPR
                  </option>
                  <option
                    disabled={this.state.hidebankname ? true : null}
                    value="COPEDU"
                  >
                    COPEDU
                  </option>
                  <option
                    disabled={this.state.hidemomo ? true : null}
                    value="Momo"
                  >
                    Momo
                  </option>
                  <option
                    disabled={this.state.hideotherbank ? true : null}
                    value="Other"
                  >
                    Other
                  </option>
                </select>
              </div> */}
            </div>
            <div className="rowuno">
              <div class="form-group">
                <label className="lebelenter">Payer Type</label>
                <select
                  id="payertype"
                  type="text"
                  name="payerType"
                  // id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  selected={this.state.payment.payerType}
                  className="logininputsselect"
                  // placeholder="Select payer type"
                >
                  <option value="">Select Payer Type</option>
                  <option value="user">User</option>
                  <option value="bank">Bank</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div class="form-group">
                <label className="lebelenter">Select Payer</label>
                <select
                  className="form-select  logininputsselect"
                  value={this.state.selectedUser}
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChangeSelect(e)}
                  disabled={this.state.hideuserid ? true : null}
                  name="userID"
                  id="userID"
                >
                  <option value=""> Select Payer</option>

                  {users &&
                    users
                      .sort((persone, perstwo) => {
                        if (
                          persone.firstName.toLowerCase() <
                          perstwo.firstName.toLowerCase()
                        )
                          return -1;
                        if (
                          persone.firstName.toLowerCase() >
                          perstwo.firstName.toLowerCase()
                        )
                          return 1;
                        return 0;
                      })
                      .map((aca) => (
                        <option value={aca.userID} id={aca.userID}>
                          {aca.firstName} {aca.lastName}
                        </option>
                      ))}
                </select>
              </div>
              <div class="form-group">
                <label className="lebelenter">Names</label>
                <input
                  type="text"
                  id="firstName"
                  disabled={this.state.hidefirstname ? true : null}
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.firstName}
                  name="firstName"
                  // id=
                  className="logininputsele"
                  placeholder="Name of person paying"
                />
              </div>
              {/* <div class="form-group">
                <label className="lebelenter">Payer Message</label>
                <input
                  type="text"
                  id="transactionBankRemark"
                  name="transactionBankRemark"
                  //id="validationDefault01"
                  //required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.transactionBankRemark}
                  className="logininputsele"
                  placeholder="Message from the Bank"
                />
              </div> */}
            </div>
            <div className="rowuno">
              <div class="form-group">
                <label className="lebelenter">Amount to pay( in Rwf)</label>
                <input
                  type="number"
                  id="amount"
                  //id="validationDefault01"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.payment.amount}
                  name="amount"
                  className="logininputsele"
                  placeholder="Enter Amount without tranaction fees"
                />
              </div>
            </div>
            <div className="mat ml-4">
              <label className="lebelenter">Remark</label>
              <textarea
                type="text"
                id="note"
                name="note"
                // id="validationDefault01"
                //required
                onChange={(e) => this.handleChange(e)}
                value={this.state.payment.note}
                className="parainputsmestext "
                placeholder="The message you want displayed"
              />
            </div>

            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>

            <br />
          </form>
          <div className="schoolModal">
            <Modal show={this.state.openSucess} className="modalAll">
              <Modal.Body className="Madl">
                <div>
                  <p className="btnaddblog" onClick={this.closeSuccess}>
                    <i className="fas fa-times"></i>
                  </p>
                </div>
                <div>
                  <p className="sent">Request created successfully!</p>
                  <p className="succpar">
                    You have successfully requested a payment . Congratulations!
                  </p>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default CreatePayment;
